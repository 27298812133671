import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-js-loader";
import { NavLink } from 'react-router-dom';

const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [user, fetchUsers] = useState([])

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
    
    var formdata = new FormData();
    formdata.append("id", e);
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch("http://54.202.229.179:5000/api/admin/astrologer/deleteAstrologer", requestOptions)

.then(response => response.text())
      .then((res) => {
        getData();
            setLoading(false)
            toast.success("Data deleted Successfully!", { autoClose: 15000 })
          })
          .catch(error =>  toast.error(error, { autoClose: 15000 }));
    
      }

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    
    var formdata = new FormData();
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/getAllAstrologers", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
            fetchUsers(JSON.parse(res).data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
    <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Reports</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="/">Reports</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <table className="table table-bordered table-hover table-striped" cellspacing="0" id="exampleAddRow">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>PhoneNum..</th>
                    <th>Email..</th>
                    <th>ChatPrice</th>
                    <th>CallPrice</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {user.map((item) => {
                return (
                  <tr className="gradeA">
                    <td>{item.profileImage}</td>
                    <td>{item.name}</td>
                    <td>{item.phone}</td>
                    <td>{item.email}</td>
                    <td>{item.chatPrice}</td>
                    <td>{item.callPrice}</td>
                
                 
                    <td className="actions">
                    <Link to="/astrologers/edit"
      state= {{
        id: item.AstrologerID
      }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                        <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(item.AstrologerID)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
                    </td>
                  </tr>
                    )
                  })}

                </tbody>
              </table>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
