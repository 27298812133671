import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-js-loader";
import Select from 'react-select';
import { CKEditor } from 'ckeditor4-react';

const Add = (props) => {
    const navigate = useNavigate();
      const [user, fetchUsers] = useState([])
    const [loading, setLoading] = useState(false);
    let [arr, setArrList] = useState([]);
    
  const HandelChange = (obj) => {
    const newdata = { ...select };
    if(obj.type == "category"){
      newdata['category'] = obj
    }
    
    fetchselectdata(newdata);
   console.log(newdata)
  };
    const [data, fetchdata] = useState({
      title:"",
    slug:"",
    section:"",
    content:""
  })
  const location = useLocation();
  const dataid = location.state;
  const [image, setSelectedImage] = useState(null);
  const [select, fetchselectdata] = useState({
    gender: null,
    category: null
  });
  
  
  const getCategoryData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
    
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/getCategories/blog", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
          const newdata = { ...data };
        
          var cat = JSON.parse(res).map(item => {
            return {...item, type: 'category'};
          });
         
          newdata['categoryData'] = cat;
          setArrList(newdata);      
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }
    // console.log(dataid);
  
    const getData = () => { 
  if (dataid) {
    setLoading(true)
    var axios = require('axios');
 
  var config = {
    method: 'get',
    url: process.env.REACT_APP_BASE_URL+"admin/getSocial/blog/"+dataid.id,
    headers: { 
      'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
  
    },
  };
  
  axios(config)
  .then(function (response) {
    setLoading(false);
   
    console.log(response.data);
    fetchdata(response.data)
    const newdata = { ...select };
        
   var cat = {_id: response.data.category_id, value: response.data.category_name, type: 'category'}
 
   
    newdata['category'] = cat;
    fetchselectdata(newdata);      
   
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data._message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });
     
    }
  }
  

  function submit(e){
    setLoading(true)
    e.preventDefault();

    var axios = require('axios');
var FormData = require('form-data');
var formdata = new FormData();
formdata.append("category_id", select.category._id);
formdata.append("name", data.name);
formdata.append("slug", data.slug);
formdata.append("intro", data.intro);
formdata.append("content", data.content);
formdata.append("type", "blog");
formdata.append("is_featured", data.is_featured);
if(image){
    formdata.append("image", image);
  }

var url = process.env.REACT_APP_BASE_URL+"admin/addAstrologer";
var formMethode = "POST";
    if (dataid) {
      url = process.env.REACT_APP_BASE_URL+"admin/updateSocial/blog/"+dataid.id;
      formMethode = "PATCH";
    }else{
      url = process.env.REACT_APP_BASE_URL+"admin/addSocial/blog";
     formMethode = "POST";
    }
    
    var config = {
      method: formMethode,
      url: url,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
      
      },
      data : formdata
    };
axios(config)
.then(function (response) {
    setLoading(false);
    if (dataid) {
        toast.success("Blog Update Success", { autoClose: 15000 });
      }else{
        toast.success("Blog Add Success", { autoClose: 15000 });
      }
    console.log(response.data);
    navigate('/Setting/Blog');
   
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data._message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });

    }
      
      function handle(e){
        const newdata={...data}
        if(e.name == "content"){
          newdata['content'] = e.getData();
        }else{
          if(e.target.type == "file"){
          
              setSelectedImage(e.target.files[0]);
             
            
         
            }else{
             newdata[e.target.id] = e.target.value
            }
        }
      
        fetchdata(newdata)
        console.log(newdata)
      }
      const handleInstanceReady = ( { editor } ) => {
        // Will be triggered only once, when editor is ready for interaction.
            editor.setData( data.content );
      
    };
  
    useEffect(() => {
      getData();
      getCategoryData();
     
    }, [])
  
    return loading? 
    <>
    <div className="App">
          
              
                <div className={"item"}>
                    <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
                </div>
         
        
        </div>
    </>
    :(
      <>
      <ToastContainer autoClose={8000} />
       <div className="page">
      <div className="page-header">
      <h1 className="page-title">Blog {dataid?"Edit":"Add"}</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Blog</NavLink></li>
        <li className="breadcrumb-item active">{dataid?"Edit":"Add"}</li>
      </ol>
       
      </div>
      <div className="page-content">
      <div className="panel">
      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Add Blog </h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={data.name}  className="form-control" id="name" name="name"
                          placeholder="Blog Name" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Slug</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    {/* <select  className="form-control" onChange={(e)=>handle(e)} value={data.display} id="display" name="display" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select> */}
                   
                     <input type="text" onChange={(e)=>handle(e)} value={data.slug}  className="form-control" id="slug" name="slug"
                          placeholder=" slug" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Intro</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
                    <input type="text" onChange={(e)=>handle(e)} value={data.intro}  className="form-control" id="intro" name="intro"
                          placeholder="intro"/>
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-md-3 form-control-label">is_featured</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
                    <input type="number" onChange={(e)=>handle(e)} value={data.is_featured}  className="form-control" id="is_featured" name="is_featured"
                          placeholder="is_featured"/>
                  </div>
                </div>

                <div className="form-group row">
                <label className="col-md-3 form-control-label">Category</label>
                <div className="col-md-9">
                          {/* <label for="label">Product Category</label> */}
                              <Select
      isSearchable
      options={arr.categoryData}
      
      getOptionLabel={(option) => option.value}
      getOptionValue={(option) => option.category_id}
      className="diMultiSelect"
      classNamePrefix="diSelect"
      id="label"
      name="label"
      //  styles={styles}
      maxMenuHeight={150}
      value={select.category} // this doesn't let me click options
      onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
      required
    />

                          </div>

               
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">image</label>
                  <div className="col-md-9">
                  <div className="custom-file">
                                          
                  <input
                                  type="file" accept="image/png, image/gif, image/jpeg"
                                  className="custom-file-input"
                                  onChange={(e) => handle(e)}
                                  // value={data.profileImage}
                                  id="image"
                                  className='form-control'
                                  placeholder='Choose Main Image'
                                />
                                <img src={data.featured_image}  onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }}  width="40" height="40"/>

                                        
                                     </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">content</label>
                  <div className="col-md-9">
                  
                  <textarea type="number" onChange={(e)=>handle(e)} value={data.content}  className="form-control" id="content" name="content"
                          placeholder="content"/>
                  {/* <label for="content">Content</label> */}
                  {/* <textarea onChange={(e)=>handle(e)} id="content" style={{ height: "150px" }} value={data.content}  className="form-control"  placeholder='content' type="content"></textarea> */}
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    </div>
    
  </div>
  </div>
  
           </>
    )
    }

export default Add;

