
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import {Link, NavLink} from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Offer  = (props) => {
  const [loading, setLoading] = useState(false);
//   const [image, setSelectedImage] = useState(null);
  const [data, fetchdata] = useState([])
  let [arr, setArrList] = useState([])


  const [catData,setData]=useState([])
  const columns = [

    {
      name: "Name",
      selector: row=> row.name,
      sortable: true,
     
    },
    {
      name: "code",
      selector: row=> row.code,
      sortable: true,
     
    },
    {
      name: "Date",
      selector: row=> row.createdAt,
      sortable: true,
     
    },
    {
      name: "Status",
      sortable: true,
      cell: (d) => [
        <>
        {d.status?"Enable":"Diseble"}
        </>
      ]
     
    },

    
    {
      name: "Action",
      sortable: false,

      cell: (d) => [
        <>
        <Link to="/setting/offer/edit" state= {{
          data: d,
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
                    "nameedit": "",
                    "slugedit": "",
                    "introedit": "",
                    "is_featurededit": "",
                    // "imageedit": ""      
          });


          function deleterow(e){
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
            
            var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              redirect: 'follow'
            };
          
            fetch(process.env.REACT_APP_BASE_URL+"admin/deleteOffer/"+e, requestOptions)

          
          .then(response => response.text())
          .then((res) => {
            setLoading(false);
          if(JSON.parse(res).errors){
            console.log(JSON.parse(res).errors)
            toast.error(JSON.parse(res)._message, { autoClose: 8000 })
          }else{
          
            toast.success("Material Delete Success", { autoClose: 15000 });
            getData()
          };
            
          })
          .catch((error) => {
            setLoading(false);
            console.log(error.errors);
            toast.error('Somthing Went Wrong', { autoClose: 8000 });
          });
          
          }
         

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"getOffer/coupon?page=1&limit=200", requestOptions)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{

  if(Object.entries(JSON.parse(res)).length  > 0){
    var data = JSON.parse(res);
    fetchdata(data.results)
 
  }
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
  }


  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
   <ToastContainer autoClose={8000} />
   <div className="page">
    <div className="page-header">
      <h1 className="page-title">Offer </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        <li className="breadcrumb-item active">Offer</li>
      </ol>
      <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="/setting/offer/add">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">Add</span>
    </NavLink>
      </div>
    </div>

    <div className="page-content container-fluid">
      

     

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>

          </div>

          
        </div>
    </div>



    

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
     
    </div>
  </div>
</div>

  </div>
   </>
   
  )
}

export default Offer
