import React, { useState, useEffect } from 'react';
import { Link, NavLink} from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [callRecorddata, fetchCallData] = useState([])
  const [data,fetchdata] = useState([])
  const location = useLocation();
  const dataid = location.state;

  // const [data, fetchdata] = useState({
  //   "value": "",
  //   "order": "",
  //  "_id": "",
  //  "status": "",
  //   // "model":"ProductMaterial"
  
  // });

  



  const getData = () => {
    setLoading(true);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: process.env.REACT_APP_BASE_URL+"admin/kyc/"+dataid.id,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoading(false);
           
      console.log(response.data);
      // getCallRecord();
      fetchdata(response.data)
     
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response) {
        if(error.response.status == 500){
          toast.error(error.response.data._message, { autoClose: 8000 })
        }else{
        // Request made and server responded
        toast.error(error.response.data, { autoClose: 8000 })
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('The request was made but no response was received', { autoClose: 8000 })
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(error.message, { autoClose: 8000 })
        console.log('Error', error.message);
      }
      console.log(error);
    });
  }

  function getCallRecord(){
    setLoading(true);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: process.env.REACT_APP_BASE_URL+"admin/call-record-history/"+dataid.id,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoading(false);
           
      console.log(response.data);
   
      fetchCallData(response.data)
     
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response) {
        if(error.response.status == 500){
          toast.error(error.response.data._message, { autoClose: 8000 })
        }else{
        // Request made and server responded
        toast.error(error.response.data, { autoClose: 8000 })
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('The request was made but no response was received', { autoClose: 8000 })
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(error.message, { autoClose: 8000 })
        console.log('Error', error.message);
      }
      console.log(error);
    });
  
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var axios = require('axios');

var config = {
  method: 'patch',
  url: process.env.REACT_APP_BASE_URL+"admin/kyc/verify/"+data.astro_id._id+'/'+data.status,
  headers: { 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
  }
};

axios(config)
.then(function (response) {
  setLoading(false);
  if (dataid) {
      toast.success("Kyc  Update Success", { autoClose: 15000 });
    }else{
      toast.success("Kyc  Add Success", { autoClose: 15000 });
    }
    getData()
  console.log(response.data);
  // navigate('/yc ');
 
})
.catch(function (error) {
  setLoading(false)
  if (error.response) {
    if(error.response.status == 500){
      toast.error(error.response.data._message, { autoClose: 8000 })
    }else{
    // Request made and server responded
    toast.error(error.response.data, { autoClose: 8000 })
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    toast.error('The request was made but no response was received', { autoClose: 8000 })
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message, { autoClose: 8000 })
    console.log('Error', error.message);
  }
  console.log(error);
});

  }

    function handle(e){
  
      const newdata={...data}
      if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Kyc</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Kyc view</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body" style={{background:"aliceblue"}}>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <div className="table-responsive table-padding">
                    <table className="table ">
                     
                        <tbody className="table-primary">
                      
                      <tr>
                                <th>Adhar number</th>
                                <td>{data.aadhar_number}</td>
                                <td></td>
                              
                            </tr>
                            <tr>
                                <th>Bank Account Number</th>
                                <td>{data.bank_account_number }</td>
                                <td></td>
                            
                            </tr>
           
                            <tr>
                                <th>Bank Name</th>
                                <td>{data.bank_name }</td>
                                <td></td>
                            
                            </tr>
                            <tr>
                                <th>IFSC Code</th>
                                <td>{data.ifsc_code}</td>
                                <td></td>
                               
                            </tr>
                          
                            <tr>
                                <th>Pan card Number</th>
                                <td>{data.pan_number}</td>
                                <td></td>
                               
                            </tr>



                            <tr>
                                <th>Aadhar front and back image</th>
                                
                                <td>
                                <a href={data.aadhar_front} target="_blank">
                                  <img src= {data.aadhar_front} alt='' width='200px' target="_blank" style={{cursor:"pointer"}} />
                                  </a>
                                  </td>

                                <td>
                                <a href={data.pan_front} target="_blank">
                                  <img src= {data.aadhar_back} alt='' width='200px' target="_blank" style={{cursor:"pointer"}}/>
                                  </a>
                                  </td>
                               
                            </tr>

                            <tr>
                                <th>Pan front image</th>
                                <td>
                                  <a href={data.pan_front} target="_blank">
                                  <img src= {data.pan_front} alt='' width='200px' style={{cursor:"pointer"}}/>
                                  </a>
                                  </td>
                                <td></td>
                               
                            </tr>
                            <tr>
                                <th>Bank Attach image</th>
                                <td>
                                  <a href={data.bank_attach} target="_blank">
                                  <img src= {data.bank_attach} alt='' width='200px' style={{cursor:"pointer"}}/>
                                  </a>
                                  </td>
                                <td></td>
                               
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>
                            
            {data.status == '1'? <p style={{backgroundColor:"pink", color:"#fff", padding:"5px"}}>  Submited.verification Pending </p>: null }
            {data.status == '0'? <p style={{backgroundColor:"red", color:"#fff", padding:"5px"}}> Not Submit</p>: null }
            {data.status == '2'? <p style={{backgroundColor:"green", color:"#fff", padding:"5px"}}> verify  </p>: null }
            {data.status == '3'? <p style={{backgroundColor:"yellow", color:"#fff", padding:"5px"}}> Rejected  </p>: null }
            {data.status == '4'? <p style={{backgroundColor:"Blue", color:"#fff", padding:"5px"}}> Kyc Blocked  </p>: null }
    
                                </td>
                               <td></td>
                            </tr>
                            
                            
                                            
                        </tbody>
                        </table>
                        </div>
          </div>
        </div>
    </div>

    

  </div>


  <div className="page">

  <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              {/* <h3 className="panel-title">Add Category </h3> */}
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Status Update</label>
                  <div className="col-md-12">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */} 

                     <select  className="form-control" onChange={(e)=>handle(e)} value={data.status} id="status" name="status" >
                      <option >Select Any one</option>
             
                      {/* <option value="1"></option> */}
                      <option value="2" {...data.status == 2?'selected':''}>Verify</option>
                      <option value="3">Reject</option>
                      <option value="4">Block Kyc</option>
                    </select>
                    
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>


    </div>
  </>
  )
}

export default Manage
