import React from 'react'


const header = () => {

  function logout(e){
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.href = '/login';

    return false;
}
  return (
    <nav className="site-navbar navbar navbar-default navbar-fixed-top navbar-mega" role="navigation">
    
    <div className="navbar-header">
      <button type="button" className="navbar-toggler hamburger hamburger-close navbar-toggler-left hided"
        data-toggle="menubar">
        <span className="sr-only">Toggle navigation</span>
        <span className="hamburger-bar"></span>
      </button>
      <button type="button" className="navbar-toggler collapsed" data-target="#site-navbar-collapse"
        data-toggle="collapse">
        <i className="icon wb-more-horizontal" aria-hidden="true"></i>
      </button>
      <div className="navbar-brand navbar-brand-center site-gridmenu-toggle" data-toggle="gridmenu">
        <img className="navbar-brand-logo" src="/logo.png" style={{width:"100%"}} title="jyotishee" />
        {/* <span className="navbar-brand-text hidden-xs-down"> jyotishee</span> */}
      </div>
      {/* <button type="button" className="navbar-toggler collapsed" data-target="#site-navbar-search"
        data-toggle="collapse">
        <span className="sr-only">Toggle Search</span>
        <i className="icon wb-search" aria-hidden="true"></i>
      </button> */}
    </div>
  
    <div className="navbar-container container-fluid">
      {/* <!-- Navbar Collapse --> */}
      <div className="collapse navbar-collapse navbar-collapse-toolbar" id="site-navbar-collapse">
        {/* <!-- Navbar Toolbar --> */}
        <ul className="nav navbar-toolbar">
          <li className="nav-item hidden-float" id="toggleMenubar">
            <a className="nav-link" data-toggle="menubar" href="#" role="button">
              <i className="icon hamburger hamburger-arrow-left">
                <span className="sr-only">Toggle menubar</span>
                <span className="hamburger-bar"></span>
              </i>
            </a>
          </li>
          <li className="nav-item hidden-sm-down" id="toggleFullscreen">
            <a className="nav-link icon icon-fullscreen" data-toggle="fullscreen" href="#" role="button">
              <span className="sr-only">Toggle fullscreen</span>
            </a>
          </li>
        
        </ul>
        {/* <!-- End Navbar Toolbar --> */}
  
        {/* <!-- Navbar Toolbar Right --> */}
        <ul className="nav navbar-toolbar navbar-right navbar-toolbar-right">
          <li className="nav-item dropdown">
            {/* <a className="nav-link" data-toggle="dropdown" href="" data-animation="scale-up"
              aria-expanded="false" role="button">
              <span className="flag-icon flag-icon-us"></span>
            </a>
            <div className="dropdown-menu" role="menu">
              <a className="dropdown-item" href="" role="menuitem">
                <span className="flag-icon flag-icon-gb"></span> English</a>
              <a className="dropdown-item" href="" role="menuitem">
                <span className="flag-icon flag-icon-fr"></span> French</a>
              <a className="dropdown-item" href="" role="menuitem">
                <span className="flag-icon flag-icon-cn"></span> Chinese</a>
              <a className="dropdown-item" href="" role="menuitem">
                <span className="flag-icon flag-icon-de"></span> German</a>
              <a className="dropdown-item" href="" role="menuitem">
                <span className="flag-icon flag-icon-nl"></span> Dutch</a>
            </div> */}
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link navbar-avatar" data-toggle="dropdown" href="#" aria-expanded="false"
              data-animation="scale-up" role="button">
              <span className="avatar avatar-online">
                <img src="/logo.png"  />
                <i></i>
              </span>
            </a>
            <div className="dropdown-menu" role="menu">
              {/* <a className="dropdown-item" href="" role="menuitem"><i className="icon wb-user" aria-hidden="true"></i> Profile</a>
              <a className="dropdown-item" href="" role="menuitem"><i className="icon wb-payment" aria-hidden="true"></i> Billing</a>
              <a className="dropdown-item" href="" role="menuitem"><i className="icon wb-settings" aria-hidden="true"></i> Settings</a> */}
              <div className="dropdown-divider" role="presentation"></div>
              <a onClick={(e) => logout(e)}className="dropdown-item" href="" role="menuitem"><i className="icon wb-power" aria-hidden="true"></i> Logout</a>
            </div>
          </li>
          {/* <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="" title="Notifications"
              aria-expanded="false" data-animation="scale-up" role="button">
              <i className="icon wb-bell" aria-hidden="true"></i>
              <span className="badge badge-pill badge-danger up">5</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-media" role="menu">
              <div className="dropdown-menu-header">
                <h5>NOTIFICATIONS</h5>
                <span className="badge badge-round badge-danger">New 5</span>
              </div>
  
              <div className="list-group">
                <div data-role="container">
                  <div data-role="content">
                    <a className="list-group-item dropdown-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <i className="icon wb-order bg-red-600 white icon-circle" aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">A new order has been placed</h6>
                          <time className="media-meta" datetime="2018-06-12T20:50:48+08:00">5 hours ago</time>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item dropdown-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <i className="icon wb-user bg-green-600 white icon-circle" aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Completed the task</h6>
                          <time className="media-meta" datetime="2018-06-11T18:29:20+08:00">2 days ago</time>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item dropdown-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <i className="icon wb-settings bg-red-600 white icon-circle" aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Settings updated</h6>
                          <time className="media-meta" datetime="2018-06-11T14:05:00+08:00">2 days ago</time>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item dropdown-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <i className="icon wb-calendar bg-blue-600 white icon-circle" aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Event started</h6>
                          <time className="media-meta" datetime="2018-06-10T13:50:18+08:00">3 days ago</time>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item dropdown-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <i className="icon wb-chat bg-orange-600 white icon-circle" aria-hidden="true"></i>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Message received</h6>
                          <time className="media-meta" datetime="2018-06-10T12:34:48+08:00">3 days ago</time>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="dropdown-menu-footer">
                <a className="dropdown-menu-footer-btn" href="" role="button">
                  <i className="icon wb-settings" aria-hidden="true"></i>
                </a>
                <a className="dropdown-item" href="" role="menuitem">
                  All notifications
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="" title="Messages"
              aria-expanded="false" data-animation="scale-up" role="button">
              <i className="icon wb-envelope" aria-hidden="true"></i>
              <span className="badge badge-pill badge-info up">3</span>
            </a>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-media" role="menu">
              <div className="dropdown-menu-header" role="presentation">
                <h5>MESSAGES</h5>
                <span className="badge badge-round badge-info">New 3</span>
              </div>
  
              <div className="list-group" role="presentation">
                <div data-role="container">
                  <div data-role="content">
                    <a className="list-group-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <span className="avatar avatar-sm avatar-online">
                            <img src="assets/global/portraits/2.jpg" alt="..." />
                            <i></i>
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Mary Adams</h6>
                          <div className="media-meta">
                            <time datetime="2018-06-17T20:22:05+08:00">30 minutes ago</time>
                          </div>
                          <div className="media-detail">Anyways, i would like just do it</div>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <span className="avatar avatar-sm avatar-off">
                            <img src="assets/global/portraits/3.jpg" alt="..." />
                            <i></i>
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Caleb Richards</h6>
                          <div className="media-meta">
                            <time datetime="2018-06-17T12:30:30+08:00">12 hours ago</time>
                          </div>
                          <div className="media-detail">I checheck the document. But there seems</div>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <span className="avatar avatar-sm avatar-busy">
                            <img src="assets/global/portraits/4.jpg" alt="..." />
                            <i></i>
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">June Lane</h6>
                          <div className="media-meta">
                            <time datetime="2018-06-16T18:38:40+08:00">2 days ago</time>
                          </div>
                          <div className="media-detail">Lorem ipsum Id consectetur et minim</div>
                        </div>
                      </div>
                    </a>
                    <a className="list-group-item" href="" role="menuitem">
                      <div className="media">
                        <div className="pr-10">
                          <span className="avatar avatar-sm avatar-away">
                            <img src="assets/global/portraits/5.jpg" alt="..." />
                            <i></i>
                          </span>
                        </div>
                        <div className="media-body">
                          <h6 className="media-heading">Edward Fletcher</h6>
                          <div className="media-meta">
                            <time datetime="2018-06-15T20:34:48+08:00">3 days ago</time>
                          </div>
                          <div className="media-detail">Dolor et irure cupidatat commodo nostrud nostrud.</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="dropdown-menu-footer" role="presentation">
                <a className="dropdown-menu-footer-btn" href="" role="button">
                  <i className="icon wb-settings" aria-hidden="true"></i>
                </a>
                <a className="dropdown-item" href="" role="menuitem">
                  See all messages
                </a>
              </div>
            </div>
          </li>
          <li className="nav-item" id="toggleChat">
            <a className="nav-link" data-toggle="site-sidebar" href="" title="Chat"
              data-url="site-sidebar.tpl">
              <i className="icon wb-chat" aria-hidden="true"></i>
            </a>
          </li> */}
        </ul>
        {/* <!-- End Navbar Toolbar Right --> */}
      </div>
      {/* <!-- End Navbar Collapse --> */}
  
      {/* <!-- Site Navbar Seach --> */}
      {/* <div className="collapse navbar-search-overlap" id="site-navbar-search">
        <form role="search">
          <div className="form-group">
            <div className="input-search">
              <i className="input-search-icon wb-search" aria-hidden="true"></i>
              <input type="text" className="form-control" name="site-search" placeholder="Search..." />
              <button type="button" className="input-search-close icon wb-close" data-target="#site-navbar-search"
                data-toggle="collapse" aria-label="Close"></button>
            </div>
          </div>
        </form>
      </div> */}
      {/* <!-- End Site Navbar Seach --> */}
    </div>
  </nav>    
  )
}

export default header
