import React, { useState, useEffect } from 'react';
import { Link, NavLink} from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])

   const columns = [
   
    {
      name: "Firstname",
      selector: "firstname",
      sortable: true,
     
    },
    {
      name: "Lastname",
      selector: "lastname",
      sortable: true,
     
    },
   
    {
      name: "Email",
      selector: "email",
      sortable: true
    },
    {
      name: "Role",
      selector: "role",
      sortable: true
    },
    {
        name: "Status",
  
        sortable: true,
        cell: (d) => [
          <>
          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => StatusAdmin(d._id,d.status == 1?0:1)}
                            data-toggle="tooltip" data-original-title="Remove">{d.status == 1?<i className='icon wb-check' aria-hidden='rue'></i>:<i className='icon wb-close' aria-hidden='rue'></i>}</button>
        </>
        ]
      },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
        <Link to="/astrologers/edit" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
    
    var formdata = new FormData();
    formdata.append("id", e);
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch("http://54.202.229.179:5000/api/admin/astrologer/deleteAstrologer", requestOptions)

.then(response => response.text())
      .then((res) => {
        getData();
            setLoading(false)
            toast.success("Data deleted Successfully!", { autoClose: 15000 })
          })
          .catch(error =>  toast.error(error, { autoClose: 15000 }));
    
      }


      function StatusAdmin(e,status){
        setLoading(true)
var axios = require('axios');
var data = JSON.stringify({
  "role": "Sr. Editor",
  "status": status
});
var config = {
  method: 'patch',
  url: 'process.env.REACT_APP_BASE_URL+"admin/updatesdmin-status-role/'+e,
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
  },
  data : data
};

axios(config)
      
.then(function (response) {
    setLoading(false);
    if(status==1){
        toast.success("Admin Active Successfully", { autoClose: 15000 })
      }else{
        toast.success("Admin Deactive Successfully", { autoClose: 15000 })
      }
    getData();
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data.message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });
  
        
          }


  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/listadmins?page=1&limit=1000", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
            fetchUsers(JSON.parse(res))
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
    <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Admin </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Admin </NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="/setting/admin/add">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">Add</span>
    </NavLink>
      </div>
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
