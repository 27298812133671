import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import Select from "react-select";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CKEditor } from 'ckeditor4-react';

const Add = (props) => {
  const [loading, setLoading] = useState(false);
  const [image, setSelectedImage] = useState(null);
  const [thumbnailpath, setthumbpathimage] = useState([]);
  const [thumbnail, setSelectedthumbnail] = useState([]);

  const showAddcat=useRef();

  const s_weight = [
    { id: 3, phaseText: "3 carat/3.29 ratti", type: "s_weight" },
    { id: 3.5, phaseText: "3.5 carat/3.84 ratti", type: "s_weight" },
    { id: 4, phaseText: "4 carat/4.39 ratti", type: "s_weight" },
    { id: 4.5, phaseText: "4.5 carat/4.94 ratti", type: "s_weight" },
    { id: 5, phaseText: "5 carat/5.49 ratti", type: "s_weight" },
    { id: 5.5, phaseText: "5.5 carat/6.04 ratti", type: "s_weight" },
    { id: 6, phaseText: "6 carat/6.59 ratti", type: "s_weight" },
    { id: 6.5, phaseText: "6.5 carat/7.14 ratti", type: "s_weight" },
    { id: 7, phaseText: "7 carat/7.69 ratti", type: "s_weight" },
    { id: 7.5, phaseText: "7.5 carat/8.24 ratti", type: "s_weight" },
    { id: 8, phaseText: "8 carat/8.79 ratti", type: "s_weight" },
    { id: 8.5, phaseText: "8.5 carat/9.34 ratti", type: "s_weight" },
    { id: 9, phaseText: "9 carat/9.89 ratti", type: "s_weight" },
    { id: 9.5, phaseText: "9.5 carat/10.43 ratti", type: "s_weight" },
    { id: 10, phaseText: "10 carat/10.98 ratti", type: "s_weight" },
    { id: 10.5, phaseText: "10.5 carat/11.53 ratti", type: "s_weight" },
    { id: 11, phaseText: "11 carat/12.08 ratti", type: "s_weight" },
    { id: 11.5, phaseText: "11.5 carat/12.63 ratti", type: "s_weight" },
    // { id: 12, phaseText: '12 carat/13.18 ratti', type:'s_weight' }
  ];
  const e_weight = [
    // { id: 3, phaseText: '3 carat/3.29 ratti', type:'e_weight' },
    { id: 3.5, phaseText: "3.5 carat/3.84 ratti", type: "e_weight" },
    { id: 4, phaseText: "4 carat/4.39 ratti", type: "e_weight" },
    { id: 4.5, phaseText: "4.5 carat/4.94 ratti", type: "e_weight" },
    { id: 5, phaseText: "5 carat/5.49 ratti", type: "e_weight" },
    { id: 5.5, phaseText: "5.5 carat/6.04 ratti", type: "e_weight" },
    { id: 6, phaseText: "6 carat/6.59 ratti", type: "e_weight" },
    { id: 6.5, phaseText: "6.5 carat/7.14 ratti", type: "e_weight" },
    { id: 7, phaseText: "7 carat/7.69 ratti", type: "e_weight" },
    { id: 7.5, phaseText: "7.5 carat/8.24 ratti", type: "e_weight" },
    { id: 8, phaseText: "8 carat/8.79 ratti", type: "e_weight" },
    { id: 8.5, phaseText: "8.5 carat/9.34 ratti", type: "e_weight" },
    { id: 9, phaseText: "9 carat/9.89 ratti", type: "e_weight" },
    { id: 9.5, phaseText: "9.5 carat/10.43 ratti", type: "e_weight" },
    { id: 10, phaseText: "10 carat/10.98 ratti", type: "e_weight" },
    { id: 10.5, phaseText: "10.5 carat/11.53 ratti", type: "e_weight" },
    { id: 11, phaseText: "11 carat/12.08 ratti", type: "e_weight" },
    { id: 11.5, phaseText: "11.5 carat/12.63 ratti", type: "e_weight" },
    { id: 12, phaseText: "12 carat/13.18 ratti", type: "e_weight" },
  ];
  const gst = [
    { id: 0, phaseText: "0%", type: "gst" },
    { id: 0.25, phaseText: "0.25%", type: "gst" },
    { id: 3, phaseText: "3%", type: "gst" },
    { id: 5, phaseText: "5%", type: "gst" },
  ];
  const [status, setstatus] = useState([
    { id: 1, phaseText: "Public", type: "status" },
    { id: 0, phaseText: "Private", type: "status" },
  ]);

  const [datalabel, setlabel] = useState([
    { id: "None", phaseText: "None", type: "label" },
    { id: "New Arrival", phaseText: "New Arrival", type: "label" },
    { id: "Sold Out", phaseText: "Sold Out", type: "label" },
  ]);

  const [is_featuredData, setfeaturedData] = useState([
    { id: 1, phaseText: "Yes", type: "setfeatured" },
    { id: 0, phaseText: "No", type: "setfeatured" },
  ]);

  const [select, fetchselectdata] = useState({
    featured: null,
    label: null,
    Category: null,
    gst: null,
    s_weight: null,
    e_weight: null,
  });

  const [categoryBlock, setDatashow] = useState({
    showStartweight: 'block',
    showEndweight: 'block',
    showAddonType: 'block',
    showMetal: 'block',
    ShowDesign: 'block',
  });
  const HandelChange = (obj) => {
    const newdata = { ...select };
    if (obj.type == "setfeatured") {
      newdata["featured"] = obj;
    } else if (obj.type == "label") {
      newdata["label"] = obj;
    } else if (obj.type == "category") {
      newdata["Category"] = obj;

      // =========hide div on rudraksh and bracelet===========
      if(obj.value == "Rudraksha" || obj.parent_id == "63aeac69f969cf78856b7db9" || obj._id == "63aeac69f969cf78856b7db9"){
        setDatashow({
          showStartweight: 'none',
          showEndweight: 'none',
          showAddonType: 'block',
          showMetal: 'block',
          ShowDesign: 'block',
        })
      }else if(obj.value == "Bead Bracelet" || obj.parent_id == "63aeae05f969cf78856b80b2" || obj._id == "63aeae05f969cf78856b80b2"){
        setDatashow({
          showStartweight: 'none',
          showEndweight: 'none',
          showAddonType: 'none',
          showMetal: 'none',
          ShowDesign: 'none',
        })
      }else{
        setDatashow({
          showStartweight: 'block',
          showEndweight: 'block',
          showAddonType: 'block',
          showMetal: 'block',
          ShowDesign: 'block',
        })
      }

    } else if (obj.type == "status") {
      newdata["status"] = obj;
    } else if (obj.type == "gst") {
      newdata["gst"] = obj;
    } else if (obj.type == "s_weight") {
      newdata["s_weight"] = obj;
    } else if (obj.type == "e_weight") {
      newdata["e_weight"] = obj;
    }
    fetchselectdata(newdata);
    console.log(obj._id);
    
    
  };

  const location = useLocation();
  const dataid = location.state;

  // const [values, setValues] = useState([]);
  // values.push({name: 'Select Category', id: null});
  const values = [{ name: "Select Category", id: null }];
  const navigate = useNavigate();
  let [arr, setArrList] = useState([]);
  let [addOn, setAddonList] = useState([]);
  let [Material, setMaterialList] = useState([]);
  let [design, setDesignList] = useState([]);
  // let [image, setImageList] = useState([]);

  const [data, fetchdata] = useState({
    ProductName: "",
    subtitle: "",
    sku: "",
    label: "",
    tags: "",
    minOrderQty: "",
    maxOrderQty: "",
    description: "",
    thumbnail: "",
    image: "",
    youtube_url: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    Category: "",
    Category: "",
    product_category: [],
    categoryData: [],
    bracelet: 0,
    ring: 0,
    addOn: "",
    startingweight: 3,
    endingweight: 4.5,
    DiscountPrice: null,
    mrp: "",
    is_featured: "",
    startingweight: "",
    endingweight: "",
  });

  const getProductData = () => {
    if (dataid) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL+"admin/" +
          dataid.id +
          "/getProductDetails",
        requestOptions
      ).then((Result) => {
        Result.json()
          .then((resp) => {
            setLoading(false);

            fetchdata(resp);
            const newdata = { ...select };
            if (resp.is_featured != null) {
              if (resp.is_featured == 1) {
                newdata["featured"] = {
                  id: 1,
                  phaseText: "Yes",
                  type: "setfeatured",
                };
              } else {
                newdata["featured"] = {
                  id: 0,
                  phaseText: "No",
                  type: "setfeatured",
                };
              }
            }
            if (resp.label != null) {
              newdata["label"] = {
                id: resp.label,
                phaseText: resp.label,
                type: "label",
              };
            }
            if (resp.gst != null) {
              newdata["gst"] = {
                id: resp.gst,
                phaseText: resp.gst + "%",
                type: "gst",
              };
            }
            if (resp.gst != null) {
              newdata["gst"] = {
                id: resp.gst,
                phaseText: resp.gst + "%",
                type: "gst",
              };
            }
            if (resp.status != null) {
              if (resp.status == 1) {
                newdata["status"] = {
                  id: 1,
                  phaseText: "Public",
                  type: "status",
                };
              } else {
                newdata["status"] = {
                  id: 0,
                  phaseText: "Private",
                  type: "status",
                };
              }
            }
            if (resp.thumbnail !== null) {
              var thumbarr = Array();
              setthumbpathimage([resp.thumbnail]);
            }
            if (resp.product_category != null) {
              newdata["Category"] = resp.product_category;
            }

            fetchselectdata(newdata);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error("Somthing Went Wrong", { autoClose: 8000 });
          });
      });
    }
  };

  const getCategoryData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL+"admin/productmaster/Category",
      requestOptions
    )
      .then((response) => response.text())
      .then((res) => {
        console.log(res)
        setLoading(false);
        const newdata = { ...data };

        var cat = JSON.parse(res).map((item) => {
          return { ...item, type: "category" };
        });

        newdata["categoryData"] = cat;
        setArrList(newdata);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };
  const getDesignData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI"

    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL+"admin/designs/show",
      requestOptions
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        // const newdata = { ...data };

        setDesignList(JSON.parse(res));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };

  const getAddonData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_BASE_URL+"admin/showAddOns", requestOptions)
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        // console.log(res)
        setAddonList(JSON.parse(res));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };
  const getMaterialData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL+"admin/productmaster/Material",
      requestOptions
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        // console.log(res)
        setMaterialList(JSON.parse(res));
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };
  const onValidate = (value, name) => {
    setError((prev) => ({
      ...prev,
      [name]: { ...prev[name], errorMsg: value },
    }));
  };

  const [error, setError] = useState({
    country: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
    lang: {
      isReq: true,
      errorMsg: "",
      onValidateFunc: onValidate,
    },
  });
  const validateForm = () => {
    let isInvalid = false;
    Object.keys(error).forEach((x) => {
      const errObj = error[x];
      if (errObj.errorMsg) {
        isInvalid = true;
      } else if (errObj.isReq && !data[x]) {
        isInvalid = true;
        onValidate(true, x);
      }
    });
    return !isInvalid;
  };

  function submit(e) {
    e.preventDefault();
    getDesignData();
    // const isValid = validateForm();
    // if (!isValid) {
    //   console.error('Invalid Form!');
    //   return false;
    // }

    if (!select["label"]) {
      alert("Select Label");
      return false;
    }

    if (!select["featured"]) {
      alert("Select Featured or not");
      return false;
    }
    if (!select["status"]) {
      alert("Select Status");
      return false;
    }
    if (!select["Category"]) {
      alert("Select Category");
      return false;
    }
    if (!select["gst"]) {
      alert("Select GST");
      return false;
    }

 
    setLoading(true);
    e.preventDefault();
    var axios = require("axios");
    var formdata = new FormData();

    formdata.append("ProductName", data.ProductName);
    formdata.append("subtitle", data.subtitle);
    formdata.append("sku", data.sku);
    formdata.append("minOrderQty", data.minOrderQty);
    formdata.append("maxOrderQty", data.maxOrderQty);
    formdata.append("mrp", data.mrp);
    formdata.append("DiscountPrice", data.DiscountPrice);
    formdata.append("status", select.status.phaseText);

    formdata.append("label", select["label"].id);
    // formdata.append("tags", data.tags);
    formdata.append("is_featured", select["featured"].id);
    if (select["Category"] != null) {
      formdata.append("Category[]", select["Category"]._id);
    }
    console.log(thumbnail);  
    formdata.append("image", image ? image : data.image);
    formdata.append("youtube_url", data.youtube_url);
    formdata.append("description", data.description);

    formdata.append("gst", select["gst"].id);

    if (thumbnail) {
      Object.keys(thumbnail).forEach(function(key, index) {
        formdata.append("thumbnail", thumbnail[key]);
    })
  }
    // console.log(e.target.elements.design)
    if(categoryBlock.showStartweight != 'none'){
      if (select["s_weight"]) {
        formdata.append("startingweight", select["s_weight"].id);
      }
      if (select["e_weight"]) {
        formdata.append("endingweight", select["e_weight"].id);
      }
    }
    if(categoryBlock.showMetal != 'none'){
      var k = 0;
      Object.keys(e.target.elements.Material).map(function (item, i) {
        if (e.target.elements.Material[i].checked == true) {
          formdata.append(
            "Material[" + k + "]",
            e.target.elements.Material[i].value
          );
          k++;
        }
      });

      var k = 0;
      design.map(function (item, i) {
        
        formdata.append("design[" + k + "]", item._id);
            k++;
      
        });
    }
    if(categoryBlock.showAddonType != 'none'){
      var k = 0;
      Object.keys(e.target.elements.addon).map(function (item, i) {
        if (e.target.elements.addon[i].checked == true) {
          formdata.append("addOn[" + k + "]", e.target.elements.addon[i].value);
          k++;
        }
      });
    }
    else{
       formdata.append("startingweight", 1);
       formdata.append("endingweight", 1);
    }
    var config = {
      method: "post",
      url: process.env.REACT_APP_BASE_URL+"admin/addProduct",
      headers: {
        // 'Content-Type': 'application/json',
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4",
      },
      data: formdata,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        toast.success("Product Add Success", { autoClose: 15000 });
        navigate("/Product/Manage");
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status == 500) {
            toast.error(error.response.data.message, { autoClose: 8000 });
          } else {
            // Request made and server responded
            toast.error(error.response.data, { autoClose: 8000 });
          }
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("The request was made but no response was received", {
            autoClose: 8000,
          });
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message, { autoClose: 8000 });
          console.log("Error", error.message);
        }
        console.log(error);
      });
  }

  function onSelect(selectedList, selectedItem) {
    if (data.category) {
      data.category = data.category + "," + selectedItem.id;
    } else {
      data.category = selectedItem.id;
    }
    console.log(data.category);
  }

  function onRemove(selectedList, removedItem) {
    data.category = removeValue(data.category, removedItem.id, ",");
    console.log(data.category);
  }
  function removeValue(list, value, separator) {
    separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }

  function handle(e) {
    const isValid = validateForm();
    console.log(isValid);
    const newdata = { ...data };

    // if(e.name == "description"){
    //   newdata['description'] = e.getData();
    // }else{

    if (e.target.type === "checkbox") {
      if (e.target.id == "design" || e.target.id == "design") {
      } else {
        if (e.target.value == true) {
          newdata[e.target.id] = 0;
        } else {
          newdata[e.target.id] = 1;
        }
      }
    } else if (e.target.type == "file") {
      if (e.target.id == "image") {
        setSelectedImage(e.target.files[0]);
      } else {
       setSelectedthumbnail(e.target.files)
            }
    } else {
      newdata[e.target.id] = e.target.value;
    }
  // }
    fetchdata(newdata);
  }

  function deleteImg(e) {
    // console.log(e.target.index);

    // const list = thumbnailpath;
    // list.splice(e.target.index, 1);
    // console.log([list])

    // setthumbpathimage(list);

    let taskList = [...thumbnailpath];
    console.log(taskList);
    taskList.splice(e.target.index, 1);
    setthumbpathimage(taskList);

    let thumbnaildata = [...thumbnail];
    thumbnaildata.splice(e.target.index, 1);
    setSelectedthumbnail(thumbnaildata);
  }
//   const handleInstanceReady = ( { editor } ) => {
//     // Will be triggered only once, when editor is ready for interaction.
  
//         editor.setData( data.description );
  
// };

  useEffect(() => {
    getAddonData();
    getMaterialData();
    getProductData();
    getCategoryData();
    getDesignData();
  
  }, []);

  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ToastContainer autoClose={8000} />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title">Product </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="" className='Cursor'>Product</NavLink>
            </li>
            <li className="breadcrumb-item active">Add</li>
          </ol>
        </div>
        <div className="page-content">
          <div className="panel"  style={{backgroundColor:"#f1f4f5"}}>
            <form
              onSubmit={(e) => submit(e)}
              className="row g-3 validate-me"
              id="validate-me"
              data-validate
            >
              <div className="panel-body container-fluid">
                <div className=" row row-lg" style={{ margin: "0 0 0 10px" }}>
                  {/* <h4 className="example-title">Add New Product Form</h4> */}

                  <div className="col-md-6">
                    {/* <!-- Example Basic Form (Form grid) --> */}
                    <div className="example-wrap">
                      <div className="example">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                onChange={(e) => handle(e)}
                                value={data.ProductName}
                                className="form-control"
                                id="ProductName"
                                name="ProductName"
                                placeholder="Product Name"
                                autocomplete="off"
                                required
                              />
                              <label
                                for="ProductName"
                                
                              >
                                Product Name
                                <span style={{ color: "red" }}>**</span>
                              </label>
                            </div>
                          </div>
                        </div>

                           <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                onChange={(e) => handle(e)}
                                value={data.subtitle}
                                className="form-control"
                                id="subtitle"
                                name="subtitle"
                                placeholder="Sub-title"
                                autocomplete="off"
                                required
                              />
                              <label
                                for="subtitle"
                              >
                                Sub Title
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <label
                              for="label"
                              
                            >
                              Product Label
                              <span style={{ color: "red" }}>**</span>
                            </label>
                            <Select
                              isSearchable
                              options={datalabel}
                              getOptionLabel={(option) => option.phaseText}
                              getOptionValue={(option) => option.phaseText}
                              className="diMultiSelect"
                              classNamePrefix="diSelect"
                              id="label"
                              name="label"
                              //  styles={styles}
                              maxMenuHeight={150}
                              value={select.label} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <div className="form-floating">
                              <input
                                type="number"
                                onChange={(e) => handle(e)}
                                min="1"
                                className="form-control"
                                id="minOrderQty"
                                name="minOrderQty"
                                placeholder="Minium Order Quantity"
                                autocomplete="off"
                              />
                              <label for="minOrderQty">
                                Minium Order Quantity
                              </label>
                            </div>
                          </div>

                          <div className="form-group col-md-6">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                className="form-control"
                                min="1"
                                max="1000"
                                id="maxOrderQty"
                                name="maxOrderQty"
                                type="number"
                                placeholder="Maxmium Quantity"
                                autocomplete="off"
                              />
                              <label for="maxOrderQty">Maxmium Quantity</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="url"
                                onChange={(e) => handle(e)}
                                value={data.youtube_url}
                                className="form-control"
                                id="youtube_url"
                                name="youtube_url"
                                placeholder="YouTube url"
                                autocomplete="off"
                              />
                              <label for="youtube_url">YouTube url</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-group">
                              <div className="form-control-wrap">
                                <div className="custom-file">
                                  <label for="file">Choose Product Image</label>
                                  <input
                                    type="file"
                                    accept="image/png, image/gif, image/jpeg"
                                    className="form-control "
                                    onChange={(e) => handle(e)}
                                    name="image"
                                    id="image"
                                    aria-label="Choose Category Image"
                                    placeholder="Choose  Image"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <h3>Bootstrap image checkbox(multiple)</h3> */}
                      </div>
                    </div>

                    {/* <!-- End Example Basic Form (Form grid) --> */}
                  </div>

                  <div className="col-md-6">
                    {/* <!-- Example Basic Form (Form grid) --> */}
                    <div className="example-wrap">
                      {/* <!-- <h4 className="example-title">Add New Product Form</h4> --> */}
                      <div className="example">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                onChange={(e) => handle(e)}
                                value={data.sku}
                                className="form-control "
                                id="sku"
                                name="sku"
                                placeholder="Code/SKU"
                                autocomplete="off"
                                required
                              />
                              <label
                                for="sku"
                                
                              >
                                Code/SKU
                                <span style={{ color: "red" }}>**</span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <label
                              for="status"
                              
                            >
                              Product Status
                              <span style={{ color: "red" }}>**</span>
                            </label>
                            <Select
                              isSearchable
                              options={status}
                              getOptionLabel={(option) => option.phaseText}
                              getOptionValue={(option) => option.phaseText}
                              className="diMultiSelect select_boxes_3"
                              classNamePrefix="diSelect"
                              id="status"
                              name="status"
                              //  styles={styles}
                              maxMenuHeight={150}
                              value={select.status} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required
                            />
                          </div>

                          <div className="form-group col-md-6">
                            <label
                              for="is_featured"
                              
                            >
                              Featured Product
                              <span style={{ color: "red" }}>**</span>
                            </label>
                            <Select
                              isSearchable
                              options={is_featuredData}
                              getOptionLabel={(option) => option.phaseText}
                              getOptionValue={(option) => option.phaseText}
                              className="diMultiSelect select_boxes_3"
                              classNamePrefix="diSelect"
                              id="is_featured"
                              name="is_featured"
                              //  styles={styles}
                              maxMenuHeight={150}
                              value={select.is_featured} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-6">
                            <label
                              for="label"
                              
                            >
                              Product Category
                              <span style={{ color: "red" }}>**</span>
                            </label>
                            <Select
                              isSearchable
                              options={arr.categoryData}
                              getOptionLabel={(option) => option.value}
                              getOptionValue={(option) => option._id}
                              className="diMultiSelect select_boxes"
                              classNamePrefix="diSelect"
                              id="label"
                              name="label"
                              //  styles={styles}
                              maxMenuHeight={150}
                              value={select.Category} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required
                              ref={showAddcat}
                            />
                            
                           
                          </div>
                          <div className="form-group col-md-6">
                            <label
                              for="gst"
                              
                            >
                              Gst <span style={{ color: "red" }}>**</span>
                            </label>
                            <Select
                              className="select_boxes"
                              options={gst}
                              getOptionLabel={(option) => option.phaseText}
                              getOptionValue={(option) => option.id}
                              value={select.gst} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            {/* new code of file upload btn */}
                            <div className="">
                              <label for="file" style={{ zIndex: "0" }}>
                                Choose Thumbnail Image
                              </label>
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                className="form-control "
                                onChange={(e) => handle(e)}
                                name="thumbnail"
                                id="thumbnail"
                                aria-label="Select thumbnail Image"
                                placeholder="Choose thumbnail Image"
                                multiple
                              />
                            </div>

                            <div style={{ display: "flex" }}>
                                  {thumbnailpath?.map((file, key) => {
                                    return (
                                      <div className="title">
                                        <img
                                          src={file ? file : data.thumbnail}
                                          width="40"
                                          height="40"
                                        />

                                        <div className="image-removal-link">
                                          <button
                                            type="button"
                                            className="btn btn-warning"
                                            index={key}
                                            onClick={(e) => deleteImg(e)}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {/* <p>Description</p> */}

                     
                        <label for="floatingTextarea2">
                          Enter Product Description
                        </label>
                         {/* <CKEditor
                      name="description"
                       id="description"
                data={data.description}
                onChange={ ( { editor } ) => handle(editor)}
                onInstanceReady={handleInstanceReady}
            /> */}
             <textarea  name="description"
            onChange={(e) => handle(e)}
                       id="description"
                       value={data.description}
                        style={{height:"200px", width:"100%"}}/>
            <br/>
                    </div>


                    <div className=" mt-3  mb-3" style={{display:categoryBlock.showAddonType}}>
                      <div className="col-md-9 col-sm-6" >
                        <div className="preview-block" >
                          <span className="preview-title overline-title mb-5" >
                            Attach addOn
                          </span>
                          <br />

                          {addOn.map((item) => {
                            <div className="form-check form-check-inline" >
                              <input
                                type="radio"
                                className="form-check-input"
                                value={item._id}
                                name="addon"
                                id="addon"
                                onChange={(e) => handle(e)}
                                defaultChecked={
                                  item._id == data.addOn ? true : false
                                }
                              />
                              <label
                                className="form-check-label"
                                for="addon"
                              ></label>
                            </div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" >
                    <div className="col-md-3" style={{display:categoryBlock.showStartweight}}>
                      <div className="form-group mb-3" >
                        <div className="form-control-wrap focused">
                          <Select
                            options={s_weight}
                            getOptionLabel={(option) => option.phaseText}
                            getOptionValue={(option) => option.id}
                            value={select.s_weight} // this doesn't let me click options
                            onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3" style={{display:categoryBlock.showEndweight}}>
                      <div className="form-group mb-3" >
                        <div className="form-control-wrap focused">
                          <Select
                            options={e_weight}
                            name="e_weight"
                            getOptionLabel={(option) => option.phaseText}
                            getOptionValue={(option) => option.id}
                            value={select.e_weight} // this doesn't let me click options
                            onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control form-control-outlined"
                              id="mrp"
                              name="mrp"
                              placeholder="Mrp"
                              value={data.mrp}
                              onChange={(e) => handle(e)}
                              required
                            />
                            <label
                              for="Price"
                              
                            >
                              Mrp <span style={{ color: "red" }}>**</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control form-control-outlined"
                              id="DiscountPrice"
                              name="DiscountPrice"
                              value={data.DiscountPrice}
                              placeholder="Selling Price"
                              onChange={(e) => handle(e)}
                              required
                            />
                            <label
                              for="DiscountPrice"
                              
                            >
                              Selling Price
                              <span style={{ color: "red" }}>**</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{display:categoryBlock.showAddonType}}>
                    {addOn.map((item) => {
                      return (
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item._id}
                            name="addon"
                            id="addon"
                            onChange={(e) => handle(e)}
                          />
                          <label className="form-check-label" for="inlineCheckbox1">
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="row" style={{display:categoryBlock.showMetal}}>
                    <p>Material</p>
                    {Material.map((item) => {
                      return (
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item._id}
                            name="Material"
                            id="Material"
                            onChange={(e) => handle(e)}
                          />
                          <label className="form-check-label" for="Material">
                            {item.value}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  {/* <div className="row">
                       {design.map((item) => {
                return (
                       <div className="col-md-3">
                                                     
                                                      <label className="image-checkbox">
        <img className="img-responsive" src={item.image} style={{height:"200px"}}/>
        <input type="checkbox" name="design[]"  id="design" value={item._id} onChange={(e)=>handle(e)} />
        <i className="fa fa-check hidden"></i>
      </label>
                </div>
                )}
                       )}
          
                       </div> */}

                  <div className="row">
                    <div className="col-md-12">
                      <button type="submit" className="btn btn-success btn-block" >
                        submit
                      </button>
                    </div>
                  </div>
                </div>
                </div>
              
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
