import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-js-loader";
import Select from 'react-select';

const Edit = (props) => {
  const navigate = useNavigate();
      const [user, fetchUsers] = useState([])
    const [loading, setLoading] = useState(false);
    let [arr, setArrList] = useState([]);
    
  const HandelChange = (obj) => {
    const newdata = { ...select };

      newdata['userMemberId'] = obj
    
    
    fetchselectdata(newdata);
   console.log(obj)
  };
    const [simple, fetchdata] = useState({
      "name": "",
      "type": "",
      "discount": "",
      "userMemberId": [],
      "discountType": "",
      "code": "",
      "amount": "",
      "maxdiscount": "",
      "startDate": "",
      "userSelect" : "",
      "endDate": "",
      "minCartValue": "",
      "status": ""
  })
  const location = useLocation();
   const data = location.state;
  const [image, setSelectedImage] = useState(null);
  const [select, fetchselectdata] = useState({
    Category: null,
  });

  const getData = () => {
  console.log(data.data.userMemberId)
        fetchdata(data.data);
        const newdata={}
      
        data.data.userMemberId?.map((item, key) => {
         
          newdata['userMemberId'] = user.find(obj => item == obj._id);
      
          
         })
        
       console.log(newdata)
        
        fetchselectdata(newdata)
    
       }

  function submit(e){
    setLoading(true)
  
    e.preventDefault();
    var axios = require('axios');
    var formdata = JSON.stringify({
      "name": simple.name,
      "type": 'coupon',
      "discount": simple.discount,
      "discountType": simple.discountType,
      "code": simple.code,
      "amount": simple.amount,
      "maxdiscount": simple.maxdiscount,
      "startDate": simple.startDate,
      "userSelect": simple.userSelect,
      "userMemberId": select.userMemberId,
      "endDate": simple.endDate,
      "minCartValue": simple.minCartValue,
      "status": simple.status
    });


    
    var config = {
              method: 'patch',
              url: process.env.REACT_APP_BASE_URL+"admin/updateOffer/"+data.data._id,
              headers: { 
                'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
                'Content-Type': 'application/json'
              },
              data : formdata
            };
            
            axios(config)            


.then(function (response) {
            setLoading(false)
            navigate('/Setting/Offer');
                // fetchUsers(JSON.parse(res))
                // const newdata = { ...select };
                // if(res.userSelect != null){
                //   newdata['userSelect'] =  { id: res.userSelect, phaseText: res.userSelect, type:'userSelect' };
                //  }
                //  fetchselectdata(newdata);
          })
.catch(function (error) {
  setLoading(false)
  if (error.response) {
    if(error.response.status == 500){
      toast.error(error.response.data._message, { autoClose: 8000 })
    }else{
    // Request made and server responded
    toast.error(error.response.data, { autoClose: 8000 })
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    toast.error('The request was made but no response was received', { autoClose: 8000 })
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message, { autoClose: 8000 })
    console.log('Error', error.message);
  }
  console.log(error);
});

    }
      
      function handle(e){
        const newdata={...simple}
        newdata[e.target.id] = e.target.value
        fetchdata(newdata)
        console.log(newdata)
      }

      const getMemberData = () => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
    fetch(process.env.REACT_APP_BASE_URL+"admin/getAllMembers", requestOptions)
    .then(response => response.text())
          .then((res) => {
            setLoading(false)
                fetchUsers(JSON.parse(res))
                const newdata = { ...select };
              
          })
          .catch(error => console.log('error', error));
      }
     
  
    useEffect(() => {

      getMemberData();
      getData();  
     
    }, [])
  
    return loading? 
    <>
    <div className="App">
          
              
                <div className={"item"}>
                    <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
                </div>
         
        
        </div>
    </>
    :(
      <>
      <ToastContainer autoClose={8000} />
       <div className="page">
       <div className="page-header">
       <h1 className="page-title">Offer Edit</h1>
       <ol className="breadcrumb">
         <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
         <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
         <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Offer</NavLink></li>
         <li className="breadcrumb-item active">Edit</li>
      </ol>
       
       </div>
       <div className="page-content">
       <div className="panel">
       <div className="row">
         <div className="col-lg-12">
           {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
          <div className="panel-heading">
               <h3 className="panel-title">Edit Offer </h3>
           </div>

         
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)} className="validate-me"  id="validate-me" data-validate>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={simple.name} pattern="[a-zA-Z][a-zA-Z ]{2,}"  className="form-control" id="name" name="name"
                          placeholder="Offer Name" autocomplete="off" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Type</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    {/* <select  className="form-control" onChange={(e)=>handle(e)} value={data.display} id="display" name="display" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select> */}
                   
                     <input type="text" onChange={(e)=>handle(e)} readOnly value="coupon" className="form-control" id="type" name="type"
                          placeholder="Coupon Type" autocomplete="off" required/>
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Discount</label>
                  <div className="col-md-9">
                     <input type="number" onChange={(e)=>handle(e)} value={simple.discount}    className="form-control" id="discount" name="discount"
                          placeholder=" Discount" autocomplete="off" required/>
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Discount Type</label>
                  <div className="col-md-9">
                {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                     <select  className="form-control" onChange={(e)=>handle(e)} value={simple.discountType}  id="discountType" name="discountType" >
                      <option >Select Any one</option>
                      <option>Percentage</option>
                      <option>Fixed</option>
                    </select> 
                  </div>
                </div>


                <div className="form-group row">
                  <label className="col-md-3 form-control-label">code</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
                    <input type="text" onChange={(e)=>handle(e)} value={simple.code}  className="form-control" id="code" name="code"
                          placeholder="Code" required/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Amount</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
                    <input type="number" onChange={(e)=>handle(e)} value={simple.amount}   className="form-control" id="amount" name="amount"
                          placeholder="Amount" required/>
                  </div>
                </div>

               

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Maximum Discount</label>
                  <div className="col-md-9">
                  {/* <label for="content">Maxmum Discount</label> */}
                  <input type="number" onChange={(e)=>handle(e)} value={simple.maxdiscount}  className="form-control" id="maxdiscount" name="maxdiscount"
                          placeholder="Maximum Discount" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Start Date</label>
                  <div className="col-md-9">
                  {/* <label for="content">Maxmum Discount</label> */}
                  <input type="date" onChange={(e)=>handle(e)} value={simple.startDate}  className="form-control" id="startDate" name="startDate"
                          placeholder="Start Date" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">End Date</label>
                  <div className="col-md-9">
                  {/* <label for="content">Maxmum Discount</label> */}
                  <input type="date" onChange={(e)=>handle(e)} value={simple.endDate}  className="form-control" id="endDate" name="endDate"
                          placeholder="End Date" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">User Select</label>
                  <div className="col-md-9">
                  {/* <label for="content">Maxmum Discount</label> */}
                  <select  className="form-control" onChange={(e)=>handle(e)} value={simple.userSelect}  id="userSelect" name="userSelect" >
                      <option value="astrologer">astrologer</option>
                      <option value="member">member</option>
                      <option value="all">all</option>
                      <option value="array">Selected</option>
                    </select> 
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">User MemberId</label>
                  <div className="col-md-9">
                  <Select
      isSearchable
      options={user}
      
      getOptionLabel={(option) => option.firstName}
      getOptionValue={(option) => option._id}
      className="diMultiSelect"
      classNamePrefix="diSelect"
      isMulti="true"
      id="label"
      name="label"
      //  styles={styles}
      maxMenuHeight={150}
      value={select.userMemberId} // this doesn't let me click options
      onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
      required
    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Minimum cart Value</label>
                  <div className="col-md-9">
                  {/* <label for="content">Minmum cart Value</label> */}
                  <input type="number" onChange={(e)=>handle(e)} value={simple.minCartValue}  className="form-control" id="minCartValue" name="minCartValue"
                          placeholder="Minimum cart Value" required/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Status</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handle(e)} value={simple.status} id="status" name="status">
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    </div>
    
  </div>
  </div>
  
           </>
    )
    }

export default Edit;









// import React, { useState, useEffect } from 'react'
// import { useLocation, useNavigate } from 'react-router-dom';
// import Loader from "react-js-loader";
// import { ToastContainer, toast } from 'react-toastify';
// import Select from 'react-select';
// const Edit = (props) => {
//   const navigate = useNavigate();
//   const [user, fetchUsers] = useState([])
//   const [loading, setLoading] = useState(false);
//   const HandelChange = (obj) => {
//     const newdata = { ...select };

//       newdata['userMemberId'] = obj
    
    
//     fetchselectdata(newdata);
//    console.log(obj)
//   };
//   const [simple, fetchdata] = useState({
//    "name": "",
//       "type": "",
//       "discount": "",
//       "userMemberId": [],
//       "discountType": "",
//       "code": "",
//       "amount": "",
//       "maxdiscount": "",
//       "startDate": "",
//       "userSelect" : "",
//       "endDate": "",
//       "minCartValue": "",
//       "status": ""
// })
//   const location = useLocation();
//   const data = location.state;


 
//   const getData = () => {
  
//     fetchdata(data.data)

//   }

//   const [select, fetchselectdata] = useState({
//     Category: null,
//   });

//   function submit(e){
//     setLoading(true);
//     e.preventDefault();
 
//     var axios = require('axios');
//     var formdata = JSON.stringify({
//       "name": simple.name,
//       "type": 'coupon',
//       "discount": simple.discount,
//       "discountType": simple.discountType,
//       "code": simple.code,
//       "amount": simple.amount,
//       "maxdiscount": simple.maxdiscount,
//       "startDate": simple.startDate,
//       "userSelect": simple.userSelect,
//       "userMemberId": select.userMemberId,
//       "endDate": simple.endDate,
//       "minCartValue": simple.minCartValue,
//       "status": simple.status
//       });
      
//       var config = {
//         method: 'patch',
//         url: 'process.env.REACT_APP_BASE_URL+"admin/updateOffer/'+data.data._id,
//         headers: { 
//           'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
//           'Content-Type': 'application/json'
//         },
//         data : formdata
//       };
      
//       axios(config)
//       .then(function (response) {
//         setLoading(false);
             
//         console.log(response.data);
//         navigate('/Setting/Offer');
       
//       })
//       .catch(function (error) {
//         setLoading(false)
//         if (error.response) {
//           if(error.response.status == 500){
//             toast.error(error.response.data._message, { autoClose: 8000 })
//           }else{
//           // Request made and server responded
//           toast.error(error.response.data, { autoClose: 8000 })
//           }
//           console.log(error.response.data);
//           console.log(error.response.status);
//           console.log(error.response.headers);
//         } else if (error.request) {
//           // The request was made but no response was received
//           toast.error('The request was made but no response was received', { autoClose: 8000 })
//           console.log(error.request);
//         } else {
//           // Something happened in setting up the request that triggered an Error
//           toast.error(error.message, { autoClose: 8000 })
//           console.log('Error', error.message);
//         }
//         console.log(error);
//       });

//     }
    
//     function handle(e){
//       const newdata={...simple}
//       newdata[e.target.id] = e.target.value
//       fetchdata(newdata)
//       console.log(newdata)
//     }

//   useEffect(() => {
  
//     getData();
//   }, [])

//   return loading? 
//     <>
//     <div className="App">
          
              
//                 <div className={"item"}>
//                     <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
//                 </div>
         
        
//         </div>
//     </>
//     :(
//       <>
//       <ToastContainer autoClose={8000} />
//        <div className="page">
//       <div className="page-header">
//       <h1 className="page-title">Offer Edit</h1>
//       <ol className="breadcrumb">
//         <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
//         <li className="breadcrumb-item"><a href="/">Setting</a></li>
//         <li className="breadcrumb-item"><a href="">Offer</a></li>
//         <li className="breadcrumb-item active">Edit</li>
//       </ol>
       
//       </div>
//       <div className="page-content">
//       <div className="panel">
//       <div className="row">
//         <div className="col-lg-12">
//           {/* <!-- Panel Standard Mode --> */}
//           <div className="panel">
//             <div className="panel-heading">
//               <h3 className="panel-title">Edit Offer </h3>
//             </div>

         
//             <div className="panel-body">
//             <form onSubmit={(e)=> submit(e)} className="validate-me"  id="validate-me" data-validate>
//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Name</label>
//                   <div className="col-md-9">
//                   <input type="text" onChange={(e)=>handle(e)} value={simple.name} pattern="[a-zA-Z][a-zA-Z ]{2,}"  className="form-control" id="name" name="name"
//                           placeholder="Offer Name" autocomplete="off" required/>
//                   </div>
//                 </div>
//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Type</label>
//                   <div className="col-md-9">
//                   {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

//                     {/* <select  className="form-control" onChange={(e)=>handle(e)} value={data.display} id="display" name="display" >
//                       <option >Select Any one</option>
//                       <option value='1'>Yes</option>
//                       <option value='0'>No</option>
//                     </select> */}
                   
//                      <input type="text" onChange={(e)=>handle(e)} readOnly value="coupon" className="form-control" id="type" name="type"
//                           placeholder="Coupon Type" autocomplete="off" required/>
//                   </div>
//                 </div>


//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Discount</label>
//                   <div className="col-md-9">
//                      <input type="number" onChange={(e)=>handle(e)} value={simple.discount}    className="form-control" id="discount" name="discount"
//                           placeholder=" Discount" autocomplete="off" required/>
//                   </div>
//                 </div>


//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Discount Type</label>
//                   <div className="col-md-9">
//                 {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

//                      <select  className="form-control" onChange={(e)=>handle(e)} value={simple.discountType}  id="discountType" name="discountType" >
//                       <option >Select Any one</option>
//                       <option>Percentage</option>
//                       <option>Fixed</option>
//                     </select> 
//                   </div>
//                 </div>


//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">code</label>
//                   <div className="col-md-9">
//                   {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
//                     <input type="text" onChange={(e)=>handle(e)} value={simple.code}  className="form-control" id="code" name="code"
//                           placeholder="Code" required/>
//                   </div>
//                 </div>

//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Amount</label>
//                   <div className="col-md-9">
//                   {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    
//                     <input type="number" onChange={(e)=>handle(e)} value={simple.amount}   className="form-control" id="amount" name="amount"
//                           placeholder="Amount" required/>
//                   </div>
//                 </div>

               

//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Maximum Discount</label>
//                   <div className="col-md-9">
//                   {/* <label for="content">Maxmum Discount</label> */}
//                   <input type="number" onChange={(e)=>handle(e)} value={simple.maxdiscount}  className="form-control" id="maxdiscount" name="maxdiscount"
//                           placeholder="Maximum Discount" required/>
//                   </div>
//                 </div>

//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Start Date</label>
//                   <div className="col-md-9">
//                   {/* <label for="content">Maxmum Discount</label> */}
//                   <input type="date" onChange={(e)=>handle(e)} value={simple.startDate}  className="form-control" id="startDate" name="startDate"
//                           placeholder="Start Date" required/>
//                   </div>
//                 </div>
//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">End Date</label>
//                   <div className="col-md-9">
//                   {/* <label for="content">Maxmum Discount</label> */}
//                   <input type="date" onChange={(e)=>handle(e)} value={simple.endDate}  className="form-control" id="endDate" name="endDate"
//                           placeholder="End Date" required/>
//                   </div>
//                 </div>
//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">User Select</label>
//                   <div className="col-md-9">
//                   {/* <label for="content">Maxmum Discount</label> */}
//                   <select  className="form-control" onChange={(e)=>handle(e)} value={simple.userSelect}  id="userSelect" name="userSelect" >
//                       <option value="astrologer">astrologer</option>
//                       <option value="member">member</option>
//                       <option value="all">all</option>
//                       <option value="array">Selected</option>
//                     </select> 
//                   </div>
//                 </div>
//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">User MemberId</label>
//                   <div className="col-md-9">
//                   <Select
//       isSearchable
//       options={user}
      
//       getOptionLabel={(option) => option.firstName}
//       getOptionValue={(option) => option._id}
//       className="diMultiSelect"
//       classNamePrefix="diSelect"
//       isMulti="true"
//       id="label"
//       name="label"
//       //  styles={styles}
//       maxMenuHeight={150}
//       value={select.userMemberId} // this doesn't let me click options
//       onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
//       required
//     />
//                   </div>
//                 </div>


//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Minimum cart Value</label>
//                   <div className="col-md-9">
//                   {/* <label for="content">Minmum cart Value</label> */}
//                   <input type="number" onChange={(e)=>handle(e)} value={simple.minCartValue}  className="form-control" id="minCartValue" name="minCartValue"
//                           placeholder="Minimum cart Value" required/>
//                   </div>
//                 </div>

//                 <div className="form-group row">
//                   <label className="col-md-3 form-control-label">Status</label>
//                   <div className="col-md-9">
//                   {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

//                     <select  className="form-control" onChange={(e)=>handle(e)} value={simple.status} id="status" name="status">
//                       <option >Select Any one</option>
//                       <option value='1'>Yes</option>
//                       <option value='0'>No</option>
//                     </select>
//                   </div>
//                 </div>
               
//                 <div className="text-right">
//                 <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
//                 </div>
//               </form>
//             </div>
//           </div>
//           {/* <!-- End Panel Standard Mode --> */}
//         </div>

//       </div>

//     </div>
    
//   </div>
//   </div>
  
//            </>
//     )
//     }

// export default Edit
