import React, { useState, useEffect } from 'react'
import Loader from "react-js-loader";
import {Link, NavLink} from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  const [data, fetchUsers] = useState([])
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Image",
      selector: "profileImage",
      sortable: false,
      cell: (d) => (
        <img src={d.profileImage}  onError={({ currentTarget }) => {
     currentTarget.onerror = null; // prevents looping
     currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
   }}  width="40" height="40" style={{ margin: "4px"}} />
       )
    },
    {
      name: "Name",
      selector: "firstName",
      sortable: true,
     
    },
    {
      name: "PhoneNumber",
      selector: "phone",
      sortable: true,
    
    },
    {
      name: "Email",
      selector: "emailAddress",
      sortable: true
    },
    {
      name: "wallet Balance",
      selector: "walletBalance",
      sortable: true
    },
    
    {
      name: "Action",
      sortable: false,

      cell: (d) => [
        <>
         
         
         <Link to="/ecom-product" state= {{
          id: d._id,
          type:'member'
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" title="User Chat"><span className="badge badge-secondary">Order</span></Link>
         <Link to="/chat/user" state= {{
          id: d._id,
          type:'member'
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" title="User Chat"><span className="badge badge-secondary">Chat</span></Link>

<Link to="/call/user" state= {{
          id: d._id,
          type:'member'
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" title="User Call"><span className="badge badge-secondary">Call</span></Link>

<Link to="/transaction/user" state= {{
          id: d._id,
          type:'member'
        }}   className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" title="User Transaction"><span className="badge badge-secondary">Transections</span></Link>

<Link to="/recharge/user" state= {{
          id: d._id,
          type:'member'
        }}   className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" title="User Recharge"><span className="badge badge-secondary">Recharge</span></Link>



<Link to="/Users/edit" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
           


                          {/* <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button> */}
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  const getData = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/getAllMembers", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false)
            fetchUsers(JSON.parse(res))
      })
      .catch(error => console.log('error', error));
  }
  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/"+e+"/deleteMember", requestOptions)

.then(response => response.text())
      .then((res) => {
        getData();
            setLoading(false)
            toast.success("Data Addedd Successfully!", { autoClose: 15000 })
          })
          .catch(error =>  toast.error(error, { autoClose: 15000 }));
    
      }


     

  useEffect(() => {
    getData()
  }, [])
  return loading? 
  <>
  <div className="App">
        
            
              <div className={"item"}>
                  <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
              </div>
       
      
      </div>
  </>
  
  :  (
    <>
    <ToastContainer autoClose={8000} />
    <div className="page">
      
    <div className="page-header">
      <h1 className="page-title">Users</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Users</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
