import React, { useState, useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link} from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {

  // conferm _Box..........
 

  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  const [value, setvalue] = useState({
    
    "last_payout_date": "",
      "payout_amount": "",
      "current_balance": "",
      "status": "",
      "message":"",
      "other": ""
  })

   const columns = [
   
    
    {
      name: "Name",
      selector:row=> row.name,
      sortable: true,
     
    },
    {
      name: "Payout Dates",
      selector:row=> row.payDate1+', '+row.payDate2,
      sortable: true,
     
    },
    {
      name: "Min. Payout",
      selector:row=> row.minPayout,
      sortable: true,
     
    },
    {
      name: "Wallet Balance",
      selector: row=> row.walletBalance,
      sortable: true,
    
    },

    {
      name: "Status",

      sortable: true,
      cell: (d) => [
        <>
        {console.log(process.e)}
        <h1><button type='button' onClick={(e) => process(e,d)} className='btn btn-success'>pay</button></h1>
       {/* { alert(process.d)} */}
      </>
      ]
    },

 
  ];
  let tableData = {
    columns,
    data
  }

function process(e,d) {
  e.preventDefault();
  e.stopPropagation();
  confirmAlert({
    title: 'Confirm to Pay',
    message: 'Are you sure to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () => {
      //  console.log(d.walletBalance)
          if(Number(d.walletBalance?d.walletBalance:0) < Number(d.minPayout)){
            alert('Astrologer wallet balence is '+d.walletBalance+' lower then min. payout balance '+d.minPayout)
     
          }
          else{
          var axios = require('axios');
          var data = JSON.stringify({
            "astro_id": d._id,
            "last_payout_date": d.payDate1,
            "payout_amount": d.walletBalance,
            "current_balance":  d.walletBalance,
            "status": 'Aproove',
            "message": d.walletBalance,
            "other": 'other'
          });
        
         
          
          var config = {
            method: 'post',
            url: process.env.REACT_APP_BASE_URL+"Payout/Aproove",
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            console.log(response.data)
            console.log(JSON.prase(response.data));
            setvalue(JSON.prase(response.data))
          
          })
          .catch(function (error) {
            console.log(error);
          });
        }
        }
      },
      {
        label: 'No',
       
      }
    ]
  });

 

}


// list.........................


  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"Payout/1/15", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
        console.log(JSON.parse(res));
            fetchUsers(JSON.parse(res)
            )
      })
      .catch(error => console.log('error', error));
  }



  useEffect(() => {
    getData()
  
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
    
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> PayOut</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="../index.html">Dashboard</a></li>
        <li className="breadcrumb-item"><a href="">PayOut</a></li>
        <li className="breadcrumb-item active">Today PayOut</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
