import React, { useState, useEffect } from 'react'


import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";


const Product  = (props) => {
  const [user, fetchUsers] = useState([])


  const state = {
    // options: [{name: 'Option 1️⃣', id: 1, selectedValue: false},{name: 'Option 2️⃣', id: 2, selectedValue:true}]
};
 
  const [catData,setData]=useState([])
  const [loading, setLoading] = useState(false);
  const [data, fetchdata] = useState({
    
    "id": "",
            "value": "",
            "specilizationImage": "",
            "display": false
          
});
const [editdata, fetchdataedit] = useState({
          "id": "",
          "valueedit": "",
          "specilizationImageedit": "",
          "displayedit": false
        
});

function deleterow(e){
  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
  
  var formdata = new FormData();
  formdata.append("id", e);
  formdata.append("model", "addonProduct");
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formdata,
    redirect: 'follow'
  };

fetch("http://54.202.229.179:5000/api/master/deleteProductCategory", requestOptions)

.then(response => response.text())
    .then((res) => {
      getData();
          setLoading(false)
    })
    .catch(error => console.log('error', error));

}

const getData = () => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

// fetch("http://54.202.229.179:5000/api/master/listmaster", requestOptions)
fetch(process.env.REACT_APP_BASE_URL+"admin/productmaster/AddOn", requestOptions)
.then(response => response.text())
    .then((res) => {
          fetchUsers(JSON.parse(res))
    })
    .catch(error => console.log('error', error));
}


 
  function submit(e){
    // setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
   
    var formdata = new FormData();

    // formdata.append("id", data.id);
    formdata.append("value", data.value);
    formdata.append("specilizationImage", data.specilizationImage);
    formdata.append("display", data.display);
    formdata.append("model", "addonProduct");
    // formdata.append("createdAt", data.createdAt);
   
   
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
   


    fetch("http://54.202.229.179:5000/api/master/addMaster", requestOptions)
      .then(response => response.text())
      .then((res) => {
        console.log(JSON.parse(res))
        getData()
        // navigate('masters/Product/Manage');
      })
      .catch(error => console.log('error', error));
    }
 function submitedit(e){
      document.getElementById('close').click();
    // setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
   
    var formdata = new FormData();

    // formdata.append("id", data.id);
    formdata.append("value", data.value);
    formdata.append("specilizationImage", data.specilizationImage);
    formdata.append("display", data.display);
    formdata.append("model", "addonProduct");
    // formdata.append("createdAt", data.createdAt);
   
   
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
   


    fetch("http://54.202.229.179:5000/api/master/addMaster", requestOptions)
      .then(response => response.text())
      .then((res) => {
        console.log(JSON.parse(res))
        getData()
        // navigate('masters/Product/Manage');
      })
      .catch(error => console.log('error', error));
    }
    
    function handle(e){
      const newdata={...data}
      if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }

    function handleedit(e){
      const newdata={...data}
      if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={...data}
      newdata['id'] = item.id;
      newdata['valueedit'] = item.value;
      newdata['specilizationImageedit'] = '';
      newdata['displayedit'] = item.display;
    
      fetchdataedit(newdata);
    }
    useEffect(() => {
      getData();
    }, [])

  


// const AddonType = () => {

//   const [user, fetchUsers] = useState([])

//   const getData = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
    
//     var formdata = new FormData();
//     formdata.append("model", "AstrologerType");
    
//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: formdata,
//       redirect: 'follow'
//     };

// fetch("http://54.202.229.179:5000/api/master/listmaster", requestOptions)
// .then(response => response.text())
//       .then((res) => {
//             fetchUsers(JSON.parse(res).data)
//       })
//       .catch(error => console.log('error', error));
//   }

//   useEffect(() => {
//     getData()
//   }, [])

return loading? 
<>
<div className="App">
      
          
            <div className={"item"}>
                <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
            </div>
     
    
    </div>
</>
:  (
    <div className="page">
      <div className="page-header">
        <h1 className="page-title">Adon Product</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
          <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
          <li className="breadcrumb-item active">Adon Product</li>
        </ol>
       
      </div>

      <div className="page-content container-fluid">
        

        <div className="row">
          <div className="col-lg-12">
            {/* <!-- Panel Standard Mode --> */}
            <div className="panel">
              <div className="panel-heading">
                <h3 className="panel-title">Add Product AddonType</h3>
              </div>
              <div className="panel-body">
              <form onSubmit={(e)=> submit(e)}>
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Name</label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handle(e)} value={data.value}  className="form-control" id="value" name="value"
                          placeholder="Product Name" autocomplete="off" />
                    </div>
                  </div>
                
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Status</label>
                    <div className="col-md-9">
                        <div className="col-xl-4 col-md-6">
                          <input type="checkbox" onChange={(e)=>handle(e)}  value={data.display}   id="display" name="display" autocomplete="off"/>
                          </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Panel Standard Mode --> */}
          </div>

        </div>

      

       
      </div>
      <div className="page-content">
        <div className="panel">
            <header className="panel-heading">
              {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
            </header>
            <div className="panel-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-15">
                   
                  </div>
                </div>
              </div>
              <table className="table table-bordered table-hover table-striped" cellspacing="0" id="exampleAddRow">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {user.map((item) => {
                return (
                  <tr className="gradeA">
                    <td>{item.value}</td>
                    <td>{item.display}</td>
                   
                    <td className="actions">
                   
                    <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(item)}  data-toggle="modal" data-target="#exampleModalLong"
                        data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>
                        
                        <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(item.id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
                    </td>
                  </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
      </div>

      <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Addon Product</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">


      <form onSubmit={(e)=> submitedit(e)}>
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Name</label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handleedit(e)} value={editdata.valueedit}  className="form-control" id="valueedit" name="valueedit"
                          placeholder="Product Name" autocomplete="off" />
                    </div>
                  </div>
                
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Status</label>
                    <div className="col-md-9">
                        <div className="col-xl-4 col-md-6">
                          <input type="checkbox" onChange={(e)=>handleedit(e)}  value={editdata.displayedit}  id="displayedit" name="displayedit" autocomplete="off"/>
                          </div>
                    </div>
                  </div>
                 
                </form>
      </div>




      <div className="modal-footer">
      <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
    </div>
  </div>
</div>

    </div>
    
  )
}

export default Product
