import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import Loader from "react-js-loader";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { NavLink } from 'react-router-dom';


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchData] = useState([]);
  const location = useLocation();
  const dataid = location.state;

   const columns = [
     {
       name: "Order id",
       selector: row=> row.orderId,
       sortable: true,
     
     },
   
    {
      name: "User",
      selector:row=> row.memberId?.firstName,
      sortable: true,
     
    },
    {
      name: "Amount",
      selector:row=> row.amount,
      sortable: true,
     
    },
     
    {
      name: "Capture Method",
      selector:row=> row.capture_by,
      sortable: true,
     
    },
     

    // {
    //   name: "Status",

    //   sortable: true,
    //   cell: (d) => [
    //     <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" 
    //                       data-toggle="tooltip" data-original-title="Remove">{d.status == 0?<i className='icon wb-check' aria-hidden='rue'></i>:<i className='icon wb-close' aria-hidden='rue'></i>}</button>
   
    //   ]
    // },

    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
        <Link to="/view-recharge" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-eye" aria-hidden="true"></i></Link>
                         
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }



  const getData = () => {
    setLoading(true);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: dataid?process.env.REACT_APP_BASE_URL+"admin/all-recharge-history/"+`${dataid.id}`  : process.env.REACT_APP_BASE_URL+"admin/all-recharge-history",
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoading(false);
           
      console.log(response.data);
      fetchData(response.data)
     
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response) {
        if(error.response.status == 500){
          toast.error(error.response.data._message, { autoClose: 8000 })
        }else{
        // Request made and server responded
        toast.error(error.response.data, { autoClose: 8000 })
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('The request was made but no response was received', { autoClose: 8000 })
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(error.message, { autoClose: 8000 })
        console.log('Error', error.message);
      }
      console.log(error);
    });
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Recharge List</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Recharge</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
    
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
