import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import {Link, NavLink} from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])

   const columns = [
    {
      name: "Title",
      selector: row=> row.title,
      sortable: true,
     
    },
    {
      name: "Body",
      selector: row=> row.body,
      sortable: true,
    
    },
    {
      name: "Send To",
      selector: row=> row.sendto,
      sortable: true,
    
    },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
        <Link to="/setting/sendmsg" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }



  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/list-notification/sendto/allAstrologer?page=1&limit=2", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
            fetchUsers(JSON.parse(res).results)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
   <ToastContainer autoClose={8000} />
   <div className="page">
    <div className="page-header">
      <h1 className="page-title">SendMsg </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item active">SendMsg</li>
      </ol>
      <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="/setting/sendmsg">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">Add</span>
    </a>
      </div>
    </div>

    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>
    

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
     
    </div>
  </div>
</div>

  </div>
   </>
   
  )
}
export default Manage
