import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

const SendMsg = () => {
    const [loading, setLoading] = useState(false);
    const [user, fetchUsers] = useState([])
    const [catData,setData]=useState([])
    const [data, fetchdata] = useState({
        "trans_type": "",
        "title": "",
        "body": "",
        "image": "",
        "soundtype": "",
        "sendto": "",
        "msg_type": "",
        "other": ""
      
      });
function dropdown(e) {
   if(e == "member"){

   }else{

   }

}

    function submit(e){
        setLoading(true);
        e.preventDefault();

        var axios = require('axios');
var formdata = JSON.stringify({
  "trans_type": data.trans_type,
  "title": data.title,
  "body": data.body,
  "image":"Someimage.jpg",
  "soundtype": "sound",
  "sendto": data.sendto,
  "msg_type": data.msg_type,
  "other": "Other"
});

var config = {
  method: 'post',
  url: process.env.REACT_APP_BASE_URL+"admin/send-notification",
  headers: { 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
    'Content-Type': 'application/json'
  },
  data : formdata
};

axios(config)
.then(function (response) {
    setLoading(false)
  
    console.log(JSON.stringify(response.data));
   
    fetchdata({
        "trans_type": "",
        "title": "",
        "body": "",
        "image": "",
        "soundtype": "",
        "sendto": "",
        "msg_type": "",
        "other": ""

  
  })
  toast.success("MSG Send Success", { autoClose: 15000 });
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data._message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });
  

   
        }

        function handle(e){
  
            const newdata={...data}
            if(e.target.type == "file"){
      
            //    setSelectedImage(e.target.files[0]);    
             }
            
            else if(e.target.type === 'checkbox'){
              console.log(e.target.value)
              if(e.target.value === "true"){
                
                newdata[e.target.id] = false;
              }else{
                newdata[e.target.id] = true;
              }
            } else{
            newdata[e.target.id] = e.target.value
            }
            
            fetchdata(newdata)
          }
  return (
    <div className="page">
    <div className="page-content container-fluid">
       <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title"> Send MSG</h3>
            <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="/setting/sendmsg/list">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">View List</span>
    </NavLink>
      </div>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Message Transfer Type</label>
                  <div className="col-md-9">
                  <select  className="form-control" onChange={(e)=>handle(e)} value={data.trans_type} id="trans_type" name="trans_type" required>
                      <option >Select Any one</option>
                      <option value='firebase' selected>App Notification</option>
                      <option value='sms'>SMS</option>
                      <option value='mail'>E-mail</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Title</label>
                  <div className="col-md-9">
                    
                  <input type="text" onChange={(e)=>handle(e)} value={data.title}  className="form-control" id="title" name="title"
                          placeholder="Title" autocomplete="off" required/>
                  
                
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Body</label>
                  <div className="col-md-9">
                  <textarea onChange={(e)=>handle(e)} id="body" style={{ height: "150px" }} value={data.body}  className="form-control "  placeholder='body' type="description"></textarea>
                {/* <label for="floatingTextarea2">Body</label> */}
                  </div>
                </div>
                <div className="form-group row">
                <label className="col-md-3 form-control-label">Image</label>
                  <div className="col-md-9">
                      <input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handle(e)} name="image"   id="image" aria-label="Choose Category Image" placeholder='Choose  Image'  />
  <label for="file">Select  Image</label>
  </div>    
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Message Type</label>
                  <div className="col-md-9">
                  <select  className="form-control" onChange={(e)=>handle(e)} value={data.msg_type} id="msg_type" name="msg_type" >
                      <option >Select Any one</option>
                      <option value='offer' >Offer</option>
                      <option value='recharge' >Recharge</option>
                      <option value='chat' >Chat</option>
                      <option value='call' >Call</option>
                      <option value='order' >Order</option>
                    
                     
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Send To</label>
                  <div className="col-md-9">
                  <select  className="form-control" onChange={(e)=>handle(e)} value={data.sendto} id="sendto" name="sendto" >
                      <option >Select Any one</option>
                      <option value='all' selected>All</option>
                      <option value='all_astrologer' selected>All Astrologer</option>
                      <option value='all_member'>All App User</option>
                      <option value='Specify-user' onClick={dropdown('member')}>Specify-User</option>
                      <option value='Specify-Astrologer' onClick={dropdown('astrologer')}>Specify-Astrologer</option>
                     
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">App Users</label>
                  <div className="col-md-9">
                  <select  className="form-control" onChange={(e)=>handle(e)} value={data.sendto} id="sendto" name="sendto" >
                      <option value="98sdnebtyghgfhtfhgh">Manoj</option>
                   
                     
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Astrologer Users</label>
                  <div className="col-md-9">
                  <select  className="form-control" onChange={(e)=>handle(e)} value={data.sendto} id="sendto" name="sendto" >
                      <option value="98sdnebtyghgfhtfhgh">Manoj</option>
                   
                     
                    </select>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>
    </div>
    </div>
  )
}

export default SendMsg
