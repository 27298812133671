import React, { useState ,useEffect} from "react";
import { Route, useNavigate } from 'react-router-dom'
import { Navigate } from "react-router-dom";
import { getTokens } from '../../services/firebase';

export default function Login()  {
    const navigate = useNavigate();
    const [Users, fetchUsers] = useState({});
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isTokenFound, setTokenFound] = useState({
      status:false,
      token:''
    });
    console.log(isTokenFound)
    useEffect(() => {
      getTokens(setTokenFound);
    }, [isTokenFound.status])
  const handleSubmit = (event) => {
    console.log('email 👉️', email);
    console.log('password 👉️', password);
    // Prevent page reload
    event.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({
      "email": email,
      "password": password,     
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"admin/login", requestOptions)
    .then(response => response.text())
          .then((res) => {
           
          var data =  JSON.parse(res);
      
            if(data.token){
              console.log(data)
                fetchUsers(data.admin)
          
                localStorage.setItem('token', data.token);
                localStorage.setItem('user', JSON.stringify(data.admin))
                window.location.href = '/';
                
                

               
            }else{
              navigate("./login")
                alert('Invalid Details');
            }
          
          })
          .catch(error => console.log('error', error));
  };



  return (
    <>
 
   <div className="page vertical-align text-center" data-animsition-in="fade-in" data-animsition-out="fade-out" style={{height:"100vh"}} >
      <div className="page-content vertical-align-middle animation-slide-top animation-duration-1">
        <div className="panel">
          <div className="panel-body">
            <div className="brand">
              <img className="brand-img" src="/logo.png" alt="..." />
            
              <h2 className="brand-text font-size-18"></h2>
            </div>
            <form method="post" onSubmit={handleSubmit} style={{marginTop :"30%"}}>
              <div className="form-group form-material floating" data-plugin="formMaterial">
                <input type="email" className="form-control" name="email" onChange={event => setEmail(event.target.value)}  />
                <label className="floating-label">Email</label>
              </div>
              <div className="form-group form-material floating" data-plugin="formMaterial">
                <input type="password" className="form-control" name="password"  onChange={event => setPassword(event.target.value)} />
                <label className="floating-label">Password</label>
              </div>
              {/* <div className="form-group clearfix">
                <div className="checkbox-custom checkbox-inline checkbox-primary checkbox-lg float-left">
                  <input type="checkbox" id="inputCheckbox" name="remember" />
                  <label for="inputCheckbox">Check for loged in</label>
                </div>
                <a className="float-right" href="forgot-password.html">Forgot password?</a>
              </div> */}
              <button type="submit" className="btn btn-primary btn-block btn-lg mt-40">Sign in</button>
            </form>
            {/* <p>Still no account? Please go to <a href="register-v3.html">Sign up</a></p> */}
          </div>
        </div>

       
      </div>
    </div>
 
    </>
  )
}

// export default login
