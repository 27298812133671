import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
import Select from "react-select";
import { CKEditor } from "ckeditor4-react";
import validator from 'validator'
import { isValidDateValue } from "@testing-library/user-event/dist/utils";
// import Axios from 'axios'

const Add = (props) => {
 
  const [loading, setLoading] = useState(false);
  const [imagepath, setPathImage] = useState(null);
  const [thumbnailpath, setthumbpathimage] = useState([]);
  const [image, setSelectedImage] = useState(null);
  const [intro_video, setSelectedVideo] = useState(null);
  const [thumbnail, setSelectedthumbnail] = useState([]);
  const location = useLocation();
  const dataid = location.state;

  const navigate = useNavigate();
  let [arr, setArrList] = useState([]);
  let [languages, setLangList] = useState([]);
  let [skills, setSkillsList] = useState([]);
  let [expertise, setSpecializedList] = useState([]);

  const [setting, fetchSettings] = useState([]);

  const [select, fetchselectdata] = useState({
    category: null,
    lang: null,
    expertise: null,
    gender: null,
    experience: null,
    skills: null,
    specialized: null,
  });
  const experience = [
    { id: "1-2", label: "1-2 yrs", type: "experience" },
    { id: "2-3", label: "2-3 yrs", type: "experience" },
    { id: "3-4", label: "3-4 yrs", type: "experience" },
    { id: "5-10", label: "5-10 yrs", type: "experience" },
    { id: "10+", label: "10+ yrs", type: "experience" },
  ];

  const [change, changedata] = useState({
    name: "",
  });

  const [data, fetchdata] = useState({
    name: "",
    UserID: "",

    expertise: "",
    dateofbirth: "",
    experience: "",
    chatPrice: "",
    callPrice: "",
    email: "",
    phone: "",
    fbLink: "",
    twitterLink: "",
    instagramLink: "",
    linkedinLink: "",
    fullAddress: "",
    category: "",
    astrologerType: "",
    gender: "",
    title: "",
    profileImage: "",
    thumbnailImage: "",
    intro_video: "",
    description: "",
    available_for_chat: "",
    available_for_call: "",
    commision: "",
    status: "",
    payDate1: "",
    payDate2: "",
    minPayout: "",
  });

  const getastroData = () => {
    if (dataid) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL +
          "admin/" +
          dataid.id +
          "/getAstologerDetails",
        requestOptions
      )
        .then((response) => response.text())
        .then((res) => {
          setLoading(false);
          if (JSON.parse(res).errors) {
            console.log(JSON.parse(res).errors);
            toast.error(JSON.parse(res)._message, { autoClose: 8000 });
          } else {
            var resp = JSON.parse(res);
            resp.phone = resp.phone.replace(/[^\d]/g, "");
            fetchdata(resp);

            const newdata = { ...select };
            if (resp.category !== null) {
              var catarr = Array();
              resp.category?.map((item, key) => {
                catarr.push({
                  value: item.name,
                  display: "1",
                  display_order: "1",
                  _id: item.astolCat_id,
                  type: "category",
                });
                // newdata['category'] = arr.find(x => x.id == item);
              });
              newdata["category"] = catarr;
            }
            if (resp.thumbnail !== null) {
              var thumbarr = Array();
              // resp.thumbnail?.map((item, key) => {
              //   thumbarr(item);
              //   // newdata['category'] = arr.find(x => x.id == item);
              //  })
              //  setthumbpathimage(thumbarr)
              //  setthumbpathimage([resp.thumbnail])
            }

            if (resp.languages !== null) {
              var langarr = Array();
              resp.languages.map((item, key) => {
                langarr.push({
                  _id: item.astolLang_id,
                  label: item.name,
                  type: "lang",
                });
              });
              newdata["lang"] = langarr;
            }
            if (resp.specilization !== null) {
              var specilizationArr = Array();
              resp.specilization.map((item, key) => {
                specilizationArr.push({
                  id: item.astolSpec_id,
                  label: item.name,
                  type: "specialized",
                });
              });
              newdata["specialized"] = specilizationArr;
            }
            if (resp.skills !== null) {
              var skillarr = Array();
              resp.skills.map((item, key) => {
                skillarr.push({
                  id: item.astolSkill_id,
                  label: item.name,
                  type: "skills",
                });
              });
              newdata["skills"] = skillarr;
            }
            if (resp.gender != null) {
              newdata["gender"] = {
                id: resp.gender,
                label: resp.gender,
                type: "gender",
              };
            }
            if (resp.experience != null) {
              newdata["experience"] = {
                id: resp.experience,
                label: resp.experience + " yrs",
                type: "experience",
              };
            }
            fetchselectdata(newdata);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error("Somthing Went Wrong", { autoClose: 8000 });
        });
    }
    // getCategoryData()
  };

  const getCategoryData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "master/astrologer/Category",
      requestOptions,
      5000
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          let tempArray = Array();
          console.log(JSON.parse(res));

          var cat = JSON.parse(res).map((item) => {
            return { ...item, type: "category" };
            // setArrList.push({...item, type: 'category'});
            tempArray.push({ ...item, type: "category" });
          });
          setArrList(tempArray);

          // tempArray.push(cat);
          setArrList(cat);
          //  console.log(data)
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };
  const getLangData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "master/astrologer/Language",
      requestOptions,
      5000
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          let templangArray = Array();
          JSON.parse(res).map((item) => {
            templangArray.push({
              _id: item._id,
              label: item.value,
              type: "lang",
            });
          });
          setLangList(templangArray);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };
  const getSkillData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "master/astrologer/Skill",
      requestOptions,
      5000
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          let templangArray = Array();
          JSON.parse(res).map((item) => {
            templangArray.push({
              id: item._id,
              label: item.value,
              type: "skills",
            });
          });
          setSkillsList(templangArray);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };

  const getSpecializedData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "master/astrologer/Specilization",
      requestOptions,
      5000
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          let templangArray = Array();
          JSON.parse(res).map((item) => {
            templangArray.push({
              id: item._id,
              label: item.value,
              type: "specialized",
            });
          });
          setSpecializedList(templangArray);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };

  function submit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!select["category"]) {
      toast.error("Select Category", { autoClose: 15000 });

      return false;
    }
    if (!select["lang"]) {
      toast.error("Select Language", { autoClose: 15000 });
      return false;
    }
    if (!select["specialized"]) {
      toast.error("Select Expertise", { autoClose: 15000 });
      return false;
    }
    if (!select["experience"]) {
      toast.error("Select Experience", { autoClose: 15000 });
      return false;
    }
    if (!select["gender"]) {
      toast.error("Select Gender", { autoClose: 15000 });
      return false;
    }

    setLoading(true);
    e.preventDefault();
    var axios = require("axios");
    var FormData = require("form-data");
    // var fs = require('fs');
    var form = new FormData();
    // myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

    console.log(data);
    if (dataid) {
      form.append("name", data.name);
      form.append("phone", "+" + data.phone);
    } else {
      form.append("name", data.name);
      form.append("phone", "+" + data.phone);
    }

    form.append("dateofbirth", data.dateofbirth);
    form.append("experience", select["experience"].id);
    form.append("chatPrice", data.chatPrice ? data.chatPrice : 0);
    form.append("callPrice", data.callPrice ? data.callPrice : 0);
    form.append("email", data.email);

    form.append("fbLink", "www.fb.com");
    form.append("twitterLink", "www.tweet.com");
    form.append("instagramLink", "www.insta.com");
    form.append("linkedinLink", "www.in.com");
    form.append("fullAddress", data.fullAddress);
    form.append("gender", select["gender"].id);
    form.append("title", data.title);
    form.append("description", data.description);
    form.append("payDate1", data.payDate1);
    form.append("payDate2", data.payDate2);
    form.append("minPayout", data.minPayout);
    form.append("available_for_chat", data.available_for_chat ? 1 : 0);
    form.append("available_for_call", data.available_for_call ? 1 : 0);
    form.append("commision", data.commision);

    select["lang"].map(function (e, i) {
      form.append("languages[" + i + "]", e._id);
    });
    select["category"].map(function (e, i) {
      form.append("category[" + i + "]", e._id);
    });
    select.specialized.map(function (e, i) {
      form.append("specialization[" + i + "]", e.id);
    });
    select.skills.map(function (e, i) {
      form.append("skills[" + i + "]", e.id);
    });
    if (image) {
      form.append("image", image);
    }
    if (intro_video) {
      form.append("introVideo", intro_video);
    }

    if (thumbnail) {
      Object.keys(thumbnail).forEach(function (key, index) {
        form.append("thumbnail", thumbnail[key]);
      });
    }

    console.log(form);
    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: 'follow'
    // };

    var url = process.env.REACT_APP_BASE_URL + "admin/addAstrologer";
    var formMethode = "POST";
    if (dataid) {
      url =
        process.env.REACT_APP_BASE_URL +
        "admin/" +
        dataid.id +
        "/updateAstrologer";
      formMethode = "PATCH";
    } else {
      url = process.env.REACT_APP_BASE_URL + "admin/addAstrologer";
      formMethode = "POST";
    }

    var config = {
      method: formMethode,
      url: url,
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4",
      },
      data: form,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);
        if (dataid) {
          toast.success("Astrologer Update Success", { autoClose: 15000 });
        } else {
          toast.success("Astrologer Add Success", { autoClose: 15000 });
        }

        console.log(JSON.stringify(response.data));
        navigate("/Astrologers/Manage");
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response) {
          if (error.response.status == 500) {
            toast.error(error.response.data._message, { autoClose: 8000 });
          } else {
            // Request made and server responded
            toast.error(error.response.data, { autoClose: 8000 });
          }
        } else if (error.request) {
          // The request was made but no response was received
          toast.error("The request was made but no response was received", {
            autoClose: 8000,
          });
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          toast.error(error.message, { autoClose: 8000 });
          console.log("Error", error.message);
        }
        console.log(error);
      });
  }

  const getSettings = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL + "master/all-setting",
      requestOptions,
      5000
    )
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          fetchSettings(JSON.parse(res));
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  };

  function onSelect(selectedList, selectedItem) {
    if (data.category) {
      data.category = data.category + "," + selectedItem.id;
    } else {
      data.category = selectedItem.id;
    }
    console.log(data.category);
  }

  function onRemove(selectedList, removedItem) {
    data.category = removeValue(data.category, removedItem.id, ",");
    console.log(data.category);
  }
  function removeValue(list, value, separator) {
    separator = separator || ",";
    var values = list.split(separator);
    for (var i = 0; i < values.length; i++) {
      if (values[i] == value) {
        values.splice(i, 1);
        return values.join(separator);
      }
    }
    return list;
  }

  function  handle(e) {
    
    const newdata = { ...data };
    const changeid = { ...change };

    // if (e.name == "description") {
    //   newdata["description"] = e.getData();
    // } else {
      if (e.target.type === "checkbox") {
        console.log(e.target.value);
        if (e.target.value === "true") {
          newdata[e.target.id] = false;
        } else {
          newdata[e.target.id] = true;
        }
      } else if (e.target.type == "file") {
        if (e.target.id == "image") {
          var file = e.target.files[0];
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            setPathImage(reader.result);
          };
          reader.onerror = function (error) {
            console.log("Error: ", error);
          };

          setSelectedImage(e.target.files[0]);
        } else if (e.target.id == "intro_video") {
          setSelectedVideo(e.target.files[0]);
        } else {
          setSelectedthumbnail(e.target.files);
        }
      } else {
        newdata[e.target.id] = e.target.value;
        console.log(newdata);
      }

      changedata((changeid[e.target.id] = 1));
    // }

    fetchdata(newdata);
  }

  function deleteImg(e) {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTkyNzg1fQ.smgpRZDBNYx-dK02hTBAnPp047mlomAhwV-p75ZIIUY"
    );
    console.log(e);
    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BASE_URL +
        "admin/astrologer-delete-thumbnail/" +
        e._id +
        "/" +
        dataid.id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Thumbnail Delete Success", { autoClose: 15000 });
        getastroData();
      })
      .catch((error) => console.log("error", error));

    let taskList = [...thumbnailpath];
    console.log(taskList);
    taskList.splice(e.target.index, 1);
    setthumbpathimage(taskList);

    let thumbnaildata = [...thumbnail];
    thumbnaildata.splice(e.target.index, 1);
    setSelectedthumbnail(thumbnaildata);
  }
  const HandelChange = (obj) => {
    const newdata = { ...select };

    if (obj.type == "gender") {
      newdata["gender"] = obj;
    } else if (obj.type == "experience") {
      newdata["experience"] = obj;
    } else if (obj[0].type == "category") {
      newdata["category"] = obj;
    } else if (obj[0].type == "expertise") {
      newdata["expertise"] = obj;
    } else if (obj[0].type == "skills") {
      newdata["skills"] = obj;
    } else if (obj[0].type == "specialized") {
      newdata["specialized"] = obj;
    } else if (obj[0].type == "lang") {
      newdata["lang"] = obj;
    }

    fetchselectdata(newdata);
  };
  const onlychar = (e) => {
    if (!/[a-zA-Z ]/i.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };
  const isValidDate = (e) => {

    return false;
  };
  const onlyint = (e) => {
    if (!/[0-9]/i.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };
  const handleInstanceReady = ({ editor }) => {
    // Will be triggered only once, when editor is ready for interaction.

    editor.setData(data.description);
  };
  useEffect(() => {
    getCategoryData();
    getSkillData();
    getLangData();
    getSpecializedData();
    getastroData();
    getSettings();
  }, []);





  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ToastContainer autoClose={8000} />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title">Astrologer </h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="" className='Cursor'>Astrologer</NavLink>
            </li>
            <li className="breadcrumb-item active">{dataid ? "Edit" : "Add"}</li>
          </ol>
          <div className="page-header-actions">
            {dataid ? (
              <>
                {" "}
                <Link
                  to="/chat/{data.firstName}"
                  state={{
                    id: data._id,
                    type: "astrologer",
                  }}
                  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                  data-toggle="tooltip"
                  data-original-title="Save"
                  title="User Chat"
                >
                  <h5>
                    {" "}
                    <span className="badge badge-secondary">Chat</span>
                  </h5>
                </Link>
                {data.kyc_update != 0 ? (
                  <Link
                    to="/Kyc"
                    state={{
                      id: data._id,
                      type: "astrologer",
                    }}
                    className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                    data-toggle="tooltip"
                    data-original-title="Save"
                    title="User Kyc"
                  >
                    <h5>
                      {" "}
                      <span className="badge badge-secondary">Kyc</span>
                    </h5>
                  </Link>
                ) : (
                  ""
                )}
                <Link
                  to="/call/{data.firstName}"
                  state={{
                    id: data._id,
                    type: "astrologer",
                  }}
                  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                  data-toggle="tooltip"
                  data-original-title="Save"
                  title="User call"
                >
                  <h5>
                    {" "}
                    <span className="badge badge-secondary">Call</span>
                  </h5>
                </Link>
                <Link
                  to="/transaction/{data.firstName}"
                  state={{
                    id: data._id,
                    type: "astrologer",
                  }}
                  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                  data-toggle="tooltip"
                  data-original-title="Save"
                  title="User Transaction"
                >
                  <h5>
                    {" "}
                    <span className="badge badge-secondary">Transaction</span>
                  </h5>
                </Link>
                <Link
                  to="/astrologers/edit"
                  state={{
                    id: data._id,
                  }}
                  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                  data-toggle="tooltip"
                  data-original-title="Save"
                >
                  <h5>
                    {" "}
                    <span className="badge badge-secondary">Edit</span>
                  </h5>
                </Link>
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="page-content">
          <div className="panel" style={{ backgroundColor: "#f1f4f5" }}>
            <form
              onSubmit={(e) => submit(e)}
              className="validate-me"
              id="validate-me"
              data-validate
            >
              <div className="panel-body container-fluid">
                <div className="row row-lg">
                  {/* <h4 className="example-title">Add New Astrologers Form</h4> */}

                  <div className="col-md-6">
                    {/* <!-- Example Basic Form (Form grid) --> */}
                    <div className="example-wrap">
                      <div className="example">
                        <div className="row">
                          <div className="form-group col-md-12 ">
                            <div className="form-floating">
                              <input
                                type="text"
                                onChange={(e) => handle(e)}
                                onKeyPress={(e) => onlychar(e)}
                                value={data.name}
                                pattern="[a-zA-Z][a-zA-Z ]{2,}"
                                className="form-control error"
                                id="name"
                                name="name"
                                placeholder="Astrologers Name"
                                autoComplete="off"
                                required
                              />
                              <label
                                htmlFor="name"
                                style={{ color: "rgb(96 164 188)" }}
                              >
                                Astrologer Name{" "}
                                <span style={{ color: "red" }}>**</span>
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="text"
                                onChange={(e) => handle(e)}
                                value={data.phone}
                                className="form-control"
                                id="phone"
                                name="phone"
                                placeholder="Mobile Number"
                                onKeyPress={(e) => onlyint(e)}
                                pattern="[91]{2}[7-9]{1}[0-9]{9}"
                                // {...(dataid ? {disabled: 'success'} : {})}
                                title="Phone number with 91 with 4 number is in 7-9 and remaing 9 digit with 0-9"
                                autoComplete="off"
                                required
                              />
                              <label
                                htmlFor="phone"
                                style={{ color: "rgb(96 164 188)" }}
                              >
                                Astrologer Mobile Number{" "}
                                <span style={{ color: "red" }}>**</span>
                              </label>
                            </div>
                          </div>

                          <div className="form-group col-md-12">
                            <label for="gender">Gender</label>
                            <Select
                              options={[
                                { id: "Male", label: "Male", type: "gender" },
                                {
                                  id: "Female",
                                  label: "Female",
                                  type: "gender",
                                },
                              ]}
                              name="gender"
                              className="gender"
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              value={select.gender} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              required="true"
                            />
                          </div>

                          <div className="form-group col-md-12">
                            <label
                              htmlFor="expertise"
                              style={{ color: "rgb(96 164 188)" }}
                            >
                              Astrologer Expertise{" "}
                              <span style={{ color: "red" }}>**</span>
                            </label>

                            <Select
                              options={expertise}
                              placeholder="select expertise"
                              isMulti="true"
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              value={select.specialized}
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <label htmlFor="skill">Astrologer Skills</label>
                            <Select
                              options={skills}
                              name="skills"
                              id="skills"
                              placeholder="Select Skills"
                              isMulti="true"
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              value={select.skills} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            {/* new code of file upload btn */}
                            <div className="">
                              <label for="file" style={{ zIndex: "0" }}>
                                Choose Thumbnail Image
                              </label>
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                className="form-control "
                                onChange={(e) => handle(e)}
                                name="thumbnail"
                                id="thumbnail"
                                aria-label="Select thumbnail Image"
                                placeholder="Choose thumbnail Image"
                                multiple
                              />
                            </div>

                            <div style={{ display: "flex" }}>
                              {data.thumbnail?.map((item, key) => {
                                return (
                                  <div className="title">
                                    <img
                                      src={item.img}
                                      width="40"
                                      height="40"
                                    />

                                    <div className="image-removal-link">
                                      <button
                                        type="button"
                                        className="btn btn-warning"
                                        index={key}
                                        onClick={(e) => deleteImg(item)}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {/* <div className="form-control-wrap">
                              <div className="custom-file">
                                <label for="file" style={{ zIndex: "0" }}>
                                  Choose Thumbnail Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  className="form-control "
                                  onChange={(e) => handle(e)}
                                  name="thumbnail"
                                  id="thumbnail"
                                  aria-label="Select thumbnail Image"
                                  placeholder="Choose thumbnail Image"
                                />

                                <div style={{ display: "flex" }}>
                                  {thumbnailpath?.map((file, key) => {
                                    return (
                                      <div className="title">
                                        <img
                                          src={file ? file : data.thumbnail}
                                          width="40"
                                          height="40"
                                        />

                                        <div className="image-removal-link">
                                          <button
                                            type="button"
                                            className="btn btn-warning"
                                            index={key}
                                            onClick={(e) => deleteImg(e)}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {data.thumbnail?.map((item, key) => {
                                    return (
                                      <div className="title">
                                        <img
                                          src={item.img}
                                          width="40"
                                          height="40"
                                        />

                                        <div className="image-removal-link">
                                          <button
                                            type="button"
                                            className="btn btn-warning"
                                            index={key}
                                            onClick={(e) => deleteImg(e)}
                                          >
                                            Delete
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="number"
                                min="1"
                                max="31"
                                onChange={(e) => handle(e)}
                                value={data.payDate1}
                                className="form-control"
                                id="payDate1"
                                name="payDate1"
                                placeholder="First Payout Date"
                                // autoComplete="off"
                              />
                              <label htmlFor="payDate1">
                                First Payout Date
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="number"
                                min="100"
                                onChange={(e) => handle(e)}
                                value={data.minPayout}
                                className="form-control"
                                id="minPayout"
                                name="minPayout"
                                placeholder="Minimum payout"
                                // autoComplete="off"
                              />
                              <label htmlFor="minPayout">Minimum payout</label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="custom-control custom-control-md custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={(e) => handle(e)}
                                value={data.available_for_chat}
                                id="available_for_chat"
                                defaultChecked={data.available_for_chat}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="available_for_chat"
                              >
                                Available For Chat
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="Number"
                                onChange={(e) => handle(e)}
                                value={data.chatPrice}
                                className="form-control"
                                min="0"
                                max="500"
                                id="chatPrice"
                                name="chatPrice"
                                placeholder="chatPrice"
                                autoComplete="off"
                                required
                              />
                              <label htmlFor="chatPrice">Chat Price</label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="form-group col-md-12">
                          <div className="form-control-wrap">
                            <div className="custom-file">
                              <label htmlFor="image">
                                Choose Introduction Video
                              </label>
                              <input
                                type="file"
                                accept="video/mp4"
                                className="form-control  "
                                onChange={(e) => handle(e)}
                                // value={data.intro_video}
                                name="Video"
                                id="intro_video"
                                aria-label="Select Introduction Video"
                                placeholder="Choose Introduction Video"
                              />

                              {/* <img src="https://cdn-icons.flaticon.com/png/512/2696/premium/2696537.png?token=exp=1658759465~hmac=c2b68bf4349504d3329e5159b1a906bb"  width="40" height="40" style={{ margin: "4px"}}/> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- End Example Basic Form (Form grid) --> */}
                  </div>

                  <div className="col-md-6">
                    {/* <!-- Example Basic Form (Form grid) --> */}
                    <div className="example-wrap">
                      {/* <!-- <h4 className="example-title">Add New Astrologers Form</h4> --> */}
                      <div className="example">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="email"
                                onChange={(e) => handle(e)}
                                value={data.email}
                                className="form-control"
                                id="email"
                                name="email"
                                placeholder="email"
                                autoComplete="off"
                                required
                              />
                              <label
                                htmlFor="email"
                                style={{ color: "rgb(96 164 188)" }}
                              >
                                Email Address{" "}
                                <span style={{ color: "red" }}>**</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12">
                            <div className="form-floating" >
                            <input type="date"
                             placeholder="DD-MM-YYYY"
                             onChange={(e) => handle(e)}
                             value={data.dateofbirth}
                             className="form-control"
                             id="dateofbirth"
                             name="dateofbirth"/>
                             
                              {/* <div className="error-message" id="bouncer-error_dateofbirth disabled">invalid date</div> */}
                              <label htmlFor="dateofbirth">Date of Birth</label>
                            </div>
                          </div>

                          <div className="form-group col-md-12">
                            <label htmlFor="experience">Experience</label>
                            <Select
                              options={experience}
                              name="experience"
                              id="experience"
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.id}
                              value={select.experience} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <label htmlFor="lang">Language</label>
                            <Select
                              options={languages}
                              isMulti="true"
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option._id}
                              value={select.lang} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            <label htmlFor="category">
                              Astrologer category
                            </label>
                            <Select
                              options={arr}
                              name="category"
                              id="category"
                              isMulti="true"
                              getOptionLabel={(option) => option.value}
                              getOptionValue={(option) => option._id}
                              value={select.category} // this doesn't let me click options
                              onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-12">
                            {/* new code of file upload btn */}
                            <div className="">
                              <label htmlFor="image">
                                Choose Profile Image
                              </label>
                              <input
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                className="form-control "
                                onChange={(e) => handle(e)}
                                name="profileImage"
                                id="image"
                                aria-label="Select Profile Image"
                                placeholder="Choose Profile Image"
                              />
                              <img
                                src={data.profileImage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    "https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
                                }}
                                width="40"
                                height="40"
                              />
                            </div>
                            {/* <div className="form-control-wrap">
                              <div className="custom-file">
                                <label htmlFor="image">
                                  Choose Profile Image
                                </label>
                                <input
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  className="form-control "
                                  onChange={(e) => handle(e)}
                                  name="profileImage"
                                  id="image"
                                  aria-label="Select Profile Image"
                                  placeholder="Choose Profile Image"
                                />

                                <img
                                  src={data.profileImage}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                      "https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
                                  }}
                                  width="40"
                                  height="40"
                                />
                              </div>
                            </div> */}
                          </div>

                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="number"
                                min="1"
                                max="31"
                                onChange={(e) => handle(e)}
                                value={data.payDate2}
                                className="form-control"
                                id="payDate2"
                                name="payDate2"
                                placeholder="Second payout date"
                                // autoComplete="off"
                              />
                              <label htmlFor="payDate2">
                                Second payout date
                              </label>
                            </div>
                          </div>

                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="number"
                                onChange={(e) => handle(e)}
                                value={data.commision}
                                className="form-control"
                                id="commision"
                                name="commision"
                                min="1"
                                max="100"
                                placeholder="Admin Commission"
                                autoComplete="off"
                              />
                              <label htmlFor="commision">
                                Admin Commission
                              </label>
                            </div>
                          </div>

                          <div className="form-group col-md-12">
                            <div className="custom-control custom-control-md custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={(e) => handle(e)}
                                value={data.available_for_call}
                                id="available_for_call"
                                defaultChecked={data.available_for_call}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="available_for_call"
                              >
                                Available For Call
                              </label>
                            </div>
                          </div>
                          <div className="form-group col-md-12">
                            <div className="form-floating">
                              <input
                                type="Number"
                                onChange={(e) => handle(e)}
                                value={data.callPrice}
                                className="form-control"
                                id="callPrice"
                                name="callPrice"
                                min="0"
                                max="500"
                                placeholder="callPrice"
                                autoComplete="off"
                                required
                              />
                              <label htmlFor="callPrice">Call Price</label>
                            </div>
                          </div>
                        </div>

                        {/* <!-- End Example Basic Form (Form grid) --> */}
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="form-floating">
                      <input
                        type="Address"
                        onChange={(e) => handle(e)}
                        value={data.fullAddress}
                        className="form-control"
                        id="fullAddress"
                        name="fullAddress"
                        placeholder="Address"
                        autoComplete="off"
                      />
                      <label htmlFor="fullAddress">Address</label>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <div className="form-floating">
                      <input
                        type="Title"
                        onChange={(e) => handle(e)}
                        value={data.title}
                        className="form-control"
                        id="title"
                        name="title"
                        placeholder="Title"
                        autoComplete="off"
                      />
                      <label foror="title">Title</label>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="floatingTextarea2">
                      Enter Your Description
                    </label>
                    {/* <CKEditor
                      name="content"
                      id="content"
                      initData={data.content}
                      onBlur={({ editor }) => handle(editor)}
                      onInstanceReady={handleInstanceReady}
                    /> */}
                     <textarea  name="description"
            onChange={(e) => handle(e)}
                       id="description"
                       value={data.description}
                        style={{height:"200px", width:"100%"}}/>
                  </div>
                  <div className="form-group col-md-12">
                    <button type="submit" className="btn btn-block btn-success">
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
