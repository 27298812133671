import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Link } from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
// import CustomButton from './CustomButton';
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { NavLink } from "react-router-dom";

const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([]);

  const [value, setvalue] = useState({
    last_payout_date: "",
    payout_amount: "",
    current_balance: "",
    status: "",
    message: "",
    other: "",
  });



  const columns = [
    {
      name: "Astrologer Name",
      selector: (row) => row.astro_id?.name,
      sortable: true,
    },
    {
      name: "Last PayOut Date",
      selector: (row) => row.last_payout_date,
      sortable: true,
    },
    {
      name: "PayOut Amount",
      selector: (row) => row.payout_amount,
      sortable: true,
    },
    {
      name: "Current Balance",
      selector: (row) => row.current_balance,
      sortable: true,
    },
    {
      name: "Requested date",
      selector: (row) => row.request_date,
      sortable: true,
    },
    {
      name: "Approve date",
      selector: (row) => row.payout_date,
      sortable: true,
    },
   
    // {
    //   name: "Status",
    //   selector: row=> row.status,
    //   sortable: true,

    // },
    {
      name: "Message",
      selector: (row) => <span data-toggle="tooltip" data-placement="top" title={row.message}>{row.message}</span>,
      sortable: true,
    },
    {
      name: "Current-Status",
      selector: (row) => row.status,
      sortable: true,
    },

   
      {
        name: "Action",
      sortable: true,
      cell: (d) => [
        {...d.status === 'Requested'? 
       
      
      
        <>
          {console.log(d.status)}
          
          <select
            onChange={(e) => processdata(e, d)}
            className="form-control"
            id="status"
            name="status"
          >
            {/* <option value={d.status}>{d.status}</option> */}
            {/* <option value='approve' {...d.status == "approve"? 'select':''}>approve</option> */}
           {console.log(...d.status)}
           <option >select</option>
            <option value="approve" {...d.status == "Approve"? 'selected':''}>Approve</option>
            <option value="reject" {...d.status == "Reject"? 'selected':''}>Reject</option>
            {/* <option value="reject" {...d.status == "Requested"? 'selected':''}>Requested</option> */}
            <option value="pending" {...d.status == "Pending"? 'selected':''}>Pending</option>
            <option value="hold" {...d.status == "Hold"? 'selected':''}>Hold</option>
            <option value="add_violation" {...d.status == "Violation"? 'selected':''}>Violation</option>
          </select>
        </>
        : {...d.status === 'Pending'? 
        <>
        {console.log(d.status)}
        
        <select
          onChange={(e) => processdata(e, d)}
          className="form-control"
          id="status"
          name="status"
        >
          {/* <option value={d.status}>{d.status}</option> */}
          {/* <option value='approve' {...d.status == "approve"? 'select':''}>approve</option> */}
         {console.log(...d.status)}
         <option >select</option>
          <option value="approve" {...d.status == "Approve"? 'selected':''}>Approve</option>
          <option value="reject" {...d.status == "Reject"? 'selected':''}>Reject</option>
          <option value="reject" {...d.status == "Requested"? 'selected':''}>Requested</option>
          {/* <option value="pending" {...d.status == "Pending"? 'selected':''}>Pending</option> */}
          <option value="hold" {...d.status == "Hold"? 'selected':''}>Hold</option>
          <option value="add_violation" {...d.status == "Violation"? 'selected':''}>Violation</option>
        </select>
      </>
      :{...d.status === 'Hold'? 
      <>
      {console.log(d.status)}
      
      <select
        onChange={(e) => processdata(e, d)}
        className="form-control"
        id="status"
        name="status"
      >
        {/* <option value={d.status}>{d.status}</option> */}
        {/* <option value='approve' {...d.status == "approve"? 'select':''}>approve</option> */}
       {console.log(...d.status)}
       <option >select</option>
        <option value="approve" {...d.status == "Approve"? 'selected':''}>Approve</option>
        <option value="reject" {...d.status == "Reject"? 'selected':''}>Reject</option>
        <option value="reject" {...d.status == "Requested"? 'selected':''}>Requested</option>
        <option value="pending" {...d.status == "Pending"? 'selected':''}>Pending</option>
        {/* <option value="hold" {...d.status == "Hold"? 'selected':''}>Hold</option> */}
        <option value="add_violation" {...d.status == "Violation"? 'selected':''}>Violation</option>
      </select>
    </>
    :{...d.status === 'Violation'? 
    <>
    {console.log(d.status)}
    
    <select
      onChange={(e) => processdata(e, d)}
      className="form-control"
      id="status"
      name="status"
    >
      {/* <option value={d.status}>{d.status}</option> */}
      {/* <option value='approve' {...d.status == "approve"? 'select':''}>approve</option> */}
     {console.log(...d.status)}
     <option >select</option>
      <option value="approve" {...d.status == "Approve"? 'selected':''}>Approve</option>
      <option value="reject" {...d.status == "Reject"? 'selected':''}>Reject</option>
      <option value="reject" {...d.status == "Requested"? 'selected':''}>Requested</option>
      <option value="pending" {...d.status == "Pending"? 'selected':''}>Pending</option>
      <option value="hold" {...d.status == "Hold"? 'selected':''}>Hold</option>
      {/* <option value="add_violation" {...d.status == "Violation"? 'selected':''}>Violation</option> */}
    </select>
  </>
  :<></>
      }
      }
        }
      }
      ],
      }
   
    

    // {
    //   name: "Status",

    //   sortable: true,
    //   cell: (d) => [
    //     <>
    //     <h1><button type='button' onClick={payout} className='btn btn-success'>pay</button></h1>
    //   </>
    //   ]
    // },
  ];
  let tableData = {
    columns,
    data,
  };

  // status................
  function processdata(e, d) {
    e.preventDefault();
    e.stopPropagation();
    confirmAlert({
      title: "Confirm to change status",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            //  console.log(d.walletBalance)
            if (
              Number(d.walletBalance ? d.walletBalance : 0) <
              Number(d.minPayout)
            ) {
              alert(
                "Astrologer wallet balence is " +
                  d.walletBalance +
                  " lower then min. payout balance " +
                  d.minPayout
              );
            } else {
              var axios = require("axios");
              var data = JSON.stringify({
                _id: d._id,
                astro_id: d.astro_id?._id,
                last_payout_date: d.last_payout_date,
                payout_amount: d.payout_amount,
                current_balance: d.current_balance,
                status: e.target[e.target.selectedIndex].text,
                // "status": e.status,
                message: d.message,
                other: "other",
              });
              var url = "";
              if (e.target[e.target.selectedIndex].text == "Aproove") {
                url = process.env.REACT_APP_BASE_URL+"admin/Payout/Aproove";
              } else {
                url =
                process.env.REACT_APP_BASE_URL+"admin/Payout/" +
                  e.target[e.target.selectedIndex].text;
              }

              var config = {
                method: "post",
                url: url,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  console.log(response.data);
                  console.log(JSON.prase(response.data));
                  setvalue(JSON.prase(response.data));
                })
                .catch(function (error) {
                  console.log(error);
                });
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  // list.........................

  const getData = (e) => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: process.env.REACT_APP_BASE_URL+"admin/Payout/" + e+'?page=1&limit=1000' ,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        console.log(response.data);
        fetchUsers(response.data.results);
      })
      .catch((error) => console.log("error", error));
  };

  
  function getbytype(e) {
   getData(e);
  }
  
  useEffect(() => {
    getData('all');
  }, []);
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ToastContainer autoClose={8000} />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title"> PayOut</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="" className='Cursor'>PayOut</NavLink>
            </li>
            <li className="breadcrumb-item active">PayOut List</li>
          </ol>
          {/* <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </NavLink>
      </div> */}
        </div>

        <div className="page-content container-fluid"></div>
        <div className="page-content">
          <div className="panel">
            <header className="panel-heading">
              {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
            </header>
            <div className="panel-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-15"></div>
                </div>
              </div>
              <div style={{ marginLeft: "3px" }}>
              <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("all")}
                  style={{ marginLeft: "3px" }}
                >
                  All
                </button>
                <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Approve")}
                  style={{ marginLeft: "3px" }}
                >
                  Approve
                </button>

                <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Requested")}
                  style={{ marginLeft: "3px" }}
                >
                  Requested
                </button>

                <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Pending")}
                  style={{ marginLeft: "3px" }}
                >
                  Pending
                </button>
                <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Hold")}
                  style={{ marginLeft: "3px" }}
                >
                  Hold
                </button>
                <button
                type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Violation")}
                  style={{ marginLeft: "3px" }}
                >
                  Violation
                </button>
                <button type="button"
                  className="btn btn-light"
                  onClick={() => getbytype("Reject")}
                  style={{ marginLeft: "3px" }}
                >
                  Reject
                </button>
                
              
               
              
               
              
              
                {/* <input id="search" type="button" onClick="getbytype();" value="Search Location" /> */}
              </div>
              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  // sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  dense
  
                />
              </DataTableExtensions>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Manage;
