import DataTable from 'react-data-table-component';
import { useEffect,useState } from 'react';
import DataTableExtensions from "react-data-table-component-extensions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { options } from '../../App';

  const OnlineComp = () => {

  const [data, fetchUsers] = useState([])
  const location = useLocation();
  const dataid = location.state;
 
const columns = [
  {
    name: 'Online',
    selector: row => <span title={new Date(row.liveAt).toLocaleDateString("en-US", options)}>{new Date(row.liveAt).toLocaleDateString("en-US", options)}</span>,
  },
  {
    name: 'Offline',
    selector: row => <span title={new Date(row.offlineAt).toLocaleDateString("en-US", options)}>{new Date(row.offlineAt).toLocaleDateString("en-US", options)}</span>,
  },
  
];


let tableData = {
  columns,
  data
}


  function getData() {
    var axios = require('axios');

var config = {
  method: 'get',
  url: 'https://api.jyotishee.com:8000/api/admin/astro_live_log/638c71555e165a9aa7e8b07e',
  headers: { 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
  }
};

axios(config)
.then(function (response) {
  console.log(response.data.data)
  fetchUsers(response.data.data)
})
.catch(function (error) {
  console.log(error);
});
  }

  useEffect(() => {
    getData()
  }, [])
    return (
        <div class="page">
        <div class="page-header">
          <h1 class="page-title"> Astrologer</h1>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
            <li class="breadcrumb-item"><NavLink to="" className='Cursor'>Astrologer</NavLink></li>
            <li class="breadcrumb-item active">Online</li>
          </ol>
         
        </div>
    
        <div class="page-content container-fluid">
         
        </div>
        <div class="page-content" >
          <div class="panel">
              <header class="panel-heading">
                {/* <!-- <h3 class="panel-title">Add Row</h3> --> */}
              </header>
              <div class="panel-body">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-15">
                     
                    </div>
                  </div>
                </div>
                <DataTableExtensions {...tableData}>
                <DataTable
            columns={columns}
            data={data}
            // selectableRows
        />
          </DataTableExtensions>
              </div>
            </div>
        </div>
    
        
    
      </div>
    );
};
export default OnlineComp