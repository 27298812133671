import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Pie } from 'react-chartjs-2';
import { NavLink } from "react-router-dom";
import { TbCurrencyRupee, TbWallet, TbZoomMoney } from "react-icons/tb";
import { GrTransaction } from "react-icons/gr";
import { FaMoneyBillAlt } from "react-icons/fa";
import { FcMoneyTransfer, FcCallback } from "react-icons/fc";
import { MdOutlineAttachMoney } from "react-icons/md";
import { GiChatBubble } from "react-icons/gi";
import {RiMoneyDollarCircleFill} from 'react-icons/ri';

import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Chart } from "react-chartjs-2";

const Dashboard = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  let [todaylist, settodaylist] = useState([]);
  let [graph, setGraph] = useState([0,0,0,0,0,0,0]);
const [follower, setFollowers] = useState([])

  ChartJS.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: 'Chart.js Bar Chart',
      },
    },
  };

  // const labels = label;

  const labels = graph[0];

  const data = {
    labels,
    datasets: [
      {
        label: "Call",
        data: graph[1],
        backgroundColor: "#926dde",
      },
      {
        label: "Chat",
        data: graph[2],
        backgroundColor: "#57c7d4",
      },
      {
        label: "Ecommerce",
        data: graph[3],
        backgroundColor: "#11c26d",
      },
    ],
  };
  const data1 = {
    labels: ['Call', 'Chat', 'Ecommerce'],
    datasets: [
      {
        label: '# of Votes',
        data: [graph[5], graph[6], graph[7]],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  // doughnut Chart.............

  const getData = (s,e) => {
    console.log(s,e)
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://api.jyotishee.com:8000/api/admin/earning/all?startingfrom="+s+"&endto="+e,
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzODhlY2Y1OWMwZTVmNjJiM2ZkMWI4ZiIsImlhdCI6MTY2OTkxNzk0MX0.hWr6QfcSlsTWPOoEY4nLbFDmeGKLACewjacRMxuyQtE",
      },
    };

    axios(config)
      .then(function (response) {
        settodaylist(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const totalearning = () => {
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://api.jyotishee.com:8000/api/getgrowth",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        var monthdata = response.data.data;
        var label = [];
        var call_earning = [];
        var chat_earning = [];
        var total_earning_by_ecommerce = [];
        var total_earning = 0;
        var total_call_earning = 0;
        var total_chat_earning = 0;
        var total_ecom_earning = 0;
        Object.keys(monthdata).forEach(function (key, index) {
          label.push(key);
          call_earning.push(monthdata[key].total_earning_by_call);
          chat_earning.push(monthdata[key].total_earning_by_chat);
          total_earning_by_ecommerce.push(
            monthdata[key].total_earning_by_ecommerce
          );
          total_call_earning += monthdata[key].total_earning_by_call;
          total_chat_earning += monthdata[key].total_earning_by_chat;
          total_ecom_earning += monthdata[key].total_earning_by_ecommerce;
          total_earning +=  parseInt(monthdata[key].total_earning_by_call)+parseInt(monthdata[key].total_earning_by_chat)+ parseInt(monthdata[key].total_earning_by_ecommerce)
        });
         total_call_earning = (100 * total_call_earning) / total_earning;
         total_chat_earning = (100 * total_chat_earning) / total_earning;
         total_ecom_earning = (100 * total_ecom_earning) / total_earning;

        setGraph([
          label,
          call_earning,
          chat_earning,
          total_earning_by_ecommerce,
          total_earning,
          total_call_earning,
          total_chat_earning,
          total_ecom_earning
        ]);
  
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // table..........
  const Followers = () => {
    var axios = require('axios');
var FormData = require('form-data');
var data = new FormData();

var config = {
  method: 'get',
  url: 'https://api.jyotishee.com:8000/api/admin/follower',
  headers: { 
    // ...data.getHeaders()
  },
  data : data
};

axios(config)
.then(function (response) {
  // console.log(JSON.stringify(response.data));
  console.log(response.data)
  setFollowers(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

  }

  function getbytype(s,e) {
    getData(s,e);
   }
  function daterange(s,e) {

    if(s>e){
      alert('Starting date less than ending date')
    }else{

      setStartDate(s);
      setEndDate(e);
    }
    // change start date format
    var createdDate = new Date(s);

var day = createdDate.getDate();
var month = createdDate.getMonth() + 1; //months are zero based
var year = createdDate.getFullYear();
 
  // change end date format
var endingDate = new Date(e);

var day2 = endingDate.getDate();
var month2 = endingDate.getMonth() + 1; //months are zero based
var year2 = endingDate.getFullYear();

    getData(''+year+'/'+month+'/'+day+'',''+year2+'/'+month2+'/'+day2+'');
   }

  //  function Fff() {
let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
  //  }

  useEffect(() => {
    getData(`${year}/${month}/${date}`,`${year}/${month}/${date}`);
    totalearning();
    Followers();
  }, []);



  return (
    <>
      {/* <!-- Page --> */}
      <div className="page dashboard_page">
        <div className="page-header"></div>

        <div className="page-content container-fluid">
          <div className="row">
            <div className="col-12" id="ecommerceChartView">
              <div className="card card-shadow">
                <div
                  className="card-header card-header-transparent py-20"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignitems: "center",
                  }}
                >
                 
                   
                
                  <form className="form-inline" style={{display:"contents"}}>
                  <div className="form-group mb-2">
                  <h1 className="page-title font-size-26 font-weight-100">
                      Dashboard Overview
                    </h1>
     </div>
  <div className="form-group mb-2">
    <label for="staticEmail2" className="">Select Date Range</label>
     </div>
  <div className="form-group mx-sm-3 mb-2">
    <label for="inputPassword2" className="sr-only">Password</label>
    <DatePicker
        selected={startDate}
        onChange={(date) => daterange(date,endDate)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        className="form-control"
      />
  </div>
  <div className="form-group mx-sm-3 mb-2">
    <label for="inputPassword2" className="sr-only">Password</label>
    <DatePicker
        selected={endDate}
        onChange={(date) => daterange(startDate,date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        className="form-control"/>
  </div>
  <div className="form-group mx-sm-3 mb-2">
  <ul className="nav nav-pills nav-pills-rounded chart-action">
                    <li className="nav-item">
                      <button   className="active nav-link"
                        data-toggle="tab"
                        onClick={() => getbytype(`${year}/${month}/${date}`,`${year}/${month}/${date}`)}>
                          Today
                          </button>
                    </li>
                    <li className="nav-item">
                      <button  className="nav-link"
                        data-toggle="tab"
                        onClick={() => getbytype(`${year}/${month}/01`,`${year}/${month}/31`)}>
                          Month
                          </button>
                    </li>
                    <li className="nav-item">
                      <button className="nav-link"
                        data-toggle="tab"
                        onClick={() => getbytype(`${year}/01/1`,`${year}/12/31`)}>
                          year
                          </button>
                    </li>
                  </ul>
   
  </div>
  
</form>


                
                </div>
                <div className="widget-content tab-content bg-white p-20">
                  <div className="ct-chart tab-pane active" >
                    <div className="row">
                      {/* <!-- First Row --> */}
                      <div  className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow text-secondary">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-warning"
                            >
                              <i className="icon wb-mobile"></i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Call
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                                {todaylist?.total_call}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow text-secondary">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-danger"
                            >
                              <i className="icon wb-chat"></i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Chat
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                                {todaylist?.total_chat}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/ecom-product'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-success"
                            >
                              <i className="icon wb-shopping-cart"></i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Ecommerce Order
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                                {todaylist?.total_ecommerce_order?todaylist?.total_ecommerce_order:0}
                                {/* {todaylist?.total_earning_by_ecommerce?todaylist?.total_earning_by_ecommerce:0} */}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                    
                        <div className="col-xl-3 col-md-6 info-panel">
                        <div className="card card-shadow">
                          <NavLink to='/recharge/all'>
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-primary"
                            >
                              <i className="icon ">
                                {" "}
                                <TbWallet />{" "}
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Wallat Balance Use
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.total_wallat_balance_use?todaylist?.total_wallat_balance_use:0}
                              </span>
                            </div>
                          </div>
                          </NavLink>
                        </div>
                      </div>

                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-warning"
                            >
                              <i className="icon ">
                                <FaMoneyBillAlt />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Astro Earning On Call
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                               {/* {parseFloat(todaylist?.total_earn_on_chat)+parseFloat(todaylist?.total_earn_on_call)+parseFloat(todaylist?.total_earning_by_ecommerce)} */}
                               ₹  {todaylist.total_earn_on_call?todaylist.total_earn_on_call:0}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-danger"
                            >
                              <i className="icon ">
                                <FcMoneyTransfer />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Astro Earning On Chat
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.total_earn_on_chat?todaylist?.total_earn_on_chat:0}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/ecom-product'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-success"
                            >
                              <i className="icon ">
                                <MdOutlineAttachMoney />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Earning By Ecommerce
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.total_earning_by_ecommerce?todaylist?.total_earning_by_ecommerce:0}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-primary"
                            >
                              <i className="icon">
                                <TbCurrencyRupee
                                  style={{ color: "#fff", fontSize: "20px" }}
                                />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Earning
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                               ₹ {(parseFloat(todaylist?.total_earning_by_ecommerce?todaylist?.total_earning_by_ecommerce:0)+parseFloat(todaylist?.total_earn_on_chat?todaylist?.total_earn_on_chat:0)+parseFloat(todaylist.total_earn_on_call?todaylist.total_earn_on_call:0)).toFixed(1)}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-warning"
                            >
                              <i className="icon ">
                                <FcCallback />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Commision By Call
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                               ₹ {(todaylist?.call_adminCommision?todaylist?.call_adminCommision:0)}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-danger"
                            >
                              <i className="icon ">
                                <GiChatBubble />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Commision By Chat
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.chat_adminCommision?todaylist?.chat_adminCommision:0}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-success"
                            >
                              <i className="icon ">
                                <TbZoomMoney
                                  style={{ color: "#fff", fontSize: "20px" }}
                                />
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Commision
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹ {parseFloat(todaylist?.call_adminCommision?todaylist?.call_adminCommision:0)+parseFloat(todaylist?.chat_adminCommision?todaylist?.chat_adminCommision:0)}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <NavLink to='/Transaction/all'>
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-primary"
                            >
                              <i className="icon">
                                {" "}
                                <GrTransaction
                                  style={{ color: "#fff", fontSize: "20px" }}
                                />{" "}
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Total Pay Transction
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.total_pay_transction?todaylist?.total_pay_transction:0}
                              </span>
                            </div>
                          </div>
                        </div>
                        </NavLink>
                      </div>
                      <div className="col-xl-3 col-md-6 info-panel">
                        <div className="card card-shadow">
                          <div
                            className="card-block  p-20"
                            style={{ backgroundColor: "whitesmoke" }}
                          >
                            <button
                              type="button"
                              className="btn btn-floating btn-sm btn-primary"
                            >
                              <i className="icon">
                                {" "}
                                <RiMoneyDollarCircleFill
                                  style={{ color: "#fff", fontSize: "20px" }}
                                />{" "}
                              </i>
                            </button>
                            <span className="ml-15 font-weight-400">
                              Remaining Wallet Balance
                            </span>
                            <div className="content-text text-center mb-0">
                              <span
                                className=" font-weight-100"
                                style={{ fontSize: "25px" }}
                              >
                              ₹  {todaylist?.remaing_balance ?? 0} 
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>

            <div className="col-lg-8" id="ecommerceRecentOrder">
              <div className="card card-shadow table-row">
                <div className="card-header card-header-transparent py-20">
                  <div className="btn-group dropdown">
                    <NavLink
                      to="#"
                      className="text-body dropdown-toggle blue-grey-700"
                      data-toggle="dropdown"
                    >
                      RECENT ORDER
                    </NavLink>
                    <div className="dropdown-menu animate" role="menu">
                      <NavLink className="dropdown-item" to="#" role="menuitem">
                        Sales
                      </NavLink>
                      <NavLink className="dropdown-item" to="#" role="menuitem">
                        Total sales
                      </NavLink>
                      <NavLink className="dropdown-item" to="#" role="menuitem">
                        profit
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="card-block bg-white table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Total Post</th>
                        <th>Total Followers</th>
                        <th>Total Call</th>
                        <th>Total Chat</th>
                      </tr>
                    </thead>
                    {  console.log(follower['data'])}
                      {follower?.data?.map((item) =>{
                        return(
                    <tbody>
                        <tr>
                        <td>{item.total_post}</td>
                        <td>{item.total_follower}</td>
                        <td>{item.total_call}</td>
                        <td>{item.total_chat}</td>
                      </tr>
                    </tbody>
                         )
                  })}  
                  </table>
                </div>
              </div>
            </div>

            <div className="col-lg-4" id="ecommerceRevenue">
              <div className="card card-shadow text-center pt-10">
                <h3 className="card-header card-header-transparent blue-grey-700 font-size-14 mt-0">
                  REVENUE
                </h3>
                <div className="card-block bg-white">
                  <Bar options={options} data={data} />
                  <NavLink to='/Transaction/all'>
                  <div className="pie-view row">
                    <div className="col-6 pie-left text-center">
                      <h5 className="blue-grey-500 font-size-14 font-weight-100">
                       Total Earning
                      </h5>
                      <p className="font-size-20 blue-grey-700">{graph[4]}</p>
                      <div
                        className="pie-progress pie-progress-sm"
                        data-plugin="pieProgress"
                        data-valuemax="100"
                        data-valuemin="0"
                        data-barcolor="#a57afa"
                        data-size="100"
                        data-barsize="4"
                        data-goal="60"
                        aria-valuenow="60"
                        role="progressbar"
                      >
                        {/* <span className="pie-progress-number">60%</span> */}
                      </div>
                      {console.log(graph)}
                      <Pie data={data1} />
                    </div>
                    
                   
                  </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Page --> */}
    </>
  );
};

export default Dashboard;