import React, { useState, useEffect } from 'react';
import { Link, NavLink} from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  const [setting, fetchSettings] = useState([])
   const columns = [
    { 
      name: "Image",
    
      sortable: false,
      cell: (d) => (
        <img src={d.profileImage}  onError={({ currentTarget }) => {
     currentTarget.onerror = null; // prevents looping
     currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
   }}  width="40" height="40" style={{ margin: "4px"}} />
       )
    },
    {
      name: "Name",
      selector:row=> row.name,
      sortable: true,
     
    },
    {
      name: "PhoneNumber",
      selector: row=> row.phone,
      sortable: true,
    
    },
    {
      name: "Email",
      selector: row=> row.email,
      sortable: true
    },
    {
      name: "ChatPrice",
      selector: row=> row.chatPrice,
      sortable: true
    },
    {
      name: "CallPrice",
      selector: row=> row.callPrice,
      sortable: true
    },

    {
      name: "Status",

      sortable: true,
      cell: (d) => [
        <>
        <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => StatusAstrologer(e,d,d.approved == 1?0:1)}
                          data-toggle="tooltip" data-original-title="Remove">Click For Approve</button>
      </>
      ]
    },

    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
    

<Link to="/astrologers/edit" state= {{
  id: d._id
}}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                  <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                  data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
  
    var formdata = new FormData();
    formdata.append("id", e);
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/"+e+"/deleteAstrologer", requestOptions)


.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  toast.success("Astrologer Delete Success", { autoClose: 8000 })
  getData();
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});

}

function StatusAstrologer(e, userdata,status){
  console.log(userdata.kyc_update)
  e.preventDefault();
  e.stopPropagation();

  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
  
console.log(userdata)
 
  var raw = JSON.stringify({
    "astroId": userdata._id,
    "approved": 1
    });
  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(process.env.REACT_APP_BASE_URL+"admin/approved",requestOptions)

.then(response => response.text())
    .then((res) => {
      getData();
          setLoading(false)
          if(status==1){
            toast.success("Astrologer Active Successfully", { autoClose: 15000 })
          }else{
            toast.success("Astrologer Deactive Successfully", { autoClose: 15000 })
          }
         
        })
        .catch(error =>  toast.error(error, { autoClose: 15000 }));
  
    }

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/unapproved", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);console.log(JSON.parse(res).data)
            fetchUsers(JSON.parse(res).data
            // .sort(() => {
            //   return -1;
            // }
            // )
            )
      })
      .catch(error => console.log('error', error));
  }

  const getSettings = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/all-setting", requestOptions,5000)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  fetchSettings(JSON.parse(res))

};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
     
  }

  useEffect(() => {
    getData()
    getSettings()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
    
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Astrologer</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Astrologer</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
