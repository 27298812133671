import React, { useState, useEffect } from 'react'
import { Multiselect } from 'multiselect-react-dropdown';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";

const Add = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setSelectedImage] = useState(null);
  const [data, fetchdata] = useState({
    title:"",
  slug:"",
  section:"",
  description:""
})
  const location = useLocation();
  const dataid = location.state;
  
  // console.log(dataid);

  const getData = () => {
if (dataid) {
  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    // body: formdata,
    redirect: 'follow'
  };

fetch(process.env.REACT_APP_BASE_URL+"master/banner/"+dataid.id, requestOptions)
.then(response => response.text())
    .then((res) => {
      setLoading(false);
      console.log(JSON.parse(res))
      fetchdata(JSON.parse(res))
    })
    .catch(error => console.log('error', error));  
}
   
  }


 
  



  function submit(e){
    setLoading(true)
    e.preventDefault();
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
  
    var formdata = new FormData();
    if(image){
      formdata.append('image',image);
    }

    formdata.append('name', data.name);
    formdata.append('url', data.url);
    formdata.append('button_text', data.button_text);
    formdata.append('section', data.section);
     
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
    let url = process.env.REACT_APP_BASE_URL+"master/banner";
    if (dataid) {
      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      url = process.env.REACT_APP_BASE_URL+"master/banner-update/"+dataid.id;
    }
    else{
      
      url = process.env.REACT_APP_BASE_URL+"master/banner";
    }
    fetch(url, requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false)
        console.log(JSON.parse(res))
        navigate('/Setting/Banner');
      })
      .catch(error => console.log('error', error));
    }
    
    function handle(e){
      const newdata={...data}
      if(e.target.type == "file"){
      
         setSelectedImage(e.target.files[0]);
    
       }else{
      newdata[e.target.id] = e.target.value
       }
      fetchdata(newdata)
      console.log(newdata)
    }

  useEffect(() => {
    getData();
   
  }, [])

  return loading? 
  <>
  <div className="App">
        
            
              <div className={"item"}>
                  <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
              </div>
       
      
      </div>
  </>
  :(
    <>
     <div className="page">
    <div className="page-header">
      <h1 className="page-title">Banner {dataid?"Edit":"Add"}</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Banner</NavLink></li>
        <li className="breadcrumb-item active">{dataid?"Edit":"Add"}</li>
      </ol>
     
    </div>
    <div className="page-content">
      <div className="panel">
      <form onSubmit={(e)=> submit(e)}>
        <div className="panel-body container-fluid">
          <div className="row row-lg">
              {/* <h4 className="example-title">Add New CMS Form</h4> */}
             
            <div className="col-12">
              {/* <!-- Example Basic Form (Form grid) --> */}
            
              
           
                
                    <div className="row">
                      <div className="form-group col-6">
                      <div className="form-floating">
                      <input onChange={(e)=>handle(e)} id="name" className="form-control" value={data.name} placeholder='Section' type="text"></input>
                      <label for="title">Name</label>
                      </div>
                      </div>
                      <div className="form-group col-6">
                      <div className="form-floating">
                      <input onChange={(e)=>handle(e)} id="url" className="form-control" value={data.url} placeholder='Button Url' type="url"></input>
                      <label for="title">Button Url</label>
                      </div>
                      </div>
                    
                     
                    </div>
                    <div className="row">
                      <div className="form-group col-6">
                      <div className="form-floating">
                      <input onChange={(e)=>handle(e)} id="button_text" className="form-control" value={data.button_text} placeholder='Button Text' type="text"></input>
                      <label for="title">Button Text</label>
                      </div>
                      </div>
                      <div className="form-group col-6">
                      <div className="form-floating">
                      <input onChange={(e)=>handle(e)} id="section" className="form-control" value={data.section} placeholder='Section' type="text"></input>
                      <label for="title">Section</label>
                      </div>
                      </div>
                    
                     
                    </div>

            
           
           
              {/* <!-- End Example Basic Form (Form grid) --> */}
            </div>

            <div className="form-group col-12">
          
            <div className="form-floating">
            <div className="custom-file">
                                <input
                                  type="file" accept="image/png, image/gif, image/jpeg"
                                  className="custom-file-input"
                                  onChange={(e) => handle(e)}
                                  // value={data.profileImage}
                                  id="image"
                                  className='form-control'
                                  placeholder='Choose Main Image'
                                />
                                <img src={data.image}  onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }}  width="40" height="40"/>
                              </div>
              </div>
             
            </div>
           
            <div className="form-group col-12">
            <button type="submit" className="btn btn-block btn-success">submit</button>
            </div>
         
    
        </div>
      
      </div>
      </form>
    </div>
    
  </div>
</div>

         </>
  )
  }

export default Add;

