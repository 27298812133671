import React from 'react'
import { Link, NavLink} from "react-router-dom";

const Sidebar = () => {
  return (
  
    <div className="site-menubar">
    <ul className="site-menu">
      <li className="site-menu-item has-sub">
        <NavLink to="/">
          
                <i className="site-menu-icon wb-dashboard" aria-hidden="true"></i>
                <span className="site-menu-title">Dashboard</span>
                       
            </NavLink>
       
      </li>
      <li className="site-menu-item has-sub">
      <NavLink to="">
                <i className="site-menu-icon wb-layout" aria-hidden="true"></i>
                <span className="site-menu-title">Masters</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
          
          <li className="site-menu-item has-sub">
          <NavLink to="">
              <span className="site-menu-title">Products</span>
              <div className="site-menu-label">
                {/* <span className="badge badge-danger badge-round mr-25">new</span> */}
              </div>
              <span className="site-menu-arrow"></span>
            </NavLink>
            <ul className="site-menu-sub">
              <li className="site-menu-item">
              {/* <Link to="/category">  category</Link> */}
              <NavLink className="animsition-link" to="/master/Products/Type">
                  <span className="site-menu-title">Type</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Products/Material">
                  <span className="site-menu-title">Material</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/products/addon-type">
                  <span className="site-menu-title">Addon Type</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Products/design-product">
                  <span className="site-menu-title">Design</span>
                </NavLink>
              </li>
           
            </ul>
          </li>
          <li className="site-menu-item has-sub">
            <NavLink to="">
              <span className="site-menu-title">Astrologer</span>
              <div className="site-menu-label">
                {/* <span className="badge badge-danger badge-round mr-25">new</span> */}
              </div>
              <span className="site-menu-arrow"></span>
            </NavLink>
            <ul className="site-menu-sub">
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Astrology/specilization">
                  <span className="site-menu-title">Specilization</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Astrology/Category">
                  <span className="site-menu-title">Category</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Astrology/Skills">
                  <span className="site-menu-title">Skill</span>
                </NavLink>
              </li>
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/master/Astrology/Language">
                  <span className="site-menu-title">Language</span>
                </NavLink>
              </li>
            </ul>
          </li>



          <li className="site-menu-item has-sub">
            <NavLink to="">
              <span className="site-menu-title">Post Category</span>
              <div className="site-menu-label">
                {/* <span className="badge badge-danger badge-round mr-25">new</span> */}
              </div>
              <span className="site-menu-arrow"></span>
            </NavLink>
            <ul className="site-menu-sub">
              <li className="site-menu-item">
                <NavLink className="animsition-link" to="/masters/category">
                  <span className="site-menu-title">Category</span>
                </NavLink>
              </li>
            </ul>
          </li>
      
        </ul>
      </li>
      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-file" aria-hidden="true"></i>
                <span className="site-menu-title">Astrologer</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
         
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Astrologers/Add">
              <span className="site-menu-title">Add</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Astrologers/Manage">
              <span className="site-menu-title">Manage</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Astrologers/Astrounverified">
              <span className="site-menu-title">Unverified Astrologer</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/astrologers/unapproved">
              <span className="site-menu-title">Unapproved Astrologer</span>
            </NavLink>
          </li>

          

        </ul>
      </li>

      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-user" aria-hidden="true"></i>
                <span className="site-menu-title">User</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
         
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Users/Add">
              <span className="site-menu-title">Add</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Users/Manage">
              <span className="site-menu-title">Manage</span>
            </NavLink>
          </li>
       

        </ul>
      </li>

      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-file" aria-hidden="true"></i>
                <span className="site-menu-title">Product</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
         
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Product/Add">
              <span className="site-menu-title">Add</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Product/Manage">
              <span className="site-menu-title">Manage</span>
            </NavLink>
          </li>

         


        </ul>
      </li>

      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-bookmark" aria-hidden="true"></i>
                <span className="site-menu-title">Order</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">

        {/* <li className="site-menu-item">
            <a className="animsition-link" href="/Order/list">
              <span className="site-menu-title">List</span>
            </a>
          </li> */}
         
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/call/all">
              <span className="site-menu-title">Call</span>
            </NavLink>
          </li>
         
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/chat/all">
              <span className="site-menu-title">Chat</span>
            </NavLink>
          </li>

          {/* <li className="site-menu-item">
            <a className="animsition-link" href="/Order/Chat">
              <span className="site-menu-title">Chat</span>
            </a>
          </li> */}

          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Transaction/all">
              <span className="site-menu-title">Transaction</span>
            </NavLink>
          </li>
          {/* <li className="site-menu-item">
            <a className="animsition-link" href="/view-transaction">
              <span className="site-menu-title">Transaction View</span>
            </a>
          </li> */}

          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/recharge/all">
              <span className="site-menu-title">Recharge</span>
            </NavLink>
          </li>
          {/* <li className="site-menu-item">
            <NavLink className="animsition-link" to="/ecom-invoice">
              <span className="site-menu-title">Ecommerce_Order</span>
            </NavLink>
          </li> */}
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/ecom-product">
              <span className="site-menu-title">Ecommerce Order</span>
            </NavLink>
          </li>
        </ul>
      </li>
      {/* <li className="site-menu-item has-sub">
        <a href="">
                <i className="site-menu-icon wb-hammer" aria-hidden="true"></i>
                <span className="site-menu-title">Reports</span>
                        <span className="site-menu-arrow"></span>
            </a>
        <ul className="site-menu-sub">
          <li className="site-menu-item hidden-sm-down site-tour-trigger">
            <a className="animsition-link" href="/Reports/ReportList">
              <span className="site-menu-title">List</span>
            </a>
          </li>
        </ul>
      </li> */}
      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-plugin" aria-hidden="true"></i>
                <span className="site-menu-title">CMS</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/CMS/Add">
              <span className="site-menu-title">Add</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/CMS/Manage">
              <span className="site-menu-title">Manage</span>
            </NavLink>
          </li>
      
        </ul>
      </li>

      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon  wb-payment" aria-hidden="true"></i>
                <span className="site-menu-title">PayOut</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
        <li className="site-menu-item">
            <NavLink className="animsition-link" to="/payout/payoutlist">
              <span className="site-menu-title">Payout List</span>
            </NavLink>
          </li>

          {/* <li className="site-menu-item">
            <a className="animsition-link" href="/payout/todaypayout">
              <span className="site-menu-title">Today PayOut</span>
            </a>
          </li> */}
         

          {/* <li className="site-menu-item">
            <a className="animsition-link" href="/payout/pendingpayout">
              <span className="site-menu-title">Pending Payout</span>
            </a>
          </li> */}
       
        
        </ul>
      
      </li>


      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon wb-settings" aria-hidden="true"></i>
                <span className="site-menu-title">Setting</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">
        <li className="site-menu-item">
            <NavLink className="animsition-link" to="/setting/general-setting">
              <span className="site-menu-title">General-Setting</span>
            </NavLink>
          </li>

          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/Banner">
              <span className="site-menu-title">Banner</span>
            </NavLink>
          </li>
         

          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/blog">
              <span className="site-menu-title">Blog</span>
            </NavLink>
          </li>
          {/* <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/horoscope">
              <span className="site-menu-title">Horoscope</span>
            </NavLink>
          </li> */}
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/offer">
              <span className="site-menu-title">Offer</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/post">
              <span className="site-menu-title">Post</span>
            </NavLink>
          </li>
          {/* <li className="site-menu-item">
            <a className="animsition-link" href="/Setting/admin">
              <span className="site-menu-title">Admin And Roll</span>
            </a>
          </li> */}
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/SendMsg">
              <span className="site-menu-title">Send-Message</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/Setting/notification">
              <span className="site-menu-title">Notification</span>
            </NavLink>
          </li>
        </ul>
      
      </li>
   

      <li className="site-menu-item has-sub">
        <NavLink to="">
                <i className="site-menu-icon  wb-help" aria-hidden="true"></i>
                <span className="site-menu-title">Support</span>
                        <span className="site-menu-arrow"></span>
            </NavLink>
        <ul className="site-menu-sub">

       
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/support/MSG">
              <span className="site-menu-title">User Support</span>
            </NavLink>
          </li>
          <li className="site-menu-item">
            <NavLink className="animsition-link" to="/support/Astro">
              <span className="site-menu-title">Astro Support</span>  
            </NavLink>
          </li>
         
         
        </ul>
      </li>
    </ul>
    </div>      
  
  )
}

export default Sidebar

