
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
// import Select from 'react-select'

const Type  = (props) => {
  const [loading, setLoading] = useState(false);
  const [user, fetchUsers] = useState([])
  let [arr, setArrList] = useState([])
  let [type, setType] = useState('blog')


  const [catData,setData]=useState([])
  const [data, fetchdata] = useState({
            "value": "",
            "order": "",
           "_id": "",
            // "model":"ProductMaterial"
          
          });
          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
            "valueedit": "",
            "orderedit": false,
            // "display_orderedit":""        
          });


          function deleterow(e){
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
            
            var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              redirect: 'follow'
            };
          
            fetch(process.env.REACT_APP_BASE_URL+"admin/deleteCategory/blog/"+e, requestOptions)

          
          .then(response => response.text())
          .then((res) => {
            setLoading(false);
          if(JSON.parse(res).errors){
            console.log(JSON.parse(res).errors)
            toast.error(JSON.parse(res)._message, { autoClose: 8000 })
          }else{
          
            toast.success("Material Delete Success", { autoClose: 15000 });
            getData()
          };
            
          })
          .catch((error) => {
            setLoading(false);
            console.log(error.errors);
            toast.error('Somthing Went Wrong', { autoClose: 8000 });
          });
          
          }
         

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"admin/getCategories/blog", requestOptions)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{

  if(Object.entries(JSON.parse(res)).length  > 0){
    var data = JSON.parse(res);
    fetchUsers(data)
 
  }
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    var typeId = "62f4fb1a1d638e2aec970444";
    if(type == 'blog'){
      typeId = '62f3720f741c331817cf27aa';
    }
    // else if(type == 'blog'){
    //   typeId = '62f3720f741c331817cf27aa';
    // }else{
    //   typeId = '62f3b5354bc36a0beeb853d1';
    // }
    var raw = JSON.stringify({
      // "_id" : typeId,
      "value": data.value,
      "order": data.order,
    });
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
   


    
    fetch(process.env.REACT_APP_BASE_URL+"admin/addCategory/blog", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        fetchdata({
        
          "value": "",
          "order": "",
        
        })
        toast.success("Category Add Success", { autoClose: 15000 });
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var typeId = "62f4fb1a1d638e2aec970444";
if(type == 'blog'){
  typeId = '62f3720f741c331817cf27aa';
}


var formdata = new FormData();
formdata.append("order", editdata.orderedit);
formdata.append("value", editdata.valueedit);
formdata.append("_id", typeId);

var requestOptions = {
  method: 'PATCH',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};
     
  
  
fetch(process.env.REACT_APP_BASE_URL+"admin/updateCategory/"+type+"/"+editdata.edit_id, requestOptions)
        .then(response => response.text())
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
        
          toast.success("Category Update Success", { autoClose: 15000 });
          getData()
        };
          
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
      }

function getbytype(e){
  setType(e);
  getData();
}
    function handle(e){
  
      const newdata={...data}
      if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['edit_id'] = item._id;
      newdata['valueedit'] = item.value;
      newdata['orderedit'] = item.order;
      // newdata['display_orderedit'] = item.display_order;
      // newdata['parent_idedit'] = item.parent_id;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
   <ToastContainer autoClose={8000} />
   <div className="page">
    <div className="page-header">
      <h1 className="page-title">Add Category </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        <li className="breadcrumb-item active">Add Category</li>
      </ol>
      <div className="page-header-actions">
        {/* <button className="btn btn-sm btn-default btn-outline btn-round" onClick={() => getbytype('horoscope')} >
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Horoscope</span>
    </button> */}
    {/* <button className="btn btn-sm btn-default btn-outline btn-round" onClick={() => getbytype('blog')} >
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Blog</span>
    </button> */}
    {/* <button className="btn btn-sm btn-default btn-outline btn-round" onClick={() => getbytype('post')} >
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Post</span>
    </button> */}
      </div>
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel" >
            <div className="panel-heading">
              <h3 className="panel-title">Add Category </h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={data.value}  className="form-control" id="value" name="value"
                          placeholder="Category Name" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Order</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    {/* <select  className="form-control" onChange={(e)=>handle(e)} value={data.display} id="display" name="display" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select> */}
                     <input type="number" onChange={(e)=>handle(e)} value={data.order}  className="form-control" id="order" name="order"
                          placeholder="Order" autocomplete="off" />
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <table className="table table-bordered table-hover table-striped" cellspacing="0" id="exampleAddRow">
                <thead>
                  <tr>
                    {/* <th>Image</th> */}
                    <th>Name</th>
                    <th>Order</th>
                    {/* <th>display</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {user?.map((item) => {
                  var status = '';
                  if(item.display){
                    status = 'enable';
                  }else{
                    status = 'diseble';
                  }
                return (
                  <tr className="gradeA">
                 
                    <td>{item.value}</td>
                    <td>{item.order}</td>
                    {/* <td>{item.display?<span style={{color:'green'}}>Active</span>:<span style={{color:'red'}}>DeActive</span>}</td> */}
                     {/* <td>{item.display_order}</td> */}

                    <td className="actions">
                   
                   <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(item)}  data-toggle="modal" data-target="#exampleModalLong"
                       data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>
                     <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(item._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
                   </td>
                  </tr>
                    )
                  })}
                </tbody>
              </table>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


     
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.valueedit}  className="form-control" id="valueedit" name="valueedit"
                          placeholder="Material Name"  />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Order</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                  <input type="number" onChange={(e)=>handleedit(e)} value={editdata.orderedit}  className="form-control" id="orderedit" name="orderedit"
                          placeholder="Material Name"  />
                  </div>
                </div>
                 {/* <div className="form-group row">
                  <label className="col-md-3 form-control-label">display_order</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.display_orderedit}  className="form-control" id="display_orderedit" name="display_orderedit"
                          placeholder="display_order"  />
                  </div>
                </div> */}
              
                <div className="text-right">
                  
                </div>
            
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
   </>
   
  )
}

export default Type
