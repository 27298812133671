
import React, { useState, useEffect } from 'react'

const Packing = () => {

  const [user, fetchUsers] = useState([])

  const getData = () => {
    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBZG1pbklEIjoxLCJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwidXNlcl90eXBlIjoiYWRtaW4iLCJpYXQiOjE2NTU2MTY4NjgsImV4cCI6MTY4NzE1Mjg2OH0.TuJ0IP7rxKlb6brd9dNfWea4X8V4exdSVrUMTHsjRls");
    
    var formdata = new FormData();
    formdata.append("model", "ProductPackingType");
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch("http://54.202.229.179:5000/api/master/listmaster", requestOptions)
.then(response => response.text())
      .then((res) => {
            fetchUsers(JSON.parse(res).data)
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Product packing</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><a href="../index.html">Dashboard</a></li>
        <li className="breadcrumb-item"><a href="">Master</a></li>
        <li className="breadcrumb-item active">Product packing</li>
      </ol>
      <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div>
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Add Product packing</h3>
            </div>
            <div className="panel-body">
              <form className="form-horizontal" id="exampleStandardForm" autocomplete="off">
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                    <input type="text" className="form-control" name="standard_fullName" />
                  </div>
                </div>
              
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Status</label>
                  <div className="col-md-9">
                      <div className="col-xl-4 col-md-6">
                        <input type="checkbox" data-toggle="toggle" data-on="Enabled" data-off="Disabled"/>
                        </div>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <table className="table table-bordered table-hover table-striped" cellspacing="0" id="exampleAddRow">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {user.map((item) => {
                return (
                  <tr className="gradeA">
                    <td>{item.value}</td>
                    <td>{item.display}</td>
                    <td className="actions">
                      <a href="#" className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row"
                        data-toggle="tooltip" data-original-title="Save" hidden><i className="icon wb-wrench" aria-hidden="true"></i></a>
                      <a href="#" className="btn btn-sm btn-icon btn-pure btn-default on-editing cancel-row"
                        data-toggle="tooltip" data-original-title="Delete" hidden><i className="icon wb-close" aria-hidden="true"></i></a>
                      <a href="#" className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row"
                        data-toggle="tooltip" data-original-title="Edit"><i className="icon wb-edit" aria-hidden="true"></i></a>
                      <a href="#" className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row"
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></a>
                    </td>
                  </tr>
                    )
                  })}
                </tbody>
              </table>
                  
          </div>
        </div>
    </div>

    

  </div>
  )
}

export default Packing
