import React, { useState,useEffect } from 'react';
import './App.css';
import {  Route, Routes, Navigate} from "react-router-dom";

// import { Route, Switch, useLocation } from 'react-router-dom';
import Sidebar from './components/common/Sidebar';
import Header from './components/common/header';
import Login from './components/auth/login';
import Dashboard from './components/dashboard';
import Footer from './components/common/footer';
import Category from './components/masters/Astrology/Category';
import Language from './components/masters/Astrology/Language';
import AstroSkills from './components/masters/Astrology/Skills';
import AstroSpecilization from './components/masters/Astrology/Specilization';
import Type from './components/masters/products/Type';
import Color from './components/masters/products/Color';
import Material from './components/masters/products/Material';
import Cuttype from './components/masters/products/Cut type';
import Origin from './components/masters/products/Origin';
import Packing from './components/masters/products/Packing';
import Zodic from './components/masters/products/Zodic';
import AddonTypeProduct from './components/masters/products/AddonType';
import DesignProduct from './components/masters/products/Design';
import Usage from './components/masters/products/Usage';
import Product from './components/masters/products/Product';
import Add_astrologer from './components/Astrologers/Add';
import Manage from './components/Astrologers/Manage';
// import Edit from './components/Astrologers/Edit';
import Astro_unverified from './components/Astrologers/Astrounverified';
import Astro_verified from './components/Astrologers/Astroverified';
import Adduser from './components/Users/Add';
import Manages from './components/Users/Manage';
import Addproduct from './components/Product/Add';
import Managee from './components/Product/Manage';
import EditProduct from './components/Product/Edit';
import Addcms from './components/CMS/Add';
import Managecms from './components/CMS/Manage';
import OrderList from './components/Order/List';
import Report_List from './components/Reports/ReportList';
import PayOutList from './components/PayOut/PayOutList';
import TodayPayOut from './components/PayOut/TodayPayOut';
import PendingPayout from './components/PayOut/PendingPayout';
import Setting_GeneralSetting from './components/Setting/GeneralSetting';
import Setting_Banner from './components/Setting/Banner';
import Setting_BannerAdd from './components/Setting/Banner/BannerAdd';
import Setting_BlogAdd from './components/Setting/Banner/BlogAdd';
import Setting_Blog from './components/Setting/Blog';
import Master_Blog from './components/masters/Category';

import Setting_OfferAdd from './components/Setting/Banner/OfferAdd';
import Setting_Offer from './components/Setting/Offer';
import Setting_Edit from './components/Setting/Banner/Edit';

import Setting_PostAdd from './components/Setting/Banner/PostAdd';
import Setting_Post from './components/Setting/Post';

import Setting_HoroscopeAdd from './components/Setting/Banner/HoroscopeAdd';
import Setting_Horoscope from './components/Setting/Horoscope';
import Setting_AdminAdd from './components/Setting/Banner/AdminAdd';
import Setting_Admin from './components/Setting/Admin';
import Call from './components/Order/Call';
import Kyc from './components/Astrologers/Kyc';
import Chat from './components/Order/Chat';
import Recharge from './components/Order/Recharge';
import Transaction from './components/Order/Transaction';
import TransactionView from './components/Order/TransactionView';
import RechargeView from './components/Order/RechargeView';
import CallView from './components/Order/CallView';
import ChatView from './components/Order/ChatView';
import OnlineComp from './components/Order/Online';
import Message from './components/Support/MSG';
import Astro from './components/Support/Astro';
import Setting_SendMsg from './components/Setting/SendMsg';
import Setting_SmsList from './components/Setting/Banner/SmsList';
// import ecom_order from './components/Order/Ecom_Order';
import Ecom_invoice from './components/Order/Ecom_Invoice';
import Products from './components/Order/Products';
import { getTokens,onMessageListener } from './services/firebase';
import { Store } from 'react-notifications-component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Notifications from './components/Setting/Notifications';
import { useNavigate } from 'react-router-dom';


export let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' , second: "numeric" };

function App() {
  const navigate = useNavigate();
//   if(localStorage === undefined){
// navigate("./login")
//   }
const user_id=localStorage.getItem('user') ?? JSON.parse(localStorage.getItem('user'))
console.log(user_id) 
    const auth_token=localStorage.getItem('token')
  
  // console.log(user_id)
  //================notification================================
  const [notification, setNotification] = useState([])
  const [isTokenFound, setTokenFound] = useState({
    status:false,
    token:''
  });


  console.log(isTokenFound)
  useEffect(() => {
    getTokens(setTokenFound);
  }, [isTokenFound.status])


  useEffect(() => {
    onMessageListener().then(payload => {


      // console.log(payload);
      setNotification({ title: payload.notification.title, body: payload.notification.body })
      toast( {
          title:'Notification',
         message: notification.body}, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });

    }).catch(err => console.log('failed: ', err));
  },[notification])


   const adminUpdate=()=>{
    var axios = require('axios');
    var FormData = require('form-data');
    var data = new FormData();
    data.append('id',user_id?.id);
    data.append('firebaseToken', isTokenFound?.token);
    
    var config = {
      method: 'post',
      url: 'https://api.jyotishee.com:8000/api/admin/updateToken',
      headers: { 
        'Authorization': auth_token 
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    
}


   

  useEffect(()=>{
    adminUpdate();
  },[isTokenFound?.token])

  //==============================ends================

  if(!localStorage.getItem('token')) {
    return <Login  />
  }
 

  return (
    <>
 <Header />
 <Sidebar />
 {/* <Dashboard /> */}

 


   <Routes>

     <Route path='/login' element={<Login />} />
     <Route exact path="/" element={<Dashboard />} />
     <Route path='/master/Astrology/Language' element={<Language />} />
     <Route path='/master/Astrology/skills' element={<AstroSkills />} />
     <Route path='/master/Astrology/specilization' element={<AstroSpecilization />} />
     <Route path='/master/Astrology/category' element={<Category />} />

     <Route path='/master/Products/Type' element={<Type />} />
     <Route path='/master/Products/Color' element={<Color />} />
     <Route path='/master/Products/Material' element={<Material />} />
     <Route path='/masters/products/cut%20type' element={<Cuttype />} />
     <Route path='/masters/Products/Origin' element={<Origin />} />
     <Route path='/masters/Products/Packing' element={<Packing />} />
     <Route path='/master/products/addon-type' element={<AddonTypeProduct />} />
     <Route path='/master/Products/design-product' element={<DesignProduct />} />
     <Route path='/masters/Products/Zodic' element={<Zodic />} />
     <Route path='/masters/Products/Usage' element={<Usage />} />
     <Route path='/masters/Products/Product' element={<Product />} />
     <Route path='/astrologers/:action' element={<Add_astrologer />} />
     <Route path='/astrologers/Manage' element={<Manage />} />
     {/* <Route path='/astrologers/Edit' element={<Edit />} /> */}
     <Route path='/Users/:action' element={<Adduser />} />
     <Route path='/Users/Manage' element={<Manages />} />
     <Route path='/Product/add' element={<Addproduct />} />
     <Route path='/Product/edit' element={<EditProduct />} />
     <Route path='/Product/Manage' element={<Managee />} />
     <Route path='/cms/:action' element={<Addcms />} />
     <Route path='/cms/Manage' element={<Managecms />} />
     {/* <Route path='/cms/Edit' element={<Edite />} /> */}
     <Route path='/Astrologers/Astrounverified' element={<Astro_unverified />} />
     <Route path='/astrologers/unapproved' element={<Astro_verified />} />
     <Route path='/Order/List' element={<OrderList />} />
     <Route path='/Reports/ReportList' element={<Report_List />} />
     <Route path='/payout/payoutlist' element={<PayOutList />} />
     <Route path='/payout/todaypayout' element={<TodayPayOut />} />
     <Route path='/payout/pendingpayout' element={<PendingPayout />} />
     <Route path='/setting/general-setting' element={<Setting_GeneralSetting />} />
     <Route path='/Setting/Banner' element={<Setting_Banner />} />
     <Route path='/setting/banner-add' element={<Setting_BannerAdd />} />
     <Route path='/setting/blog/:action' element={<Setting_BlogAdd />} />
     <Route path='/setting/blog' element={<Setting_Blog />} />
     <Route path='/masters/category' element={<Master_Blog />} />
     <Route path='/setting/offer/add' element={<Setting_OfferAdd />} />
     <Route path='/setting/offer/edit' element={<Setting_Edit />} />
     <Route path='/setting/offer' element={<Setting_Offer />} />
     <Route path='/setting/edit' element={<Setting_Edit />} />
     <Route path='/setting/post/:action' element={<Setting_PostAdd />} />
     <Route path='/setting/post' element={<Setting_Post />} />
     <Route path='/setting/horoscope/:action' element={<Setting_HoroscopeAdd />} />
     <Route path='/setting/horoscope' element={<Setting_Horoscope />} />
     <Route path='/setting/admin/:action' element={<Setting_AdminAdd />} />
     <Route path='/setting/admin' element={<Setting_Admin />} />
     <Route path='/call/:id' element={<Call />} />
     <Route path='/kyc' element={<Kyc/>} />
     <Route path='/chat/:id' element={<Chat />} />
     <Route path='/recharge/:id' element={<Recharge />} />
     <Route path='/transaction/:id' element={<Transaction />} />
     <Route path='/view-transaction' element={<TransactionView />} />
     <Route path='/view-recharge' element={<RechargeView />} />
     <Route path='/view-call' element={<CallView />} />
     <Route path='/view-chat' element={<ChatView />} />
     <Route path='/Support/Msg' element={<Message />} />
     <Route path='/Support/Astro' element={<Astro />} />
     <Route path='/online' element={<OnlineComp/>}/>
     <Route path='/setting/sendmsg' element={<Setting_SendMsg />} />
     <Route path='/setting/sendmsg/list' element={<Setting_SmsList />} />
     <Route path='ecom-invoice' element={<Ecom_invoice />}/>
     <Route path='ecom-product' element={<Products />}/>
     <Route path='/setting/notification' element={<Notifications/>}/>
   </Routes>
   <Footer />
   <ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
 </>
  );
 
}

export default App;
