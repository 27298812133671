import React, { useState, useEffect } from 'react';
import { Link, NavLink} from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ReactAudioPlayer from 'react-audio-player';
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  // <>
  
  const [loading, setLoading] = useState(false);
  const [data, fetchData] = useState([])
  const [callRecorddata, fetchCallData] = useState([])
  const location = useLocation();
  const chatdata = location.state;





  const getData = () => {
    console.log(chatdata.data)
        fetchData(chatdata.data)
  
  }

 
  useEffect(() => {
    getData()
  }, [])
  return  (
 
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Chat</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Chat view</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </NavLink>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <div className="table-responsive">
                    <table className="table ">
                     
                        <tbody className="table-primary">
                      
                 
                            <tr>
                                <th>Astrologer</th>
                                <td>{data?.astroId?.name }</td>
                            
                            </tr>
           
                            <tr>
                                <th>User</th>
                                <td>{data?.memberId?.firstName }</td>
                            
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{data?.status?'Ongoing':'Complete'}</td>
                               
                            </tr>
                           
                            
                            <tr>
                                <th>Chate Start Time</th>
                                <td>{data?.startTime}</td>
                               
                            </tr>
                            <tr>
                                <th>Chate End Time</th>
                                <td>{data?.endTime}</td>
                               
                            </tr>
                       
                          
                        </tbody>
                        </table>
                        </div>
          </div>
          <link to="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />

<div className="container">
<div className="row clearfix">
    <div className="col-lg-12">
        <div className="card-chat chat-app">
           
            <div className="chat">
                      <div className="chat-header clearfix">
                    <div className="row">
                        <div className="col-lg-6">
                            <NavLink to="" data-toggle="modal" data-target="#view_info">
                                <img src={chatdata.data.astroId?.profileImage} alt="avatar" />
                            </NavLink>
                            <div className="chat-about">
                                <h6 className="m-b-0">Astrologer :{chatdata.data.astroId?.name}</h6>
                                {/* <small>Last seen: 2 hours ago</small> */}
                            </div>
                        </div>
                        <div className="col-lg-6 hidden-sm text-right">
                        <NavLink to="" data-toggle="modal" data-target="#view_info">
                                <img src={chatdata.data.memberId?.profileImage} alt="avatar" />
                            </NavLink>
                        <div className="chat-about">
                                <h6 className="m-b-0">Member :{chatdata.data.memberId?.firstName}</h6>
                                {/* <small>Last seen: 2 hours ago</small> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-history">
                    <ul className="m-b-0">
                    {chatdata.data.chat.map((item) => {
                return chatdata.data.memberId?._id==item.sender?(
        <>  
 <li className="clearfix">
                            <div className="message-data text-right">
                                <span className="message-data-time">{chatdata.data.memberId?.firstName}</span>
                                <img src={chatdata.data.memberId.profileImage} alt="avatar" />
                            </div>
                            <div className="message other-message float-right"> {item.text=="[|0${"?<span style={{color:'red'}}>Chat Close</span>:item.text} </div>
                        </li>
  </>   
                ):(
                  <>
                   <li className="clearfix">
                            <div className="message-data">
                            <img src={chatdata.data.astroId.profileImage} alt="avatar" />
                                <span className="message-data-time">{chatdata.data.astroId.name}</span>
                               
                            </div>
                            <div className="message my-message">{item.text=="[|0${"?'Chat Close':item.text}</div>                                    
                        </li>  
                  </>   
                )
                
                    })
                  }                            
                     
                     
                    </ul>
                </div>
              
            </div>
        </div>
    </div>
</div>
</div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
