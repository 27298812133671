import React from 'react'

const footer = () => {
  return (
    <div>
       <footer className="site-footer">
      <div className="site-footer-legal">© 2022 <a href="https://jyotishee.com">Jyotishee</a></div>
      <div className="site-footer-right">
        Developed with <i className="red-600 wb wb-heart"></i> by <a href="https://www.hybridplus.in/">Hybrid Plus</a>
      </div>
    </footer>
    </div>
  )
}

export default footer
