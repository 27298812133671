import React, { useState, useEffect } from 'react';
import { Link, NavLink} from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  const [setting, fetchSettings] = useState([])
   const columns  = [
    {
      
      name: "Image",
      selector: "image",
      sortable: false,
      cell: (d) => (
        <img src={d.profileImage}  onError={({ currentTarget }) => {
     currentTarget.onerror = null; // prevents looping
     currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
   }}  width="40" height="40" style={{ margin: "4px"}} />
       )
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
     
    },
    {
      name: "PhoneNumber",
      selector: "phone",
      sortable: true,
    
    },
    {
      name: "Email",
      selector: "email",
      sortable: true
    },
    {
      name: "ChatPrice",
      selector: "chatPrice",
      sortable: true
    },
    {
      name: "CallPrice",
      selector: "callPrice",
      sortable: true
    },

    {
      name: "Status",

      sortable: true,
      cell: (d) => [
        <>
                          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => StatusAstrologer(e,d,d.status == 1?0:1)}
                          data-toggle="tooltip" data-original-title="Remove">{d.status == 1?<i className='icon wb-check' style={{color:"#05ec05"}} aria-hidden='rue'></i>:<i className='icon wb-close' style={{color:"red"}} aria-hidden='rue'></i>}</button>
      </>
      ]
    },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
        <Link to="/astrologers/edit" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          {/* <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button> */}
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  const getSettings = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/all-setting", requestOptions,5000)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  fetchSettings(JSON.parse(res))

};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
     
  }

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
  
    var formdata = new FormData();
    formdata.append("id", e);
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"admin/"+e+"/deleteAstrologer", requestOptions)


.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  toast.success("Astrologer Delete Success", { autoClose: 8000 })
  getData();
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});

}

// function StatusAstrologer(e, userdata,status){
//   console.log(status)
//   e.preventDefault();
//   e.stopPropagation();
// if(status == 1){
//   if(userdata.kyc_update != 2){
//   toast.error('Please Update User Kyc', { autoClose: 15000 })
//   return false;
// // }else if(userdata.payDate1 == 0 || userdata.payDate2 == 0 || userdata.commision == 0 || userdata.minPayout == 0  ){
// //   toast.error('Please complete pay-out-dates or commision or min-payout', { autoClose: 15000 });
// //   return false;
// }else if(userdata.available_for_call != 0 || userdata.available_for_chat != 0){
//   if(userdata.callPrice != 0 || userdata.chatPrice != 0){
  
//   }
// }else if(userdata.available_for_call != 0){
//   if(userdata.callPrice == 0){
//     toast.error('please update call price', { autoClose: 15000 });
//     return false;
//   }
// }  else if(userdata.available_for_chat != 0){
//   if(userdata.chatPrice == 0){
//     toast.error('please update chat price', { autoClose: 15000 });
//     return false;
//   }
// }  
  
// }
  


//   setLoading(true)
//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
  
//   if(status == 1){
//     var raw = JSON.stringify({
//       "status": status,
//       "payDate1": userdata.payDate1 == null || userdata.payDate1 == "" ? parseInt(setting.find(o => o.key === "default_pay_out_date_1").value) : userdata.payDate1,
//       "payDate2": userdata.payDate1 == null || userdata.payDate2 == "" ? parseInt(setting.find(o => o.key === "default_pay_out_date_2").value) : userdata.payDate2,
//       "minPayout": userdata.minPayout == 0 || userdata.minPayout == null ? parseInt(setting.find(o => o.key === "default_min_payout").value) : userdata.minPayout,
//       "commision": userdata.commision == 0 || userdata.commision == null ? setting.find(o => o.key === "default_commision").value : userdata.commision,
//     });
//   }else{
//     var raw = JSON.stringify({
//       "status": status
//       });
//   }

  
//   var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: raw,
//     redirect: 'follow'
//   };

//   fetch(process.env.REACT_APP_BASE_URL+"admin/"+userdata._id+"/verifyuser", requestOptions)

// .then(response => response.text())
//     .then((res) => {
//       getData();
//           setLoading(false)
//           if(status==1){
//             toast.success("Astrologer Active Successfully", { autoClose: 15000 })
//           }else{
//             toast.success("Astrologer Deactive Successfully", { autoClose: 15000 })
//           }
         
//         })
//         .catch(error =>  toast.error(error, { autoClose: 15000 }));
  
//     }
function StatusAstrologer(e, userdata,status){
  console.log(userdata.kyc_update)
  e.preventDefault();
  e.stopPropagation();
if(status == 1){
if(userdata.kyc_update != 2){
  toast.error('Please Update User Kyc', { autoClose: 15000 })
  return false;
// }else if(userdata.payDate1 == 0 || userdata.payDate2 == 0 || userdata.commision == 0 || userdata.minPayout == 0  ){
//   toast.error('Please complete pay-out-dates or commision or min-payout', { autoClose: 15000 });
//   return false;
}else if(userdata.available_for_call != 0 || userdata.available_for_chat != 0){
  if(userdata.callPrice != 0 || userdata.chatPrice != 0){
  
  }
}else if(userdata.available_for_call != 0){
  if(userdata.callPrice == 0){
    toast.error('please update call price', { autoClose: 15000 });
    return false;
  }
}  else if(userdata.available_for_chat != 0){
  if(userdata.chatPrice == 0){
    toast.error('please update chat price', { autoClose: 15000 });
    return false;
  }
}  
  
}
  


  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
  
  if(status == 1){
    var raw = JSON.stringify({
      "status": status,
      "payDate1": userdata.payDate1 == null || userdata.payDate1 == "" ? parseInt(setting.find(o => o.key === "default_pay_out_date_1").value) : userdata.payDate1,
      "payDate2": userdata.payDate1 == null || userdata.payDate2 == "" ? parseInt(setting.find(o => o.key === "default_pay_out_date_2").value) : userdata.payDate2,
      "minPayout": userdata.minPayout == 0 || userdata.minPayout == null ? parseInt(setting.find(o => o.key === "default_min_payout").value) : userdata.minPayout,
      "commision": userdata.commision == 0 || userdata.commision == null ? setting.find(o => o.key === "default_commision").value : userdata.commision,
    });
  }else{
    var raw = JSON.stringify({
      "status": status
      });
  }

  
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(process.env.REACT_APP_BASE_URL+"admin/"+userdata._id+"/verifyuser", requestOptions)

.then(response => response.text())
    .then((res) => {
      getData();
          setLoading(false)
          if(status==1){
            toast.success("Astrologer Active Successfully", { autoClose: 15000 })
          }else{
            toast.success("Astrologer Deactive Successfully", { autoClose: 15000 })
          }
         
        })
        .catch(error =>  toast.error(error, { autoClose: 15000 }));
  
    }

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };
    fetch(process.env.REACT_APP_BASE_URL+"admin/listunverified?page=1&limit=1000", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
            fetchUsers(JSON.parse(res))
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
    getSettings()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Astrologer</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Astrologer</NavLink></li>
        <li className="breadcrumb-item active">Unverified Astrologer List</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
