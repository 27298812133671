// import { Button } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRef } from "react";
import { options } from '../../App';

const Ecom_invoice = () => {
  const statusRef=useRef('');
  const location = useLocation();
  const dataid = location.state;
  const [data, fetchdata] = useState("");
  const [updateStatus, setUpdateStatus] = useState('');
console.log(updateStatus)

// const stUpdate=()=>{
//  console.log(statusRef?.current?.value)
// setUpdateStatus(statusRef?.current?.value);
// setTimeout(() => {
// StatusUpdate();
// }, 1000);
// }


  function Invoice() {
    var axios = require("axios");

    var config = {
      method: "get",
      url: "https://api.jyotishee.com:8000/api/admin/order/" + dataid.id,
      headers: {
        Authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data.data);
        fetchdata(response.data.data);
        console.log(response.data.data.status)
        localStorage.setItem('order_status',response.data.data.status)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  
  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }

  function StatusUpdate(e) {
    var axios = require('axios');
    var qs = require('qs');
    var data = qs.stringify({
      'status': e 
    });
    var config = {
      method: 'patch',
      url: 'https://api.jyotishee.com:8000/api/admin/updateOrderStatus/'+ dataid.id,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      Invoice();
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }

  function handle(e){
    StatusUpdate(e.target.value)
  }


  useEffect(() => {
    Invoice();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row gutters">
          <div className="col-xl-10 col-lg-12 col-md-12 col-sm-12 col-12 m-auto">
            <div className="card1" style={{ marginTop: "70px" }}>
              <div className="card-body p-0">
                <div className="invoice-container">
                  <div className="invoice-header">
                    {/* <!-- Row start --> */}
                    <div className="row gutters">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="custom-actions-btns mb-5">
                          {/* <a href="#" className="btn btn-primary">
    										<i className="icon-download"></i> Download
    									</a> */}
                    <Link
                            className="btn btn-primary"
                            data-title="Print"
                            to="/ecom-product"
                          >
                            Back
                          </Link>
                    <select className="btn btn-secondary" name="status" id="status" onChange={(e)=>handle(e)}  value={data.status}>
          
                        <option value="Ordered">Ordered</option>
                        <option value="Progress">Progress</option>
                        <option value="Shipped">Shipped</option>
                        <option value="Delivered">Delivered</option>
                        <option value="Return">Return</option>
                        <option value="Cancel">Cancel</option>
                        </select>
                    
                  
                         
                          <button type="button" onClick={Print} className="btn btn-secondary">
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Row end -->
    
    						<!-- Row start --> */}
                <div id="printablediv">
                    <div className="row gutters">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                        <a href="index.html" className="invoice-logo">
                          jyotishee.com
                        </a>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6">
                        <address className="text-right">
                          {(data.Address?.orderAddress?.state) + " - " +
                          (data.Address?.orderAddress?.townCity)}
                          <br />
                          {data.Address?.orderAddress?.phone}
                        </address>
                      </div>
                    </div>
                    {/* <!-- Row end -->
    
    						<!-- Row start --> */}
                    <div className="row gutters">
                      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <div className="invoice-details">
                          <address>
                            {data.Address?.orderAddress?.firstName}{" "}
                            {data.Address?.orderAddress?.lastName}
                            <br />
                            {data.Address?.orderAddress?.streetAddress}
                          </address>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <div className="invoice-details">
                          <div className="invoice-num">
                            <div>Invoice - #{data._id}</div>
                            <div> {new Date(data.createdAt).toLocaleDateString("en-US", options)}</div>
                            <div>Status - {data.status}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Row end --> */}
                  

                  <div className="invoice-body">
                    {/* <!-- Row start --> */}
                    <div className="row gutters">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="table-responsive">
                          <table className="table custom-table m-0">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Image</th>
                                {/* <th>GST</th> */}
                                <th>Mrp</th>
                                <th>Order Price</th>
                              </tr>
                            </thead>
                            {data?.cartItems?.map((item) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td data-th="Supplier Code">
                                      {item.ProductName}<br/>
                                      {/* {data.other2} */}
                                    </td>
                                    <td data-th="Supplier Name">
                                      <img
                                        src={item.image}
                                        width="60"
                                        height="60"
                                      />
                                    </td>
                                    {/* <td data-th="Invoice Date">
                                      {item?.cartItems?.gst}
                                    </td> */}
                                    <td data-th="Invoice Number">
                                      {item?.weight?.mrp}
                                    </td>
                                    <td data-th="Invoice Date">
                                      {/* {item?.weight?.selling_price} */}
                                      {(data.orderAmount)- (data.gst)}
                                    </td>
                                    
                                  </tr>
                                  <tr>
                    <td>&nbsp;</td>
                    <td colspan="2">
                      {/* <p>
                        Order Ammout <br />
                      </p> */}
                      <h5 className="text-success">
                        <strong>Grand Total</strong>
                      </h5>
                    </td>
                    <td>
                      {/* <p>
                      {data.orderAmount}
                        
                      </p> */}
                      <h5 className="text-success">
                        <strong>{data.total} </strong>
                      </h5>
                    </td>
                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Row end --> */}
                  </div>

              
                  
             
                  <div className="invoice-footer">Thank you for your Business.</div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ecom_invoice;
