import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "react-js-loader";
import Select from 'react-select';

const Add = (props) => {
    const navigate = useNavigate();
      const [user, fetchUsers] = useState([])
    const [loading, setLoading] = useState(false);
    let [arr, setArrList] = useState([]);
    
  const HandelChange = (obj) => {
    const newdata = { ...select };
    if(obj.type == "category"){
      newdata['Category'] = obj
    }
    
    fetchselectdata(newdata);
   console.log(obj)
  };
    const [data, fetchdata] = useState({
      _id:"",
      firstname:"",
      lastname:"",
      email:"",
      role:""
  })
  const location = useLocation();
  const dataid = location.state;
  // const [image, setSelectedImage] = useState(null);
  const [select, fetchselectdata] = useState({
    Category: null,
  });


    const getData = () => { 
  if (dataid) {
    setLoading(true)
    var axios = require('axios');
 
  var config = {
    method: 'get',
    url: 'process.env.REACT_APP_BASE_URL+"admin/getSocial/post/'+dataid.id,
    headers: { 
      'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
  
    },
  };
  
  axios(config)
  .then(function (response) {
    setLoading(false);
    select.Category = {_id: response.data._id, value: response.data.category_name,  type: 'category'};

    console.log(response.data);
    fetchdata(response.data)
   
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data._message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });
     
    }
  }
  
  
   
    
  
  
  
  function submit(e){
    setLoading(true)
    e.preventDefault();

    var axios = require('axios');
var FormData = require('form-data');
console.log(select._id)
var formdata = new FormData();
formdata.append("_id", select._id);
formdata.append("firstname", data.firstname);
formdata.append("lastname", data.lastname);
formdata.append("email", data.email);
formdata.append("role", data.role);
formdata.append("type", "blog");
// formdata.append("is_featured", data.is_featured);
// if(image){
//     formdata.append("image", image);
//   }

var url = 'process.env.REACT_APP_BASE_URL+"admin/addAstrologer';
var formMethode = "POST";
    if (dataid) {
      url = 'process.env.REACT_APP_BASE_URL+"admin/updatesdmin-detail/dataid._id';
      formMethode = "PATCH";
    }else{
      url = 'process.env.REACT_APP_BASE_URL+"admin/addSocial/post';
     formMethode = "POST";
    }
    
    var config = {
      method: formMethode,
      url: url,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
      
      },
      data : formdata
    };
axios(config)
.then(function (response) {
    setLoading(false);
    if (dataid) {
        toast.success("Admin  Update Success", { autoClose: 15000 });
      }else{
        toast.success("Admin  Add Success", { autoClose: 15000 });
      }
    console.log(response.data);
    navigate('/Setting/Admin ');
   
  })
  .catch(function (error) {
    setLoading(false)
    if (error.response) {
      if(error.response.status == 500){
        toast.error(error.response.data._message, { autoClose: 8000 })
      }else{
      // Request made and server responded
      toast.error(error.response.data, { autoClose: 8000 })
      }
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      toast.error('The request was made but no response was received', { autoClose: 8000 })
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(error.message, { autoClose: 8000 })
      console.log('Error', error.message);
    }
    console.log(error);
  });

    }
      
      function handle(e){
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        fetchdata(newdata)
        console.log(newdata)
      }
  
    useEffect(() => {
      getData();
    
    }, [])
  
    return loading? 
    <>
    <div className="App">
          
              
                <div className={"item"}>
                    <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
                </div>
         
        
        </div>
    </>
    :(
      <>
      <ToastContainer autoClose={8000} />
       <div className="page">
      <div className="page-header">
      <h1 className="page-title">Admin  Add</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Admin </NavLink></li>
        <li className="breadcrumb-item active">Add</li>
      </ol>
       
      </div>
      <div className="page-content">
      <div className="panel">
      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Add Admin  </h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Firstname</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={data.firstname}  className="form-control" id="firstname" name="firstname"
                          placeholder="Admin  Name" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Lastname</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    {/* <select  className="form-control" onChange={(e)=>handle(e)} value={data.display} id="display" name="display" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select> */}
                   
                     <input type="text" onChange={(e)=>handle(e)} value={data.lastname}  className="form-control" id="lastname" name="lastname"
                          placeholder=" lastname" autocomplete="off" />
                  </div>
                </div>
               


                

                 

                {/* <div className="form-group row">
                  <label className="col-md-3 form-control-label">image</label>
                  <div className="col-md-9">
                  <div className="custom-file">
                                          
                                          <input type="file" accept="image/png, image/gif, image/jpeg" className="custom-file-input"   id="image" placeholder="image"  onChange={(e)=>handle(e)} name="image"   />
                                         <label className="custom-file-label" for="image" style={{zIndex:"0"}}>Choose Product Image </label >
                                        
                                         <img src={data.image} onError={({ currentTarget }) => {
 currentTarget.onerror = null; // prevents looping
 currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
}} width="40" height="40"/>

                                        
                                     </div>
                  </div>
                </div> */}

                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Role</label>
                  <div className="col-md-9">
                  {/* <label for="content">Role</label> */}
                  <input type="text" onChange={(e)=>handle(e)} value={data.role}  className="form-control" id="role" name="role"
                          placeholder="Role"/>
                  </div>
                </div>
                
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Email</label>
                  <div className="col-md-9">
                  {/* <label for="content">Role</label> */}
                  <input type="email" onChange={(e)=>handle(e)} value={data.email}  className="form-control" id="email" name="email"
                          placeholder="Email"/>
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    </div>
    
  </div>
  </div>
  
           </>
    )
    }

export default Add;

