
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

const Category  = (props) => {
  const [loading, setLoading] = useState(false);
  const [user, fetchUsers] = useState([])
  let [arr, setArrList] = useState([])
  const [image, setSelectedImage] = useState(null);


  const state = {
    // options: [{name: 'Option 1️⃣', id: 1, selectedValue: false},{name: 'Option 2️⃣', id: 2, selectedValue:true}]
};
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [catData,setData]=useState([])
  const [data, fetchdata] = useState({
            "key": "",
            "value": "",
          
          
          });
          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
            "keyedit": "",
            "valueedit": "",
                   
          });
         

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/all-setting", requestOptions,5000)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  fetchUsers(JSON.parse(res))
  let tempArray =Array();
  JSON.parse(res).map((item) => { 
   
   tempArray.push({label: item.value,value: item.id}); 
  })
  setArrList(tempArray); 
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
     
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    myHeaders.append("Content-Type", "application/json");
     
    var formdata = JSON.stringify({
        "key": data.key,
        "value": data.value
      });
   
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/setting", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        fetchdata({
          "key": "",
          "value": ""
        });
        toast.success("Setting Add Success", { autoClose: 15000 });
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

    function deleterow(e){
      setLoading(true)
      var myHeaders = new Headers();
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"master/setting/"+e, requestOptions)
    
    .then(response => response.text())
    .then((res) => {
      setLoading(false);
    if(JSON.parse(res).errors){
      console.log(JSON.parse(res).errors)
      toast.error(JSON.parse(res)._message, { autoClose: 8000 })
    }else{
      toast.success("Category Delete Success", { autoClose: 15000 });
      console.log(JSON.parse(res))
      getData()
    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.errors);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
    
    }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
      myHeaders.append("Content-Type", "application/json");
      var formdata = JSON.stringify({
        "key": editdata.keyedit,
        "value": editdata.valueedit
      });
           
      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"master/setting-update/"+editdata.edit_id, requestOptions, 5000)
        .then(response => response.text())
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
          // $('body').removeClass('modal-open');
// $('.modal-backdrop').remove();
// document.getElementsByClassName('modal-backdrop').remove()
          toast.success("Category Update Success", { autoClose: 15000 });
          getData()
        };
          
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
      }


    function handle(e){
  
      const newdata={...data}
      if(e.target.type == "file"){

        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == "file"){
       
        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['edit_id'] = item._id;
      newdata['keyedit'] = item.key;
      newdata['valueedit'] = item.value;
     
      // newdata['parent_idedit'] = item.parent_id;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
  <ToastContainer autoClose={8000} />
  <div className="page">
    <div className="page-header">
      <h1 className="page-title">General Setting</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        {/* <li className="breadcrumb-item"><a href="">Astrologer</a></li> */}
        <li className="breadcrumb-item active">General-Setting</li>
      </ol>
    
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Add General-Setting </h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={data.key}  className="form-control" id="key" name="key"
                          placeholder="Key" autocomplete="off" required/>
                  </div>
                </div>
              
                
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Value</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={data.value}  className="form-control" id="value" name="value"
                          placeholder="Value" autocomplete="off" />
                  </div>
                </div>
               
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <table className="table table-bordered table-hover table-striped" cellspacing="0" id="exampleAddRow">
                <thead>
                  <tr>
                    {/* <th>Image</th> */}
                    <th>Name</th>
                    <th>Value</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {user.map((item,key) => {
                 
                return (
                  <tr className="gradeA">
                 
                    <td>{item.key}</td>
                    <td style={{wordBreak: "break-all"}}>{item.value}</td>
                    <td className="actions">
                   
                    <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(item)}  data-toggle="modal" data-target="#exampleModalLong"
                       data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>
                       {key > 24 ?
                       <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(item._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button> : ""}
                   </td>
                  </tr>
                    )
                  })}
                </tbody>
              </table>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"  aria-labelledby="preview-modal" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit General-Setting</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


     <div className="col-md-12 row">
                <div className="form-group">
                  <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.keyedit}  className="form-control" id="keyedit" name="keyedit"
                          placeholder="Key" required />
                  </div>
                </div>
             
                <div className="form-group">
                  <label className="col-md-3 form-control-label">Value</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.valueedit}  className="form-control" id="valueedit" name="valueedit"
                          placeholder="Value" autocomplete="off" />
                  </div>
                </div>
              
                </div>
              
                <div className="text-right">
                  
                </div>
            
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
  </>
    
  )
}

export default Category
