
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import {Link, NavLink} from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const Horoscope  = (props) => {
  const [loading, setLoading] = useState(false);
  const [image, setSelectedImage] = useState(null);
  const [data, fetchdata] = useState([])
  let [arr, setArrList] = useState([])


  const [catData,setData]=useState([])
  const columns = [
    {
      name: "Image",
      
      sortable: false,
      cell: (d) => (
        <img src={d.featured_image}  onError={({ currentTarget }) => {
     currentTarget.onerror = null; // prevents looping
     currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
   }}  width="40" height="40" style={{ margin: "4px"}} />
       )
    },
    {
      name: "Name",
      selector: row=> row.name,
      sortable: true,
     
    },
    {
      name: "Slug",
      selector: row=> row.slug,
      sortable: true,
     
    },

    
    {
      name: "Action",
      sortable: false,

      cell: (d) => [
        <>
        <Link to="/setting/horoscope/edit" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
                    "nameedit": "",
                    "slugedit": "",
                    "introedit": "",
                    "is_featurededit": "",
                    "featured_imageedit": ""      
          });


          function deleterow(e){
            setLoading(true)
        var axios = require('axios');

var config = {
  method: 'delete',
  url: process.env.REACT_APP_BASE_URL+`admin/deleteSocial/post/${e}`,
  headers: { 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
  }
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});
          
          }
         

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BASE_URL+"admin/getSocial/horoscope?page=1&limit=1000", requestOptions)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{

  if(Object.entries(JSON.parse(res).results).length  > 0){
    var data = JSON.parse(res).results;
    fetchdata(data)
 
  }
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    

    var formdata = new FormData();
    formdata.append("category_id", '62f3b44b7f48fc87227fe15f');
formdata.append("name", data.name);
formdata.append("slug", data.slug);
formdata.append("intro", data.intro);
formdata.append("is_featured", data.is_featured);
formdata.append("featured_image", data.featured_image);

    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };


    
    fetch(process.env.REACT_APP_BASE_URL+"admin/addSocial/post", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        fetchdata({
            "name": "",
            "slug": "",
            "intro": "",
            "is_featured": "",
            "featured_image": "",
          "model":"ProductMaterial"
        
        })
        toast.success("Material Add Success", { autoClose: 15000 });
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var formdata = new FormData();
formdata.append("name", editdata.nameedit);
formdata.append("slug", editdata.slugedit);
formdata.append("intro", editdata.introedit);
formdata.append("is_featured", editdata.is_featurededit);
formdata.append("featured_image", image?image:editdata.featured_imageedit);


var requestOptions = {
  method: 'PATCH',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};
     
  
  
fetch(process.env.REACT_APP_BASE_URL+"admin/updateSocial/post/"+e, requestOptions)
        .then(response => response.text())
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
        
          toast.success("Material Update Success", { autoClose: 15000 });
          getData()
        };
          
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
      }


    function handle(e){
  
      const newdata={...data}
      if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        } 
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['nameedit'] = item.name;
      newdata['slugedit'] = item.slug;
      newdata['introedit'] = item.intro;
      newdata['is_featurededit'] = item.is_featured;
      newdata['featured_imageedit'] = item.featured_image;

    
      
      // newdata['parent_idedit'] = item.parent_id;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
   <ToastContainer autoClose={8000} />
   <div className="page">
    <div className="page-header">
      <h1 className="page-title">Horoscope </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        <li className="breadcrumb-item active">Horoscope</li>
      </ol>
      <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="/setting/horoscope/add"
          target="_blank">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">Add</span>
    </NavLink>
      </div>
    </div>

    <div className="page-content container-fluid">
      

     

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="false">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


     
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.nameedit}  className="form-control" id="nameedit" name="nameedit"
                          placeholder="Horoscope Name"  />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">slug</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    {/* <select  className="form-control" onChange={(e)=>handleedit(e)} value={editdata.slugedit}  id="slugedit" name="slugedit" >
                    <option >Select Any one</option>
                      <option value='1' select>Yes</option>
                      <option value='0'>No</option>
                    </select> */}
                    <input type="text" onChange={(e)=>handleedit(e)} value={editdata.slugedit}  className="form-control" id="slugedit" name="slugedit"
                          placeholder="slug"  />
                  </div>
                </div>
                 <div className="form-group row">
                  <label className="col-md-3 form-control-label">intro</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.introedit}  className="form-control" id="introedit" name="introedit"
                          placeholder="intro"  />
                  </div>
                </div>
                 <div className="form-group row">
                  <label className="col-md-3 form-control-label">is_featured</label>
                  <div className="col-md-9">
                  <input type="number" onChange={(e)=>handleedit(e)} value={editdata.is_featurededit}  className="form-control" id="is_featurededit" name="is_featurededit"
                          placeholder="is_featured"  />
                  </div>
                </div>
                 <div className="form-group row">
                  <label className="col-md-3 form-control-label">Image</label>
                  <div className="col-md-9">
                  <div className="custom-file">
                                          
                                          <input type="file" accept="image/png, image/gif, image/jpeg" className="custom-file-input"   id="featured_imageedit" placeholder="image"  onChange={(e)=>handle(e)} name="featured_image"   />
                                         <label className="custom-file-label" for="image" style={{zIndex:"0"}}>Choose Product Image </label >
                                        
                                         <img src={data.featured_image} onError={({ currentTarget }) => {
 currentTarget.onerror = null; // prevents looping
 currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
}} width="40" height="40"/>

                                        
                                     </div>
                  </div>
                </div>
              
                <div className="text-right">
                  
                </div>
            
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
   </>
   
  )
}

export default Horoscope
