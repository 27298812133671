import React, { useState, useEffect, useRef } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
// import { CKEditor } from "ckeditor4-react";

const Add = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, fetchdata] = useState({
    title: "",
    slug: "",
    section: "",
    description: "",
  });
  const location = useLocation();
  const dataid = location.state;

  // console.log(dataid);
  const editor = useRef(null);
  const [site, setSite] = useState("");

  const getData = () => {
    if (dataid) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL+"admin/" + dataid.id + "/getcms",
        requestOptions
      )
        .then((response) => response.text())
        .then((res) => {
          setLoading(false);
          // localStorage.setItem('des',res)
          console.log(JSON.parse(res));
          fetchdata(JSON.parse(res));
        })
        
        .catch((error) => {
          setLoading(false);
          toast.error(JSON.stringify(error), { autoClose: 8000 });
        });
    }
  };

  function submit(e) {
    // localStorage.removeItem('des')
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4"
    );
    // var formdata = new FormData();
    // formdata.append("title", data.title);
    // formdata.append("slug", data.slug);
    // formdata.append("section", data.section);
    // formdata.append("description", data.description);
    // if (dataid) {
    //   formdata.append("id", dataid.id);
    // }

    var raw = JSON.stringify({
      title: data.title,
      slug: data.slug,
      section: data.section,
      description: data.description,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    let url = process.env.REACT_APP_BASE_URL+"admin/mastercms";
    if (dataid) {
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      url =
        process.env.REACT_APP_BASE_URL+"admin/" + dataid.id + "/updatecms";
    } else {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      url = process.env.REACT_APP_BASE_URL+"admin/mastercms";
    }
    setTimeout(() => {
      fetch(url, requestOptions, 5000)
        .then((response) => response.text())
        .then((res) => {
          setLoading(false);
          if (JSON.parse(res).errors) {
            console.log(JSON.parse(res).errors);
            toast.error(JSON.parse(res)._message, { autoClose: 8000 });
          } else {
            if (dataid) {
              toast.success("Page Update Success", { autoClose: 8000 });
            } else {
              toast.success("Page Add Success", { autoClose: 8000 });
            }

            navigate("/cms/Manage");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error("Somthing Went Wrong", { autoClose: 8000 });
        });
    });
  }

  console.log({ ...data });
  function handle(e) {
    const newdata = { ...data };

    if (e.name == "description") {
      newdata["description"] = e.getData();
    } else {
      newdata[e.target.id] = e.target.value;
    }
    fetchdata(newdata);
    console.log(newdata);
  }

  const handleInstanceReady = ({ editor }) => {
    // Will be triggered only once, when editor is ready for interaction.
    console.log(data.description);
    editor.setData(data.description);
  };

  // window.onload = Hhh()

  useEffect(() => {
    getData();
    // window.onload="getData()"
  }, []);

  // const ckdescription=JSON.parse(localStorage.getItem('des'));
  // console.log(ckdescription)

  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ToastContainer autoClose={8000} />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title">CMS Manage</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="" className='Cursor'>Cms</NavLink>
            </li>
            <li className="breadcrumb-item active">{dataid ? "edit" : "add"}</li>
          </ol>
          {/* <div className="page-header-actions">
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Edit">
          <i className="icon wb-pencil" aria-hidden="true"></i>
        </button>
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Refresh">
          <i className="icon wb-refresh" aria-hidden="true"></i>
        </button>
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Setting">
          <i className="icon wb-settings" aria-hidden="true"></i>
        </button>
      </div> */}
        </div>
        <div className="page-content">
          <div className="panel" style={{backgroundolor:"#f1f4f5"}}>
            <h5>
              {" "}
              <span style={{ color: "red", marginLeft: "10px" }}>
                All Field Are Required
              </span>{" "}
            </h5>

            <form
              onSubmit={(e) => submit(e)}
              className="validate-me"
              id="validate-me"
              data-validate
            >
              <div className="panel-body container-fluid">
                <div className="row row-lg">
                  {/* <h4 className="example-title">Add New CMS Form</h4> */}

                  <div className="col-12">
                    {/* <!-- Example Basic Form (Form grid) --> */}

                    <div className="row">
                      <div className="form-group col-8">
                        <div className="form-floating">
                          <input
                            onChange={(e) => handle(e)}
                            id="title"
                            style={{ border: "1px solid black" }}
                            className="form-control"
                            value={data.title}
                            placeholder="Titel"
                            type="text"
                            required
                          />
                          <label for="title">Titel</label>
                        </div>
                      </div>

                      <div className="form-group col-md-2">
                        <div className="form-floating">
                          <input
                            onChange={(e) => handle(e)}
                            id="slug"
                            style={{ border: "1px solid black" }}
                            className="form-control"
                            value={data.slug}
                            placeholder="Slug"
                            type="text"
                            required
                          />
                          <label for="slug">Slug</label>
                        </div>
                      </div>

                      <div className="form-group col-2">
                        <div className="form-floating">
                          <input
                            onChange={(e) => handle(e)}
                            id="section"
                            style={{ border: "1px solid black" }}
                            className="form-control"
                            value={data.section}
                            placeholder="Section"
                            type="text"
                            required
                          />
                          <label for="section">Section</label>
                        </div>
                      </div>
                    </div>

                    {/* <!-- End Example Basic Form (Form grid) --> */}
                  </div>

                  <div className="form-group col-12">
                    <label for="floatingTextarea2"> Add description</label>

                    {/* {
                      <CKEditor
                        ref={editor}
                        id="description"
                        name="description"
                        data={data.description}
                        initData={data.description}
                        value={data.description}
                        onBlur={({ editor }) => handle(editor)}
                        onInstanceReady={handleInstanceReady}
                      />
                     
                    } */}
                    

                    {/* <JoditEditor
                    id="description"
                   
                      // tabIndex of textarea
                      initData={data.description}
                      value={data.description}
                         onBlur={({ editor }) => handle(editor)}
                         onInstanceReady={handleInstanceReady}
                    /> */}

                    <textarea onChange={(e)=>handle(e)} id="description" style={{border: "1px solid black", height: "150px" }}  className="form-control" value={data.description} placeholder='description' type="text"></textarea>
                  </div>

                  <div className="form-group col-12">
                    <button type="submit" className="btn btn-block btn-success">
                      submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
