import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { NavLink } from 'react-router-dom';


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])

   const columns = [
    {
      name: "Image",
   
      sortable: false,
      cell: (d) => (
        <img src={d.image}  onError={({ currentTarget }) => {
     currentTarget.onerror = null; // prevents looping
     currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
   }}  width="40" height="40" style={{ margin: "4px"}} />
       )
    },
    {
      name: "Name",
      selector: "name",
      sortable: true,
     
    },
    {
      name: "Section",
      selector: "section",
      sortable: true,
    
    },

    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
        <Link to="/setting/banner-add" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
                          <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                          data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");  
    var formdata = new FormData();
    formdata.append("id", e);
    // formdata.append("model", "AstrologerLanguage");
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"master/banner-delete/"+e, requestOptions)

.then(response => response.text())
      .then((res) => {
        getData();
            setLoading(false)
            toast.success("Data deleted Successfully!", { autoClose: 15000 })
          })
          .catch(error =>  toast.error(error, { autoClose: 15000 }));
    
      }

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw");
    

    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      
      redirect: 'follow'
    };

fetch(process.env.REACT_APP_BASE_URL+"master/all-banner?page=1&limit=1000", requestOptions)
.then(response => response.text())
      .then((res) => {
        setLoading(false);
            fetchUsers(JSON.parse(res))
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
    <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Banner</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="#" className='Cursor'>Setting</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Banner</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="/setting/banner-add">
      <i aria-hidden="true"></i>
      <span className="hidden-sm-down">Add</span>
    </NavLink>
      </div>
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
