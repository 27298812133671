import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import Loader from "react-js-loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { NavLink } from 'react-router-dom';
import { options } from '../../App';


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchData] = useState([])
  const location = useLocation();
  const dataid = location.state;

   const columns = [
   
   
    
   
    {
      name: "User",
      selector:row=> row.memberId?.firstName,
      sortable: true,
     
    },
    {
      name: "Astrologer",
      selector:row=> row.astrologerId?.name,
      sortable: true,
     
    },
    {
      name: "Mode",
      selector:row=> row.mode,
      sortable: true,
     
    },
    {
      name: "Admin Commision",
      selector:row=> row.adminCommision,
      sortable: true,
     
    },
    {
      name: "Astrologer Commision",
      selector:row=> row.credited,
      sortable: true,
     
    },
    {
      name: "User Deducted Amount",
      selector:row=> row.deducted,
      sortable: true,
     
    },
    {
      name: "Duration",
      selector:row=> row.duration,
      sortable: true,
     
    },
  
    {
      name: "Time",
      selector:row=> <span title={new Date(row?.createdAt).toLocaleDateString("en-US", options)}>{new Date(row?.createdAt).toLocaleDateString("en-US", options)}</span>,
      sortable: true,
     
    },
  
     

    // {
    //   name: "Status",

    //   sortable: true,
    //   cell: (d) => [
    //     <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" 
    //                       data-toggle="tooltip" data-original-title="Remove">{d.status == 0?<i className='icon wb-check' aria-hidden='rue'></i>:<i className='icon wb-close' aria-hidden='rue'></i>}</button>
   
    //   ]
    // },

    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
        <Link to="/view-transaction" state= {{
          id: d._id
        }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-eye" aria-hidden="true"></i></Link>
                         
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }



  const getData = (s=null,e = null) => {
    setLoading(true);
    var axios = require('axios');
// console.log(dataid._id)
var url = process.env.REACT_APP_BASE_URL+"admin/allTransaction";
if(dataid){
  url = url+'/'+dataid.id+`/${dataid.type}`
}
if(s && s<e){

  url = url+"?startingfrom="+s+"&endto="+e;
}

    var config = {
      method: 'get',
      url: url,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoading(false);
           
      console.log(response.data);
      fetchData(response.data)
     
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response) {
        if(error.response.status == 500){
          toast.error(error.response.data._message, { autoClose: 8000 })
        }else{
        // Request made and server responded
        toast.error(error.response.data, { autoClose: 8000 })
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('The request was made but no response was received', { autoClose: 8000 })
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(error.message, { autoClose: 8000 })
        console.log('Error', error.message);
      }
      console.log(error);
    });
  }

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState();


  function daterange(s,e) {
    if(s>e){
      alert('Start date must be less than end date')
    }else{
      setStartDate(s);
    setEndDate(e);
    }
    // change start date format
    var createdDate = new Date(s);

var day = createdDate.getDate();
var month = createdDate.getMonth() + 1; //months are zero based
var year = createdDate.getFullYear();
 
  // change end date format
var endingDate = new Date(e);

var day2 = endingDate.getDate();
var month2 = endingDate.getMonth() + 1; //months are zero based
var year2 = endingDate.getFullYear();

    getData(''+year+'/'+month+'/'+day+'',''+year2+'/'+month2+'/'+day2+'');
   }

   let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Transaction List</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Transaction</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
    
    </div>
    

    <div className="page-content container-fluid">
     
    </div>
   
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
              <div className="form-group mb-2">
    <label for="staticEmail2" className="">Select Date Range</label>
     </div>
  <div className="form-group mx-sm-3 mb-2" style={{width:"15%"}}>
    <DatePicker
       selected={startDate}
       onChange={(date) => daterange(date,endDate)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       placeholderText='select starting date'
       className="form-control"
      />
  </div>
  <div className="form-group mx-sm-3 mb-2" style={{width:"15%"}}>
    <DatePicker
         selected={endDate}
         onChange={(date) => daterange(startDate,date)}
         selectsEnd
         startDate={startDate}
         endDate={endDate}
         minDate={startDate}
         placeholderText='select ending date'
         className="form-control"/>
  </div>
            </div>
           
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
