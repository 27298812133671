
import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const MSG = () => {
  
  const user = localStorage.getItem('user').user;
  // console.log(localStorage.getItem('user').user)
  let [messagesEnd, fetchMessageEnd] = useState()
  const location = useLocation();
const dataid = location.state;
    const [data, fetchUsers] = useState([])
    const [chatlist, fetchChat] = useState([])
    const [currentChat, fetchCurrentChat] = useState([])
    const [sendermsg, fetchdatasender] = useState([])
    // const [user] = useState([])
    // let [arr, setArrList] = useState([])
    const [loading, setLoading] = useState(false);

    setInterval(() => {
     
    }, 10000);
      const getData = () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch("https://api.jyotishee.com:8000/api/admin/getAll-support-chat?type=astro&page=1&limit=30", requestOptions,5000)
    .then(response => response.text())
    .then((res) => {
      setLoading(false);
    if(JSON.parse(res).errors){
      console.log(JSON.parse(res).errors)
      toast.error(JSON.parse(res)._message, { autoClose: 8000 })
    }else{
      fetchUsers(JSON.parse(res))
  //     let tempArray =Array();
  //     JSON.parse(res).map((item) => { 
  //         if(currentChat){
  // if(item._id == currentChat._id){
  //   console.log(item._id)
  // }
  //     }
  //      tempArray.push({label: item.value,value: item.id}); 
  //     })
  //     setArrList(tempArray); 
   
    
    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.errors);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
         
      }
      
let ch____id;
      function getchatBydata  (e =null)  { 
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
   
        let chatid = e?e._id:JSON.parse(localStorage.getItem('crrntid'));
    
   
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
      
        ch____id=chatid
        
        fetch("https://api.jyotishee.com:8000/api/get-support-chat/"+chatid, requestOptions)
    .then(response => response.text())
    .then((res) => {
      setLoading(false);
      if(JSON.parse(res).errors){
      console.log(JSON.parse(res).errors)
      toast.error(JSON.parse(res)._message, { autoClose: 8000 })
    }else{

      fetchChat(JSON.parse(res))
      fetchCurrentChat(JSON.parse(res));
      localStorage.setItem('crrntid',JSON.stringify(JSON.parse(res)?._id))
      var objDiv = document.getElementsByClassName("chats");
      objDiv.scrollTop = objDiv.scrollHeight;

    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
         
      }
let senderchatmsg;
// console.log(sendermsg.text)
      if(sendermsg?.text){
       senderchatmsg = (e) =>{
        e.preventDefault();
      // {console.log(user._id)}
        setLoading(true);
        var axios = require('axios');
    var formdata = JSON.stringify({
      "supportId": currentChat._id,
      "sender": sendermsg._id,
      "recipient": currentChat.astroId._id,
      "text": sendermsg.text,
      "media": "",
      "senderName": sendermsg.fname,
      "recieverName": currentChat?.astroId?.name
    });
  
    var config = {
      method: 'post',
      url: 'https://api.jyotishee.com:8000/api/support-chat-message',
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyZGJjZWNmOTliYjE2MzJkMjE4OGQzZCIsImlhdCI6MTY2MDU1MDE0M30.v-hspyZm7YUfQnKodGMAec0mSpcOiDPQil9fdgNn3Pc', 
        'Content-Type': 'application/json'
      },
      data : formdata
    };
    
    axios(config)
    .then(function (response) {
 
      getchatBydata(currentChat);
      e.target.reset();
      fetchdatasender({
        "text": "",
    })
      // scrollToBottom()
      
    toast.success("MSG Send Success", { autoClose: 15000 });
    })
    .catch(function (error) {
      console.log(error);
    });


     
      
        }
      }else{}

        function handle(e){
  
          const newdata={...sendermsg}
          if(e.target.type === 'checkbox'){
            console.log(e.target.value)
            if(e.target.value === "true"){
              
              newdata[e.target.id] = false;
            }else{
              newdata[e.target.id] = true;
            }
          }else{
          newdata[e.target.id] = e.target.value
          }
          
          fetchdatasender(newdata)
        }

        function scrollToBottom(){
          this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        }

        let getchat;
        getchat=(e)=> {
          getchatBydata(e);
        }

      
      useEffect(() => {
        let interval = setInterval(() => {
          setLoading(true);
          var myHeaders = new Headers();
          myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
     
          // let chatid = e?e._id:currentChat._id;
      
          console.log(currentChat)
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          // console.log(chatid)
          
          fetch(`https://api.jyotishee.com:8000/api/get-support-chat/${JSON.parse(localStorage.getItem('crrntid'))}`, requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
        if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
  
        fetchChat(JSON.parse(res))
        fetchCurrentChat(JSON.parse(res));
        var objDiv = document.getElementsByClassName("chats");
        objDiv.scrollTop = objDiv.scrollHeight;
  
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
        }, 5000);
        return () => {
          clearInterval(interval);
        };
      }, []);

      function endchat(e) {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
       
        let chatid = currentChat?currentChat._id:e._id;
        console.log(e)
        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        console.log(currentChat._id)

        fetch("https://api.jyotishee.com:8000/api/support-chat-status/"+chatid+"/0", requestOptions,5000)
    .then(response => response.text())
    .then((res) => {
      setLoading(false);
      if(JSON.parse(res).errors){
      console.log(res.data)
      toast.error(res.data.message, { autoClose: 8000 })
    }else{
console.log(JSON.parse(res).message)
toast.success(JSON.parse(res).message, { autoClose: 8000 })
      getchatBydata(chatid);
      
      var objDiv = document.getElementsByClassName("chats");
      objDiv.scrollTop = objDiv.scrollHeight;
      

    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
         
      }

      useEffect(() => {
        getData()
        const interval = setInterval(() => getData(), 10000)
        
        return () => {
          clearInterval(interval);
        }
        
      
       
      }, [])

   
     
  return (
    // <div className="animsition site-navbar-small app-message page-aside-scroll page-aside-left">
<div className=" site-navbar-small app-message page-aside-scroll page-aside-left clasic">
  
  


    <div className="page">
    
      <div className="page-aside">
        <div className="page-aside-switch">
          <i className="icon wb-chevron-left" aria-hidden="true"></i>
      <i className="icon wb-chevron-right" aria-hidden="true"></i>
        </div>
        <div className="page-aside-inner">
          <div className="input-search">
            <button className="input-search-btn" type="submit">
              <i className="icon wb-search" aria-hidden="true"></i>
            </button>
            <form>
              <input className="form-control" type="text" placeholder="Search Keyword" name="" />
            </form>
          </div>

          <div className="app-message-list">
            <div data-role="container" style={{overflowY: "scroll",overflowX: "scroll",maxHeight: '600px',width:"350px",    minHeight: '410px'}}>


              <div data-role="content">
                <ul className="list-group"> 
                {data.map((item) => {
                    return (
                  <li className="list-group-item" onClick={(e) => getchat(item)} >
                    <div className="media">
                      <div className="pr-20">
                        <NavLink className="avatar avatar-online" to="">
                          {/* <img className="img-fluid" src="%PUBLIC_URL%/assets/global/portraits/1.jpg"
                            alt="..." /> */}
                            <i></i></NavLink>
                      </div>
                     
                      
                      <div className="media-body">
                    {/* {console.log(item)} */}
                  
                        <h5 className="mt-0 mb-5">{item?.astroId?.name}</h5>
                        <p className="mt-0 mb-2">{item?._id}</p>
                        <span className="media-time">{item.createdAt}</span><br/>
                        {item.status == 0 ? <><span className="media-time" style={{color:'red'}}>Chat Closed</span></>:<></> }
                      </div>
                     
                      
                      <div className="pl-20">
                        <span className="badge badge-pill badge-danger">{item?.chat?.length}</span>
                      </div>
                    </div>
                  </li>
                    )
              })}
                </ul>
              </div>



            </div>
          </div>
        </div>
      </div>




      
  
      <div className="page-main" >
     
        <div className="app-message-chats">
          {/* <button type="button" id="historyBtn" className="btn btn-round btn-outline btn-default">History Messages</button> */}
          <div className="chats bgchats" style={{overflowY: "scroll",maxHeight: '410px',    minHeight: '410px'}}>
          

          
{currentChat.userType == "member"?
<>

{chatlist.chat?.map((item) => {
             return (
             (currentChat?.memberId?._id != item.sender ? 
                <>
   
            <div className="chat chat-left">
              <div className="chat-avatar">
                <NavLink className="avatar" data-toggle="tooltip" to="#" data-placement="right" title="">
                  {/* <img src="%PUBLIC_URL%/assets/global/portraits/4.jpg" alt="June Lane" /> */}
                </NavLink>
              </div>
              <div className="chat-body chat-left">
                <div className="chat-content">
                  <p>
                   {item?.text}
                  </p>

                </div>
              </div>
            </div>
            
            
            </> 
            :
            <>
            <div className="chat chat-left">
              <div className="chat-avatar">
                <NavLink className="avatar" data-toggle="tooltip" to="#" data-placement="left" title="">
                  {/* <img src="%PUBLIC_URL%/assets/global/portraits/5.jpg" alt="Edward Fletcher" /> */}
                </NavLink>
              </div>
              <div className="chat-body">
               
                <div className="chat-content">
                  <p>
                  {item?.text}
                  </p>

                </div>
              </div>
            </div>
            </>
             )
            
            )
        })}
</>
:
<>
{chatlist.chat?.map((item) => {
             return (
             (currentChat?.memberId != item.sender ? 
                <>
         
            <div className="chat chat-left">
              <div className="chat-avatar">
                <NavLink className="avatar" data-toggle="tooltip" to="#" data-placement="right" title="">
                  {/* <img src="%PUBLIC_URL%/assets/global/portraits/4.jpg" alt="June Lane" /> */}
                </NavLink>
              </div>
              <div className="chat-body">
                <div className="chat-content">
                  <p>
                   {item?.text}
                  </p>

                </div>
              </div>
            </div>
            
            </> 
            :
          
            <>
        
            <div className="chat">
              <div className="chat-avatar">
                <NavLink className="avatar" data-toggle="tooltip" to="#" data-placement="left" title="">
                  {/* <img src="%PUBLIC_URL%/assets/global/portraits/5.jpg" alt="Edward Fletcher" /> */}
                </NavLink>
              </div>
              <div className="chat-body">
                <div className="chat-content">
                  <p >
                   {item?.text}
                  </p>
                </div>
              </div>
            </div>
            </>
             )
            
            )
        })}
</>
}



          

          </div>


        </div>
        <div style={{textAlign:"center", backgroundColor:"#f3f7f9"}}>
          {chatlist.status == 1 ? <><button type='button' onClick={endchat} style={{border:"none", backgroundColor:"#f3f7f9"}}> Click to close chat</button> </>:<><button type='button' onClick={endchat} style={{border:"none", backgroundColor:"#f3f7f9", color:"red"}}>Chat Closed</button></>}
          
        </div>
        <div style={{ float:"left", clear: "both" }}
             ref={(el) => { messagesEnd = el; }}>
        </div>
        {chatlist.status == 1 ? <>
        <form className="app-message-input" onSubmit={(e)=> senderchatmsg(e)}>
          <div className="message-input">
       
                
                  <input type="text" onChange={(e)=>handle(e)}   className="form-control" id="text" name="text"
                          placeholder="Type msg" autocomplete="off" />
                 
            
            <div className="message-input-actions btn-group">
              <button className="btn btn-pure btn-icon btn-default" type="button">
                <i className="icon wb-emoticon" aria-hidden="true"></i>
              </button>
              <button className="btn btn-pure btn-icon btn-default"   type="button">
                <i className="icon wb-image" aria-hidden="true"></i>
              </button>
              
              <button className="btn btn-pure btn-icon btn-default" type="button">
                <i className="icon wb-paperclip" aria-hidden="true"></i>
              </button>
              {/* <input id="messageImage" type="file" name="messageImage" /> */}
              <input id="messageFile" type="file" name="messageFile" />
            </div>
          </div>
          <button className="message-input-btn btn btn-primary" type="submit">SEND</button>
        </form>
       </>
       :''
}

      </div>
    </div>

</div>





  
   
   
   
  )
}

export default MSG
