import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate, Link, NavLink} from "react-router-dom";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";


const Manage = () => {
  const [data, fetchUsers] = useState([])
  const [loading, setLoading] = useState(false);
  const columns = [
    
    {
      name: "Title",
      selector: row => row.title,
      sortable: true,
     
    },
    {
      name: "Slug",
      selector: row => row.slug,
      sortable: true,
    
    },
    
 
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
       <Link to="/cms/edit"
      state= {{
        id: d._id
      }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><i className="icon wb-edit" aria-hidden="true"></i></Link>
         {d._id == "62ecb0863d1104c19dd68ed3" || d._id == "6309d27d7ca3649f026f6f31" || d._id == "6309d2bc7ca3649f026f6f3a" || d._id == "6309d3a97ca3649f026f6f57" || d._id == "6309d3d87ca3649f026f6f5b" || d._id == "633d53f1e846047b0503f1dd" ? "":  
                       <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>
    }
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  function deleterow(e){
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://api.jyotishee.com:8000/api/admin/"+e+"/deletecms", requestOptions)

.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  toast.success("Page Delete Success", { autoClose: 15000 });
          getData();  
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});


  }
  const getData = () => {
    setLoading(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
   var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};


fetch("https://api.jyotishee.com:8000/api/admin/getallcms", requestOptions, 5000)

.then(response => response.text())
      .then((res) => {
            fetchUsers(JSON.parse(res))
            setLoading(false)
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
  }

  useEffect(() => {
    getData()
  }, [])
  return loading? 
  <>
  <div className="App">
        
            
              <div className={"item"}>
                  <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
              </div>
       
      
      </div>
  </>
  :  
  <>
  (
    <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">CMS Manage</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>CMS</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
 
  )
  </>
}

export default Manage
