import React, { useState, useEffect } from 'react'


import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
// import { Multiselect } from 'multiselect-react-dropdown';
// import Loader from "react-js-loader";
// import Axios from 'axios'

const AddonType  = (props) => {
  const [data, fetchUsers] = useState([])
  const [loading, setLoading] = useState(false);
  const [image, setSelectedImage] = useState(null);


  const state = {
    // options: [{name: 'Option 1️⃣', id: 1, selectedValue: false},{name: 'Option 2️⃣', id: 2, selectedValue:true}]
};

const columns = [
 
  {
      name: 'Name',
      selector: row => row.name,
  },
  {
    name: 'Price',
    selector: row => row.price,
},
  {
      name: 'Status',
      selector: row => row.status==1?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Deactivate</span>,
  },
  {
    name: "Image",
    selector: "image",
    sortable: false,
    cell: (d) => (
      <img src={d.image}  onError={({ currentTarget }) => {
   currentTarget.onerror = null; // prevents looping
   currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
 }}  width="40" height="40" style={{ margin: "4px"}} />
     )
  },
  {
      name: 'Action',
      sortable: false,
      cell: (d) => [
        <>
            <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(d)}  data-toggle="modal" data-target="#exampleModalLong"
                        data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>
                        
                        <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>

        </>
      ]
  },
];


let tableData = {
  columns,
  data
}


  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [catData,setData]=useState([])
  const [user, fetchdata] = useState({
    "id": "",
            "value": "",
            'price':"",
            "image" : "",
            "status": ""
          
});
const [editdata, fetchdataedit] = useState({
          "editid": "",
          "valueedit": "",
          "priceedit": "",
          "statusedit": "",
          "imageedit" : ""
        
});


function deleterow(e){
  setLoading(true)
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
  
  var requestOptions = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(process.env.REACT_APP_BASE_URL+"admin/deleteAddOn/"+e, requestOptions)

.then(response => response.text())
 
    .then((res) => {
      setLoading(false);
    if(JSON.parse(res).errors){
      console.log(JSON.parse(res).errors)
      toast.error(JSON.parse(res)._message, { autoClose: 8000 })
    }else{
      toast.success("Addon Delete Success", { autoClose: 8000 })
      getData();
    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.errors);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });

}


const getData = () => {
  setLoading(true);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

fetch(process.env.REACT_APP_BASE_URL+"admin/showAddOns", requestOptions)
.then(response => response.text())
    .then((res) => {
      setLoading(false);
          fetchUsers(JSON.parse(res))
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.errors);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
}


 
  function submit(e){
    // setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var formdata = new FormData();
formdata.append("name", user.name);
formdata.append("price", user.price);
formdata.append("image", image?image:user.image);
formdata.append("status", user.status);



var requestOptions = {
  method: 'POST',
  body: formdata,
  headers: myHeaders,
  redirect: 'follow'
};

fetch(process.env.REACT_APP_BASE_URL+"admin/addAddOn", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        toast.success("Addon Add Success", { autoClose: 8000 })
        getData();
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }
  function submitedit(e){
    document.getElementById('close').click();
    setLoading(true);
    e.preventDefault();
    var axios = require('axios');
var FormData = require('form-data');
var data1 = new FormData();
data1.append("_id", editdata.id);
data1.append("name", editdata.nameedit);
data1.append("price", editdata.priceedit);
data1.append("image", image?image:editdata.imageedit);
data1.append("status", editdata.statusedit);

var config = {
  method: 'patch',
  url: process.env.REACT_APP_BASE_URL+"admin/updateAddOn/"+editdata.id,
  headers: { 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 
   
  },
  data : data1
};

axios(config)
.then(function (response) {
  setLoading(false);
  console.log(JSON.stringify(response.data));
  toast.success("Addon Upadte Success", { autoClose: 8000 })
  getData();
})
.catch(function (error) {
  setLoading(false)
  if (error.response) {
    if(error.response.status == 500){
      toast.error(error.response.data.message, { autoClose: 8000 })
    }else{
    // Request made and server responded
    toast.error(error.response.data, { autoClose: 8000 })
    }
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    toast.error('The request was made but no response was received', { autoClose: 8000 })
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    toast.error(error.message, { autoClose: 8000 })
    console.log('Error', error.message);
  }
  console.log(error);
});
    
    }
    
    function handle(e){
      const newdata={...user}
      if(e.target.type == "file"){
    
         setSelectedImage(e.target.files[0]);    
       }
     else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }
        else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }

    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == "file"){
    
        setSelectedImage(e.target.files[0]);    
      }
      else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
      const newdata={...data}
      newdata['id'] = item._id;
      newdata['nameedit'] = item.name;
      newdata['priceedit'] = item.price;
      newdata['statusedit'] = item.status;
      newdata['imageedit'] = item.image;
    
      fetchdataedit(newdata);
    }
    useEffect(() => {
      getData();
    }, [])

  




    return loading? 
  <>
  <div className="App">
        
            
              <div className={"item"}>
                  <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
              </div>
       
      
      </div>
  </>
  :  (
    <>
    <ToastContainer autoClose={8000} />
    <div className="page">
      <div className="page-header">
        <h1 className="page-title">Product Addon</h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
          <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
          {/* <li className="breadcrumb-item"><NavLink to="">Products</NavLink></li> */}
          <li className="breadcrumb-item active">Product Addon</li>
        </ol>
        {/* <div className="page-header-actions">
          <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
            target="_blank">
        <i className="icon wb-link" aria-hidden="true"></i>
        <span className="hidden-sm-down">Official Website</span>
      </a>
        </div> */}
      </div>

      <div className="page-content container-fluid">
        

        <div className="row">
          <div className="col-lg-12">
            {/* <!-- Panel Standard Mode --> */}
            <div className="panel" style={{backgroundColor:"aliceblue"}}>
              <div className="panel-heading">
                <h3 className="panel-title">Add Product Addon </h3>
              </div>
              <div className="panel-body">
              <form onSubmit={(e)=> submit(e)}>
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handle(e)} value={user.name}  className="form-control" id="name" name="name"
                          placeholder="Product Name" autocomplete="off" required/>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Price <span style={{ color: "red" }}>**</span></label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handle(e)} value={user.price}  className="form-control" id="price" name="price"
                          placeholder="Price" autocomplete="off" required/>
                    </div>
                  </div>
                  <div className="form-group row">
                  <label className="col-md-3 form-control-label">status</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handle(e)} value={user.status} id="status" name="status" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                </div>
                  <div className="form-group row">
                  <label className="col-md-3 form-control-label">Addon Image <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <div className="form-group">
                                    <div className="form-control-wrap">
                                        <div className="custom-file">
                                        <input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handle(e)} name="image"   id="image" aria-label="Choose Category Image" placeholder='Choose Addon Image'  required/>
  <label for="file">Select Addon Image</label>

                                        </div>
                                    </div>
                                </div>
                  </div>
                </div>

                
                  <div className="text-right">
                    <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            {/* <!-- End Panel Standard Mode --> */}
          </div>

        </div>

      

       
      </div>
      <div className="page-content">
        <div className="panel">
            <header className="panel-heading">
              {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
            </header>
            <div className="panel-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-15">
                   
                  </div>
                </div>
              </div>
              <DataTableExtensions {...tableData}>
                <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              // sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
        />
          </DataTableExtensions>
            </div>
          </div>
      </div>

      <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Addon</h5>
         <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


  
                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Name</label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handleedit(e)} value={editdata.nameedit}  className="form-control" id="nameedit" name="nameedit"
                          placeholder="Product Name" autocomplete="off" />
                    </div>
                  </div>


                  <div className="form-group row">
                    <label className="col-md-3 form-control-label">Price</label>
                    <div className="col-md-9">
                    <input type="text" onChange={(e)=>handleedit(e)} value={editdata.priceedit}  className="form-control" id="priceedit" name="priceedit"
                          placeholder="Price" autocomplete="off" />
                    </div>
                  </div>
               
                
                  <div className="form-group row">
                  <label className="col-md-3 form-control-label">status</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handleedit(e)} value={editdata.statusedit} id="statusedit" name="statusedit" >
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                </div>
                  <div className="form-group row">
                  <label className="col-md-3 form-control-label">Addon Image</label>
                  <div className="col-md-9">
                  <div className="form-group">
                                    <div className="form-control-wrap">
                                        <div className="custom-file">
                                        <input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handleedit(e)} name="imageedit"   id="imageeedit" aria-label="Choose Specilizaton Image" placeholder='Choose Skills Image'  />    
  {/* <label for="file">Select Specailization Image</label> */}
  <img src={editdata.imageedit} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }} width="40" height="40"/> 
                                        </div>
                                    </div>
                                </div>
                  </div>
                </div> 
            
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
    </>
    
  )
}

export default AddonType
