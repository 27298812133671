import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { NavLink } from 'react-router-dom';
const Notifications = () => {
const[notification_data,setNotificationData]=useState([]);
    function getNotification(params) {
        var axios = require('axios');

        var config = {
          method: 'get',
          url: process.env.REACT_APP_BASE_URL+'notification',
          headers: { 
            'Authorization': ''
          }
        };
        
        axios(config)
        .then(function (response) {
            console.log(response.data)
            setNotificationData(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    
      }
      useEffect(()=>{
        getNotification()
},[])


  return (
    <>

  <>

  <div className="page">
    <div className="page-header">
      <h1 className="page-title">General Setting</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Setting</NavLink></li>
        {/* <li className="breadcrumb-item"><a href="">Astrologer</a></li> */}
        <li className="breadcrumb-item active">Notification</li>
      </ol>
    
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel">
            <div className="panel-heading">
              <h3 className="panel-title">Notification </h3>
            </div>
            <div className="panel-body">
            <Table striped bordered hover>
      <thead>
        <tr>
          <th>S.No</th>
          <th>Title</th>
          <th>Message Type</th>
          <th>Notification</th>
        </tr>
      </thead>
      <tbody>
      
            {
                notification_data?.data?.map((item,index)=>{
                    return   <tr key={item._id}>
                    <td>{index+1}</td>
                    <td>{item.title}</td>
                    <td>{item.msg_type}</td>
                    <td>{item.body}</td>
                    </tr>
})
            }
          
        
     
       
      </tbody>
    </Table>

            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>


 

  </div>
  </></>
  )
}

export default Notifications
