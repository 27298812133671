
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';

const Language  = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  let [arr, setArrList] = useState([])
  const [image, setSelectedImage] = useState(null);



  const columns = [
    {
        name: 'Name',
        selector: row => row.value,
    },
    {
        name: 'Status',
        selector: row => row.display == 1?<span style={{color:"green"}}>Active</span>:<span style={{color:"red"}}>Deactive</span>,
    },
    {
        name: 'Action',
        sortable: false,
        cell: (d) => [
          <>
                 <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(d)}  data-toggle="modal" data-target="#exampleModalLong"
                       data-original-title="Edit"><i className="icon wb-edit" aria-hidden="true"></i></button>
                       
                       <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>


          </>
        ]
    },
];


let tableData = {
    columns,
    data
  }


  const state = {
    // options: [{name: 'Option 1️⃣', id: 1, selectedValue: false},{name: 'Option 2️⃣', id: 2, selectedValue:true}]
};
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [catData,setData]=useState([])
  const [user, fetchdata] = useState({
            "value": "",
            "display": false,
            "display_order": "",
            "model":"Language"
          
          });
          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
            "valueedit": "",
            "displayedit": false,
            "display_orderedit":"" ,       
            "image":""        
          });
         

  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/astrologer/Language", requestOptions,5000)
.then(response => response.text())
.then((res) => {
  setLoading(false);
if(JSON.parse(res).errors){
  console.log(JSON.parse(res).errors)
  toast.error(JSON.parse(res)._message, { autoClose: 8000 })
}else{
  fetchUsers(JSON.parse(res))
  let tempArray =Array();
  JSON.parse(res).map((item) => { 
   
   tempArray.push({label: item.value,value: item.id}); 
  })
  setArrList(tempArray); 
};
  
})
.catch((error) => {
  setLoading(false);
  console.log(error.errors);
  toast.error('Somthing Went Wrong', { autoClose: 8000 });
});
     
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var formdata = new FormData();
    formdata.append("value", user.value);
    formdata.append("display",user.display);
    formdata.append("display_order", user.display_order);
    formdata.append("image", image);
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(process.env.REACT_APP_BASE_URL+"master/astrologer/Language", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        fetchdata({
          "value": "",
          "display": false,
          "display_order": "",
          "model":"Language"
        
        });
        toast.success("Language Add Success", { autoClose: 15000 });
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

    function deleterow(e){
      setLoading(true)
      var myHeaders = new Headers();
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"master/astrologer/Language?id="+e, requestOptions)
    
    .then(response => response.text())
    .then((res) => {
      setLoading(false);
    if(JSON.parse(res).errors){
      console.log(JSON.parse(res).errors)
      toast.error(JSON.parse(res)._message, { autoClose: 8000 })
    }else{
      toast.success("Language Delete Success", { autoClose: 15000 });
      console.log(JSON.parse(res))
      getData()
    };
      
    })
    .catch((error) => {
      setLoading(false);
      console.log(error.errors);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
    
    }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
      
      var formdata = new FormData();
      formdata.append("_id", editdata.edit_id);
      formdata.append("value", editdata.valueedit);
      formdata.append("display", editdata.displayedit);
      formdata.append("display_order", editdata.display_orderedit);
      formdata.append("image", image?image:editdata.image);
      
      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"master/astrologer/Language", requestOptions, 5000)
        .then(response => response.text())
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
         
          toast.success("Language Update Success", { autoClose: 15000 });
          getData()
        };
          
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
      }


    function handle(e){
  
      const newdata={...user}
      if(e.target.type == "file"){

        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == "file"){
       
        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['edit_id'] = item._id;
      newdata['valueedit'] = item.value;
      newdata['displayedit'] = item.display;
      newdata['display_orderedit'] = item.display_order;
      newdata['image'] = item.image;
      // newdata['parent_idedit'] = item.parent_id;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
  <ToastContainer autoClose={8000} />
  <div className="page">
    <div className="page-header">
      <h1 className="page-title">Astrologer Language</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Astrologer</NavLink></li>
        <li className="breadcrumb-item active">Language</li>
      </ol>
    
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel" style={{backgroundColor:"aliceblue"}}>
            <div className="panel-heading">
              <h3 className="panel-title">Add Astrologer Language</h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={user.value}  className="form-control" id="value" name="value"
                          placeholder="Language  Name" autocomplete="off" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Display <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handle(e)} value={user.display} id="display" name="display" required>
                      <option >Select Any one</option>
                      <option value='1' selected>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                </div>
                
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Display Order</label>
                  <div className="col-md-9">
                  <input type="number" onChange={(e)=>handle(e)} value={user.display_order}  className="form-control" id="display_order" name="display_order"
                          placeholder="Display Order" min="0" autocomplete="off" />
                  </div>
                </div>
                
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
         
            <DataTableExtensions {...tableData}>
                <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              // sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
        />
          </DataTableExtensions>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog"  aria-labelledby="preview-modal" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Astrologer Language</h5>
        <button type="button" className="close" id='close' data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


     
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.valueedit}  className="form-control" id="valueedit" name="valueedit"
                          placeholder="Material Name" required />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Display <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handleedit(e)} value={editdata.displayedit}  id="displayedit" name="displayedit" required>
                    <option >Select Any one</option>
                      <option value='1' select>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Display Order</label>
                  <div className="col-md-9">
                  <input type="number" onChange={(e)=>handleedit(e)} value={editdata.display_orderedit}  className="form-control" id="display_orderedit" name="display_orderedit"
                          placeholder="Display Order" min="0" autocomplete="off" />
                  </div>
                </div>
                
              
                <div className="text-right">
                  
                </div>
            
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
  </>
    
  )
                }
export default Language
