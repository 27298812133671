import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Routes, Navigate, Link} from "react-router-dom";
import Loader from "react-js-loader";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import 'react-toastify/dist/ReactToastify.css';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { FaFileInvoice } from "react-icons/fa";
import { NavLink } from 'react-router-dom';
import { options } from '../../App';



const Products = () => {
  const location = useLocation();
  const dataid = location.state;
  const [data, fetchUsers] = useState([])
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState();

  function daterange(s,e) {
  
    
    console.log(s,e);
    if(s>e){
      alert('Start date must be less than end date')
    }else{
      setStartDate(s);
    setEndDate(e);
    }
  if(e & s){
    // change start date format
    var createdDate = new Date(s);

var day = createdDate.getDate();
var month = createdDate.getMonth() + 1; //months are zero based
var year = createdDate.getFullYear();
 
  // change end date format
var endingDate = new Date(e);

var day2 = endingDate.getDate();
var month2 = endingDate.getMonth() + 1; //months are zero based
var year2 = endingDate.getFullYear();

    getData(''+year+'/'+month+'/'+day+'',''+year2+'/'+month2+'/'+day2+'');
  }
   }

   let newDate = new Date()
   let date = newDate.getDate();
   let month = newDate.getMonth() + 1;
   let year = newDate.getFullYear();
  const columns = [
    
    {
      name: "Order id",
      selector: row => row?._id,
      sortable: true,
     
    },
    {
      name: "User",
      selector: row => row.MemberId?.firstName + " " +row.MemberId?.lastName,
      sortable: true,
    
    },
    {
      name: "Order Total",
      selector: row => row.total,
      sortable: true,
    
    },
    {
      name: "Order Status",
      selector: row => row.status,
      sortable: true,
    
    },
    {
      name: "Order Date",
      selector: row => new Date(row?.createdAt).toLocaleDateString("en-US", options),
      sortable: true,
    
    },
    
 
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
       <Link to="/ecom-invoice"
      state= {{
        id: d._id
      }}  className="btn btn-sm btn-icon btn-pure btn-default on-editing save-row" data-toggle="tooltip" data-original-title="Save" ><FaFileInvoice/></Link>
       
      </>
      ]
    }
  ];
  let tableData = {
    columns,
    data
  }

  const getData = (s=null,e = null) => {
    setLoading(true)
    var axios = require('axios');
    var url = "https://api.jyotishee.com:8000/api/admin/order?";
    if(dataid){
      url = url+'user_id='+dataid.id
    }
    if(s && e){
    
      url = url+"&startingfrom="+s+"&endto="+e;
    }

    var config = {
      method: 'get',
      url: url,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      console.log(response.data);
      fetchUsers(response.data?.data)
      setLoading(false)
    })
    .catch(function (error) {
      setLoading(false);
      console.log(error);
      toast.error('Somthing Went Wrong', { autoClose: 8000 });
    });
    
  }

  useEffect(() => {
    getData()
  }, [])
  return loading? 
  <>
  <div className="App">
        
            
              <div className={"item"}>
                  <Loader type="bubble-ping" bgColor={"#333"} title={"bubble-ping"} color={'#FFFFFF'} size={100} />
              </div>
       
      
      </div>
  </>
  :  
  <>
  (
    <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Eccomerce Order</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Order</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </NavLink>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
            <div className="form-group mb-2">
    <label for="staticEmail2" className="">Select Date Range</label>
     </div>
  <div className="form-group mx-sm-3 mb-2" style={{width:"15%"}}>
    <DatePicker
       selected={startDate}
       onChange={(date) => daterange(date,endDate)}
       selectsStart
       startDate={startDate}
       endDate={endDate}
       placeholderText='select starting date'
       className="form-control"
      />
  </div>
  <div className="form-group mx-sm-3 mb-2" style={{width:"15%"}}>
    <DatePicker
         selected={endDate}
         onChange={(date) => daterange(startDate,date)}
         selectsEnd
         startDate={startDate}
         endDate={endDate}
         minDate={startDate}
         placeholderText='select ending date'
         className="form-control"/>
  </div>
              <div className="col-md-6">
                <div className="mb-15">
         
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          // sortIcon={<SortIcon />}
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
        />
      </DataTableExtensions>
          </div>
        </div>
    </div>

    

  </div>
 
  )
  </>
}

export default Products
