import React, { useState, useEffect } from "react";
// import { Multiselect } from 'multiselect-react-dropdowndefaultChecked={data.ring == 1?true:false}';
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-js-loader";
import Select from "react-select";
import { NavLink } from "react-router-dom";


const Add = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [image, setSelectedImage] = useState(null);
  const [data, fetchdata] = useState({
    user_type: "",
    gender: "",
    // "MemberID": 40,
    firstName: "",
    // image: "",
    // "UserID": 49,
    lastName: "",
    expertise: "",
    dateOfBirth: "",
    timeOfBirth: "",
    placeOfBirth: "",
    emailAddress: "",
    address: "",
    city: "",
    StateID: "",
    CountryID: "",
    pinCode: "",
    status: "",
    phone: "",
    // "updatedAt": "2022-07-23T05:56:31.712Z",
    // "createdAt": "2022-07-23T05:56:31.712Z"
  });
  const location = useLocation();
  const dataid = location.state;

  const [select, fetchselectdata] = useState({
    gender: "",
  });

  const getData = () => {
    if (dataid) {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_BASE_URL+"admin/" +
          dataid.id +
          "/getMemberDetails",
        requestOptions
      )
        .then((response) => response.text())
        .then((res) => {
          setLoading(false);
          console.log(JSON.parse(res));
          fetchdata(JSON.parse(res));

          const newdata = { ...select };
        
          if(JSON.parse(res).gender != null){
            if(JSON.parse(res).gender == "F"){
              newdata["gender"] = { value: "F", label: "female", type: "gender" };
            }
            else{
              newdata["gender"] = { value: "M", label: "Male", type: "gender" };
            }
        
      

           }

          fetchselectdata(newdata);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error("Somthing Went Wrong", { autoClose: 8000 });
        });
    }
  };

  function submit(e) {
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();


    myHeaders.append(
      "Authorization",
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4MDAwMTEwfQ.H_nDnEWMFRodX6OI0IjmWxLT0u90FCyMvFFQPGxZKRw"
    );
    var formdata = new FormData();
    formdata.append("firstName", data.firstName);
    formdata.append("lastName", data.lastName);
    formdata.append("emailAddress", data.emailAddress);
    formdata.append("dateOfBirth", data.dateOfBirth);
    formdata.append("timeOfBirth", data.timeOfBirth);
    formdata.append("placeOfBirth", data.placeOfBirth);
    formdata.append("address", data.address);
    // formdata.append("city", data.city);
    // formdata.append("stateID", data.stateID);
    // formdata.append("countryID", data.countryID);
    formdata.append("pinCode", data.pinCode);
    if(image){
      formdata.append('image',image);
    }
    formdata.append("status", data.status);
    formdata.append("phone", data.phone);
    formdata.append("gender", select.gender.value);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    let url = process.env.REACT_APP_BASE_URL+"admin/addMember";
    if (dataid) {
      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      url =process.env.REACT_APP_BASE_URL+"admin/"+dataid.id+"/updateMember";
    } else {
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };
      url = process.env.REACT_APP_BASE_URL+"admin/addMember";
    }
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((res) => {
        setLoading(false);
        if (JSON.parse(res).errors) {
          console.log(JSON.parse(res).errors);
          toast.error(JSON.parse(res)._message, { autoClose: 8000 });
        } else {
          toast.success("Data Addedd Successfully!", { autoClose: 15000 });
          navigate("/Users/Manage");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error("Somthing Went Wrong", { autoClose: 8000 });
      });
  }

  function handle(e) {
    // const newdata = { ...data };
    // if (e.target.type == "file") {
    //   setSelectedImage(e.target.files[0]);
    // } else {
    //   newdata[e.target.id] = e.target.value;
    // }
    const newdata={...data}
    if(e.target.type == "file"){
    
       setSelectedImage(e.target.files[0]);
  
     }else{
    newdata[e.target.id] = e.target.value
     }
    fetchdata(newdata);
    console.log(newdata);
  }

  const HandelChange = (obj) => {
    const newdata = { ...select };
    if (obj.type == "gender") {
      newdata["gender"] = obj;
    }

    fetchselectdata(newdata);
    console.log(obj);
  };

  const onlychar = (e) => {
    if (!/[a-zA-Z ]/i.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };
  const onlyint = (e) => {
    if (!/[0-9]/i.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ToastContainer autoClose={8000} />
      <div className="page">
        <div className="page-header">
          <h1 className="page-title">Users</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <NavLink to="/">Dashboard</NavLink>
            </li>
            <li className="breadcrumb-item">
              <NavLink to="" className='Cursor'>Users</NavLink>
            </li>
            <li className="breadcrumb-item active">{dataid?"Edit":"Add"}</li>
          </ol>
          {/* <div className="page-header-actions">
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Edit">
          <i className="icon wb-pencil" aria-hidden="true"></i>
        </button>
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Refresh">
          <i className="icon wb-refresh" aria-hidden="true"></i>
        </button>
        <button type="button" className="btn btn-sm btn-icon btn-default btn-outline btn-round"
          data-toggle="tooltip" data-original-title="Setting">
          <i className="icon wb-settings" aria-hidden="true"></i>
        </button>
      </div> */}
        </div>

        <div className="page-content">
          <div className="panel" style={{backgroundColor:"#f1f4f5"}}>
            <form onSubmit={(e) => submit(e)} className="validate-me"  id="validate-me" data-validate>
              <div className="panel-body container-fluid">
                <div className="row row-lg">
                  {/* <h4 className="example-title">Add New Users Form</h4> */}

                  <div className="col-12">
                    {/* <!-- Example Basic Form (Form grid) --> */}
                    <div className="example-wrap">
                      <div className="example">
                        <div className="row">
                          <div className="form-group col-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                onKeyPress={(e) => onlychar(e)}
                                id="firstName"
                                name="firstName"
                                pattern="[a-zA-Z][a-zA-Z ]{2,}"
                                className="form-control"
                                value={data.firstName}
                                placeholder="FirstName"
                                type="text"
                                required
                              ></input>
                              <label for="firstName">First Name <span style={{ color: "red" }}>**</span></label>
                            </div>
                          </div>

                          <div className="form-group col-md-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                onKeyPress={(e) => onlychar(e)}
                                id="lastName"
                                name="lastName"
                                pattern="[a-zA-Z][a-zA-Z ]{2,}"
                                className="form-control"
                                value={data.lastName}
                                placeholder="lastName"
                                type="text"
                                required
                              ></input>
                              <label for="lastName" >Last Name <span style={{ color: "red" }}>**</span></label>
                            </div>
                          </div>

                          <div className="form-group col-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="emailAddress"
                                name="emailAddress"
                                className="form-control"
                                value={data.emailAddress}
                                placeholder="Email address"
                                type="email"
                                required
                              ></input>
                              <label for="emailAddress"  >Email address <span style={{ color: "red" }}>**</span></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-4">
                            <div className="form-floating">
                              <Select
                                options={[
                                  { value: "M", label: "Male", type: "gender" },
                                  {
                                    value: "F",
                                    label: "Female",
                                    type: "gender",
                                  },
                                ]}
                                name="gender"
                                placeholder="Gender"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                value={select.gender} // this doesn't let me click options
                                onChange={(option) => HandelChange(option)} // this returns (option) => option.phaseText) as a string
                              />
                            </div>
                          </div>

                          <div className="form-group col-md-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                onKeyPress={(e) => onlychar(e)}
                                id="expertise"
                                pattern="[a-zA-Z][a-zA-Z ]{2,}"
                              
                                className="form-control"
                                value={data.expertise}
                                placeholder="Profession"
                                type="text"
                              ></input>
                              <label for="expertise">Profession</label>
                            </div>
                          </div>

                          <div className="form-group col-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="phone"
                                name="phone"
                                pattern="[7-9]{1}[0-9]{9}"
                                onKeyPress={(e) => onlyint(e)}
                                className="form-control"
                                value={data?.phone}
                                placeholder="Phone Number"
                                type="text"
                                {...(dataid ? { disabled: "success" } : {})}
                                title="Phone number with 7-9 and remaing 9 digit with 0-9"
                                required
                              ></input>
                              <label for="phone" >Phone Number <span style={{ color: "red" }}>**</span></label>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-md-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="dateOfBirth"
                                name="dateOfBirth"
                                className="form-control"
                                value={data.dateOfBirth}
                                placeholder="Date Of Birth"
                                type="Date"
                                required
                              ></input>
                              <label for="dateOfBirth" >Date of Birth <span style={{ color: "red" }}>**</span></label>
                            </div>
                          </div>

                          <div className="form-group col-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="timeOfBirth"
                                name="timeOfBirth"
                                className="form-control"
                                value={data.timeOfBirth}
                                placeholder="Time of birth"
                                type="Time"
                              ></input>
                              <label for="timeOfBirth">Time of Birth</label>
                            </div>
                          </div>
                          <div className="form-group col-4">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="placeOfBirth"
                                className="form-control"
                                value={data.placeOfBirth}
                                placeholder="Place of birth"
                                type="palace address"
                              ></input>
                              <label for="placeOfBirth">Place of Birth</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-12 ">
                          <div className="form-floating">
            <div className="custom-file">
                          <input
                                  type="file" accept="image/png, image/gif, image/jpeg"
                                  className="custom-file-input"
                                  onChange={(e) => handle(e)}
                                  value={data.image}
                                  id="image"
                                  className='form-control'
                                  placeholder='Choose Main Image'
                                />
  {/* <label for="file">Select Specailization Image</label> */}
<img src={data.profileImage} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }} width="40" height="40"/> 
  </div>
  </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col-12">
                            <div className="form-floating">
                              <input
                                onChange={(e) => handle(e)}
                                id="address"
                                name="address"
                                className="form-control"
                                value={data.address}
                                placeholder="address"
                                type="text"
                              ></input>
                              <label for="address">Address</label>
                            </div>
                          </div>
{/* 
                          <div className="form-group col-md-4">                          
                    <input onChange={(e)=>handle(e)} id="city" className="form-control" value={data.city} placeholder='city' type="text"></input>
                      </div> */}
                        </div>
                        <div className="form-group row">
                    
                  <div className="col-md-12">
                
                    <select  className="form-control" onChange={(e)=>handle(e)} value={data.status}  id="status" name="status" >
                    <option >Select Status</option>
                      <option value='1' select>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                  
                </div>
                        <button type="submit" className="btn btn-success btn-block">
                          submit
                        </button>
                      </div>
                    </div>

                    {/* <!-- End Example Basic Form (Form grid) --> */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
