import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { NavLink } from 'react-router-dom';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';


const Type  = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  let [arr, setArrList] = useState([])
  const [image, setSelectedImage] = useState(null);
 const[isActive,setActive]=useState(false);

  const state = {
    // options: [{name: 'Option ⿡', id: 1, selectedValue: false},{name: 'Option ⿢', id: 2, selectedValue:true}]
};


const columns = [
  {
    name: "Image",
    selector: "categoryImage",
    sortable: false,
    cell: (d) => (
      <img src={d.categoryImage}  onError={({ currentTarget }) => {
   currentTarget.onerror = null; // prevents looping
   currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
 }}  width="40" height="40" style={{ margin: "4px"}} />
     )
  },
  {
      name: 'Name',
      selector: row => row.value,
  },
  {
    name: 'Parent Category',
    selector: row => row.parent_name,
},
  {
      name: 'Display Order',
      selector: row => row.display_order,
  },
  {
      name: 'Action',
      sortable: false,
      cell: (d) => [
        <>
              {d._id == "637103dfe800a9d5f7bedf31" || d._id == "637103f7e800a9d5f7bedf9c" || d._id == "63710409e800a9d5f7bee007" ? "":  
                   <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(d)}  data-toggle="modal" data-target="#exampleModalLong"
                       data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>}
  {d._id == "637103dfe800a9d5f7bedf31" || d._id == "637103f7e800a9d5f7bedf9c" || d._id == "63710409e800a9d5f7bee007" ? "": 
                       <button  className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                        data-toggle="tooltip" data-original-title="Remove" ><i className="icon wb-trash" aria-hidden="true"></i></button> 
                        }

        </>
      ]
  },
];


let tableData = {
  columns,
  data
}
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [catData,setData]=useState([])
  const [user, fetchdata] = useState({
    "id": "",
            "value": "",
            "categoryImage": "",
            "display": "",
            "parent_id": "",
            "display_order":""
          
          });
          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
                    "valueedit": "",
                    "categoryImageedit": "",
                    "displayedit": 1,
                    "parent_idedit": "",
                    "display_orderedit": ""
                  
          });
         

          function deleterow(e){
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
      
            var formdata = new FormData();
            formdata.append("id", e);
            formdata.append("model", "ProductType");
            
            var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              // body: formdata,
              redirect: 'follow'
            };

          fetch(process.env.REACT_APP_BASE_URL+"admin/productmaster/Category?id="+e, requestOptions)
          
          .then((res) => {
            setLoading(false);
            console.log(res)
          if(res.errors){
            console.log(res.errors)
            toast.error(res._message, { autoClose: 8000 })
          }else{
            toast.success("Category Delete Success", { autoClose: 15000 });
            // console.log(JSON.parse(res))
            getData()
          };
            
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            toast.error('Somthing Went Wrong', { autoClose: 8000 });
          });
          
          }


           const  getData  =  async() =>  {
    setLoading(true);
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
    
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

await fetch(process.env.REACT_APP_BASE_URL+"admin/productmaster/Category", requestOptions)
.then(response => response.text())          
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
          if(Object.entries(JSON.parse(res)).length  > 0){
            fetchUsers(JSON.parse(res))
            let tempArray =Array();
            JSON.parse(res).map((item) => { 
             
             tempArray.push({label: item.value,value: item._id}); 
            })
            setArrList(tempArray); 
          console.log(JSON.parse(res))
         
        };
      }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
  }

  function submit(e){
    setLoading(true);
    e.preventDefault();
    var myHeaders = new Headers();
  //  myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var formdata = new FormData();
formdata.append("value", user.value);
formdata.append("display", user.display);
formdata.append("display_order", user.display_order);
formdata.append("image", image?image:user.categoryImage);
formdata.append("parent_id", user.parent_id);

// var raw = JSON.stringify({
//   "value":  data.value,
//   "display": data.display,
//   "display_order": data.display_order,
//   "parent_id": "62e8c974047fc9f375d2222a",
//   "categoryImage": "diamond.jpg"
// });

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: formdata,
  redirect: 'follow'
};
   


    fetch(process.env.REACT_APP_BASE_URL+"admin/productmaster/Category", requestOptions)
      .then(response => response.text())
      .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
         fetchdata({
          "value": "",
          "categoryImage": "",
          "display": 1,
          "parent_id": "",
          "display_order":""
        
        });
        toast.success("Category Add Success", { autoClose: 15000 });
        console.log(JSON.parse(res))
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var axios = require('axios');
var FormData = require('form-data');
var dataform = new FormData();
dataform.append("_id", editdata.edit_id);
dataform.append("value", editdata.valueedit);
dataform.append("display", 1);
dataform.append("display_order", editdata.display_orderedit?editdata.display_orderedit:1);
if(image){
  dataform.append("image", image?image:editdata.categoryImageedit);
}

dataform.append("parent_id", editdata.parent_idedit);
dataform.append('status',isActive)

var config = {
  method: 'patch',
  url: process.env.REACT_APP_BASE_URL+"admin/productmasterDemo/Category",
  headers: { 
    'Content-Type': 'application/json', 
    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4', 

  },
  data : dataform
};

axios(config)
.then(function (response) {
  setLoading(false);
  console.log(JSON.stringify(response.data));
  toast.success("Category Update Success", { autoClose: 15000 });
  getData()
})
.catch(function (error) {
  setLoading(false);
  console.log(error);
});
      }


    function handle(e){
  
      const newdata={...user}
      if(e.target.type == "file"){

         setSelectedImage(e.target.files[0]);    
       }
      
      else if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      } else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdata(newdata)
    }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == "file"){
       
        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
        console.log(e.target.id)
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['edit_id'] = item._id;
      newdata['valueedit'] = item.value;
      newdata['categoryImageedit'] = item.categoryImage;
      newdata['displayedit'] = item.display;
      newdata['parent_idedit'] = item.parent_id;
      newdata['display_orderedit'] = item.display_order;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
     <ToastContainer autoClose={8000} />
     <div className="page">
    <div className="page-header">
      <h1 className="page-title">Product Category</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        <li className="breadcrumb-item active">Product Category</li>
      </ol>
      <div className="page-header-actions">
        {/* <a className="btn btn-sm btn-default btn-outline btn-round" href="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </a> */}
      </div>
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel" style={{backgroundColor:"aliceblue"}}>
            <div className="panel-heading">
              <h3 className="panel-title">Add Product Category</h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={user.value}  className="form-control" id="value" name="value"
                          placeholder="Product Category Name" autocomplete="off" required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Parent Category <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                    
                    <select  className="form-control" onChange={(e)=>handle(e)} value={user.parent_id} id="parent_id" name="parent_id" required>
                      <option >Select Any one</option>
                        {data.map((item) => {
                         return (
                      <option value={item._id}>{item.value}</option>
                         )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Display Order</label>
                  <div className="col-md-9">
                  <input type="number" onChange={(e)=>handle(e)} value={user.display_order}  className="form-control" id="display_order" name="display_order"
                          placeholder="Display Order" min="0" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                <label className="col-md-3 form-control-label">Category Image <span style={{ color: "red" }}>**</span></label>
                  <div className="col-md-9">
                      <input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handle(e)} name="categoryImage"   id="categoryImage" aria-label="Choose Category Image" placeholder='Choose Category Image'  required/>
  <label for="file">Select Category Image</label>
  </div>    
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
                <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              // sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
        />
          </DataTableExtensions>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-dismiss="modal">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Category</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">


      
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.valueedit}  className="form-control" id="valueedit" name="valueedit"
                          placeholder="Product Category Name"  />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Parent Category</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handleedit(e)}  id="parent_idedit" name="parent_idedit" >
                      <option >Select Any one</option>
                      {data.map((item) => {
                         return (
                      <option value={item._id} {...(item?._id == editdata?.parent_idedit ? {selected: 'true'} : {})} >{item.value}</option>
                         )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  
                  <label className="col-md-3 form-control-label">Display Order</label>
                  <div className="col-md-9">
                  <input type="number" onChange={(e)=>handleedit(e)} value={editdata.display_orderedit}  className="form-control" id="display_orderedit" name="display_orderedit"
                          placeholder="Display Order" min="0" autocomplete="off" />
                  </div>
                </div>
                <div className="form-group row">
                <label className="col-md-3 form-control-label">Category Image</label>
                  <div className="col-md-9">
                  

<input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handleedit(e)} name="categoryImageedit"   id="categoryImageedit" aria-label="Choose Specilizaton Image" placeholder='Choose Skills Image'  />    
  {/* <label for="file">Select Specailization Image</label> */}
  <img src={editdata.categoryImageedit} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }} width="40" height="40"/>  
                          </div>
                        
                </div>
                <div className="form-group row">
                  
                  <label className="col-md-3 form-control-label">Status</label>
                  <div className="col-md-9" style={{width:"70px",}}>
                 
<BootstrapSwitchButton
    checked={isActive}
    onlabel='Active'
    offlabel='InActive'
    onChange={(e)=>setActive(!isActive)}
/>
                  </div>
                </div>
              
             
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
       <button type="submit" className="btn btn-primary" id="validateButton2">Save changes</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
  </>
   
   )
 }
 
 export default Type