
import React, { useState, useEffect } from 'react'
import { Multiselect } from "multiselect-react-dropdown";
import Loader from "react-js-loader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from 'react-data-table-component';
// import Select from 'react-select'

const Design  = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, fetchUsers] = useState([])
  let [arr, setArrList] = useState([])
  const [image, setSelectedImage] = useState(null);
  const [thumbnail, setSelectedthumbnail] = useState(null);


  const state = {
    // options: [{name: 'Option 1️⃣', id: 1, selectedValue: false},{name: 'Option 2️⃣', id: 2, selectedValue:true}]
};



const columns = [
  {
    name: "Image",
    selector: "image",
    sortable: false,
    cell: (d) => (
      <img src={d.image}  onError={({ currentTarget }) => {
   currentTarget.onerror = null; // prevents looping
   currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }}  width="40" height="40" style={{ margin: "4px"}} />
     )
  },
{
    name: 'Name',
    selector: row => row.name,
},
{
  name: 'Price',
  selector: row => row.price,
},
{
  name: 'Addon Product',
  selector: row => row.addon_id?.name,
},


{
    name: 'Action',
    sortable: false,
    cell: (d) => [
      <>
         <button className="btn btn-sm btn-icon btn-pure btn-default on-default edit-row" onClick={() => setdataform(d)}  data-toggle="modal" data-target="#exampleModalLong"
                       data-original-title="Edit" ><i className="icon wb-edit" aria-hidden="true"></i></button>
                       
                       <button className="btn btn-sm btn-icon btn-pure btn-default on-default remove-row" onClick={(e) => deleterow(d._id)}
                        data-toggle="tooltip" data-original-title="Remove"><i className="icon wb-trash" aria-hidden="true"></i></button>


      </>
    ]
},
];

let tableData = {
  columns,
  data
}

  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [catData,setData]=useState([])
  const [user, fetchdata] = useState({
    "id": "",
            "image": "",
            "name": "",
            "price": "",
            "display": "",
            "_id": "",
            "addon_id": "",
            "status":""
          
          });
          const [editdata, fetchdataedit] = useState({
                    "edit_id": "",
                    "nameedit": "",
                    "priceedit": "",
                    "addon_idedit": "",
                    "imageedit": "",
                    "statusedit": ""                  
          });
         

          function deleterow(e){
            setLoading(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
         
            
            var requestOptions = {
              method: 'DELETE',
              headers: myHeaders,
              redirect: 'follow'
            };
          
            fetch(process.env.REACT_APP_BASE_URL+"admin/designs/delete/"+e, requestOptions)
          
          .then(response => response.text())
          .then((res) => {
            setLoading(false);
          if(JSON.parse(res).errors){
            console.log(JSON.parse(res).errors)
            toast.error(JSON.parse(res)._message, { autoClose: 8000 })
          }else{
            toast.success("Design Delete Success", { autoClose: 8000 })
            getData();
          };
            
          })
          .catch((error) => {
            setLoading(false);
            console.log(error.errors);
            toast.error('Somthing Went Wrong', { autoClose: 8000 });
          });

            
          
          }


  const getData = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch(process.env.REACT_APP_BASE_URL+"admin/designs/show", requestOptions)
.then(response => response.text())
      .then((res) => {
     
            fetchUsers(JSON.parse(res))
       
            setLoading(false); 
           
      
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
  }

  function submit(e){
    e.preventDefault();
    if(!user.addon_id){
      alert('Select Addon');
      return false;
    }
    if(!user.status){
      alert('Select Status');
      return false;
    }
    
    setLoading(true);
  
    var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4ODI3NzM4fQ.0zozaqueC2fcSfFmsG66ciPCI1yMcy7jjrxnlaNpwYI");
 
    var formdata = new FormData();   
    formdata.append("name", user.name);
    formdata.append("price", user.price);
    formdata.append("image", image?image:user.image);
    formdata.append("addon_id", user.addon_id);
    formdata.append("status", user.status);
   
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
   
    fetch(process.env.REACT_APP_BASE_URL+"admin/designs/add", requestOptions)
      .then(response => response.text())
        .then((res) => {
        setLoading(false);
      if(JSON.parse(res).errors){
        console.log(JSON.parse(res).errors)
        toast.error(JSON.parse(res)._message, { autoClose: 8000 })
      }else{
        toast.success("Design Add Success", { autoClose: 15000 });
        console.log(JSON.parse(res))
        getData()
      };
        
      })
      .catch((error) => {
        setLoading(false);
        console.log(error.errors);
        toast.error('Somthing Went Wrong', { autoClose: 8000 });
      });
    }

    const getaddonData = () => {
      setLoading(true);
      var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

fetch(process.env.REACT_APP_BASE_URL+"admin/showAddOns", requestOptions)
  .then(response => response.text())
  .then((res) => {
    setLoading(false);
  if(JSON.parse(res).errors){
    console.log(JSON.parse(res).errors)
    toast.error(JSON.parse(res)._message, { autoClose: 8000 })
  }else{
    setArrList(JSON.parse(res)); 
  };
    
  })
  .catch((error) => {
    setLoading(false);
    console.log(error.errors);
    toast.error('Somthing Went Wrong', { autoClose: 8000 });
  });
      }

   function submitedit(e){
      document.getElementById('close').click();
      setLoading(true);
      e.preventDefault();
      var myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4");
    
      var formdata = new FormData();

    
      formdata.append("_id", editdata.edit_id);
      
      formdata.append("name", editdata.nameedit);
      formdata.append("price", editdata.priceedit);
      formdata.append("image", image? image :editdata.imageedit);
      // formdata.append("__v", data.__v);
      formdata.append("addon_id", editdata.addon_idedit);
      formdata.append("status", editdata.statusedit);
     
     
      var requestOptions = {
        method: 'PATCH',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(process.env.REACT_APP_BASE_URL+"admin/designs/update/"+editdata.edit_id, requestOptions)
        .then(response => response.text())
        .then((res) => {
          setLoading(false);
        if(JSON.parse(res).errors){
          console.log(JSON.parse(res).errors)
          toast.error(JSON.parse(res)._message, { autoClose: 8000 })
        }else{
          toast.success("Design Update Success", { autoClose: 15000 });
          getData();
        };
          
        })
        .catch((error) => {
          setLoading(false);
          console.log(error.errors);
          toast.error('Somthing Went Wrong', { autoClose: 8000 });
        });
      }


    function handle(e){
  
      const newdata={...user}
      if(e.target.type == "file"){
         setSelectedImage(e.target.files[0]);   
       }
      else if(e.target.type === 'checkbox'){
      if(e.target.type == "file"){

        setSelectedImage(e.target.files[0]);    
      }
     
     else if(e.target.type === 'checkbox'){
        console.log(e.target.value)
        if(e.target.value === "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
     }
       else{
       newdata[e.target.id] = e.target.value
       }
   
       
       fetchdata(newdata);
    
     }


    function handleedit(e){
      const newdata={...editdata}
      if(e.target.type == "file"){

        setSelectedImage(e.target.files[0]);    
      }    
     else if(e.target.type == 'checkbox'){
        console.log(e.target.value)
        if(e.target.value == "true"){
          
          newdata[e.target.id] = false;
        }else{
          newdata[e.target.id] = true;
        }
      }else{
      newdata[e.target.id] = e.target.value
      }
      
      fetchdataedit(newdata)
    }

    const setdataform = (item) => {
 
      const newdata={}
      newdata['edit_id'] = item._id;
      newdata['imageedit'] = item.image;
      newdata['priceedit'] = item.price;
      newdata['nameedit'] = item.name;
      newdata['statusedit'] = item.status;
      newdata['addon_idedit'] = item.addon_id?._id;
    console.log(newdata)
      fetchdataedit(newdata);
    }

  useEffect(() => {
    getData()
    getaddonData()
  }, [])

 
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
  ) : (
  <>
   <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title">Product Addon Design</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Master</NavLink></li>
        {/* <li className="breadcrumb-item"><NavLink to="">Product</NavLink></li> */}
        <li className="breadcrumb-item active">Product Addon Design</li>
      </ol>
     
    </div>

    <div className="page-content container-fluid">
      

      <div className="row">
        <div className="col-lg-12">
          {/* <!-- Panel Standard Mode --> */}
          <div className="panel" style={{backgroundColor:"aliceblue"}}>
            <div className="panel-heading">
              <h3 className="panel-title">Add Product Design  <span style={{ color: "red", marginLeft:"30px"}}>All Field Are Required</span></h3>
            </div>
            <div className="panel-body">
            <form onSubmit={(e)=> submit(e)}>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name </label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={user.name}  className="form-control" id="name" name="name"
                          placeholder="Product Design Name" autocomplete="off"  required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Price</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handle(e)} value={user.price}  className="form-control" id="price" name="price"
                          placeholder="Price" autocomplete="off"  required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Addon</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.material_id} id="material_id" name="material_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handle(e)} value={user.addon_id} id="addon_id" name="addon_id" required>
                      <option >Select Any one</option>
                      {arr.map((item) => {
                         return (
                      <option value={item._id}>{item.name}</option>
                         )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Status</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handle(e)} value={user.status} id="status" name="status" required>
                      <option >Select Any one</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                  </div>
                  </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Design Image</label>
                  <div className="col-md-9">
                  <div className="form-group">
                                    <div className="form-control-wrap">
                                        <div className="custom-file">
                                             <input type="file" accept="image/png, image/gif, image/jpeg" className="form-control"   id="image"  onChange={(e)=>handle(e)} name="image"  required />
                                            <label className="" for="image" style={{zIndex:"0"}}>Choose Design Image </label >
                                    
                                        </div>
                                    </div>
                                </div>
                  </div>
                </div>
                <div className="text-right">
                  <button type="submit" className="btn btn-primary" id="validateButton2">Submit</button>
                </div>
              </form>
            </div>
          </div>
          {/* <!-- End Panel Standard Mode --> */}
        </div>

      </div>

    

     
    </div>
    <div className="page-content">
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <DataTableExtensions {...tableData}>
                <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              // sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
        />
          </DataTableExtensions>
          </div>
        </div>
    </div>

    <div className="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Edit Addon Design</h5>
        <button type="button" className="close" id="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form onSubmit={(e)=> submitedit(e)}>
      <div className="modal-body">
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Name</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.nameedit}  className="form-control" id="nameedit" name="nameedit"
                          placeholder="Product Design Name"  required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Price</label>
                  <div className="col-md-9">
                  <input type="text" onChange={(e)=>handleedit(e)} value={editdata.priceedit}  className="form-control" id="priceedit" name="priceedit"
                          placeholder="Price"  required/>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Addon</label>
                  <div className="col-md-9">
                    <select  className="form-control" onChange={(e)=>handleedit(e)}  id="addon_idedit" name="addon_idedit" >
                      <option >Select Any one</option>
                      {arr.map((item) => {
                         return (
                      <option value={item._id} {...(item?._id == editdata?.addon_idedit ? {selected: 'true'} : {})}>{item.name}</option>
                         )
                      })}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Status</label>
                  <div className="col-md-9">
                  {/* <Select options={arr} onChange={(e)=>handle(e)} value={data.parent_id} id="parent_id" name="parent_id"  /> */}

                    <select  className="form-control" onChange={(e)=>handleedit(e)} id="statusedit" name="statusedit" required>
                      <option >Select Any one</option>
                      <option value='1' {...(1== editdata?.statusedit ? {selected: 'true'} : {})}>Yes</option>
                      <option value='0' {...(0== editdata?.statusedit ? {selected: 'true'} : {})}>No</option>
                    </select>
                  </div>
                  </div>
                <div className="form-group row">
                  <label className="col-md-3 form-control-label">Addon Design</label>
                  <div className="col-md-9">
                  <div className="form-group">
                                    <div className="form-control-wrap">
                                        <div className="custom-file">
                                        <input type="file" accept="image/png, image/gif, image/jpeg"  className="form-control " onChange={(e)=>handleedit(e)} name="imageedit"   id="imageeedit" aria-label="Choose Specilizaton Image" placeholder='Choose Skills Image'  />    
  {/* <label for="file">Select Specailization Image</label> */}
  <img src={editdata.imageedit} onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="https://cdn-icons-png.flaticon.com/512/2659/2659360.png";
  }} width="40" height="40"/> 
                                        </div>
                                    </div>
                                </div>
                  </div>
                </div>
               
             
      </div>




      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary">Save changes</button>
      </div>
      </form>
    </div>
  </div>
</div>

  </div>
  </>
  )
}

export default Design
