import React, { useState, useEffect } from 'react';
import { Link} from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDOM from "react-dom";
// import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { NavLink } from 'react-router-dom';


const Manage = () => {
  const [loading, setLoading] = useState(false);
  const [data, fetchData] = useState([])
  const location = useLocation();
  const dataid = location.state;

  



  const getData = () => {
    setLoading(true);
    var axios = require('axios');

    var config = {
      method: 'get',
      url: process.env.REACT_APP_BASE_URL+"admin/recharge-history/"+dataid.id,
      headers: { 
        'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzdHJvbWFub2pAZ21haWwuY29tIiwiaWF0IjoxNjU4NTY3Njc4fQ.zAFRtWXFhB3yaivZpk7_uBmE2P-xXZSreBYQzQJF9D4'
      }
    };
    
    axios(config)
    .then(function (response) {
      setLoading(false);
           
      console.log(response.data);
      fetchData(response.data)
     
    })
    .catch(function (error) {
      setLoading(false)
      if (error.response) {
        if(error.response.status == 500){
          toast.error(error.response.data._message, { autoClose: 8000 })
        }else{
        // Request made and server responded
        toast.error(error.response.data, { autoClose: 8000 })
        }
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        toast.error('The request was made but no response was received', { autoClose: 8000 })
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error(error.message, { autoClose: 8000 })
        console.log('Error', error.message);
      }
      console.log(error);
    });
  }

  useEffect(() => {
    getData()
  }, [])
  return loading ? (
    <>
      <div className="App">
        <div className={"item"}>
          <Loader
            type="bubble-ping"
            bgColor={"#333"}
            title={"bubble-ping"}
            color={"#FFFFFF"}
            size={100}
          />
        </div>
      </div>
    </>
    
  ) : (
    <>
      <ToastContainer autoClose={8000} />
    <div className="page">
    <div className="page-header">
      <h1 className="page-title"> Recharge</h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><NavLink to="/">Dashboard</NavLink></li>
        <li className="breadcrumb-item"><NavLink to="" className='Cursor'>Recharge view</NavLink></li>
        <li className="breadcrumb-item active">List</li>
      </ol>
      {/* <div className="page-header-actions">
        <NavLink className="btn btn-sm btn-default btn-outline btn-round" to="http://formvalidation.io"
          target="_blank">
      <i className="icon wb-link" aria-hidden="true"></i>
      <span className="hidden-sm-down">Official Website</span>
    </NavLink>
      </div> */}
    </div>

    <div className="page-content container-fluid">
     
    </div>
    <div className="page-content" >
      <div className="panel">
          <header className="panel-heading">
            {/* <!-- <h3 className="panel-title">Add Row</h3> --> */}
          </header>
          <div className="panel-body">
            <div className="row">
              <div className="col-md-6">
                <div className="mb-15">
                 
                </div>
              </div>
            </div>
            <div className="table-responsive">
                    <table className="table ">
                     
                        <tbody className="table-primary">
                      
                      <tr>
                                <th>Astrologer</th>
                                <td>{data.memberId?.firstName}</td>
                              
                            </tr>
           
                            <tr>
                                <th>Amount</th>
                                <td>{data.amount}</td>
                            
                            </tr>
                            <tr>
                                <th>Payment Id</th>
                                <td>{data.paymentId}</td>
                               
                            </tr>
                            <tr>
                                <th>Order Id</th>
                                <td>{data.orderId}</td>
                               
                            </tr>
                           
                            <tr>
                                <th>Time</th>
                                <td>{data.createdAt}</td>
                               
                            </tr>
                          
                        </tbody>
                        </table>
                        </div>
          </div>
        </div>
    </div>

    

  </div>
  </>
  )
}

export default Manage
